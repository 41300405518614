/* RESPONSIBLE TEAM: team-knowledge-interop */

import FinAIAgentContentRoute from 'embercom/routes/apps/app/fin-ai-agent/content';

export default class FinContentRoute extends FinAIAgentContentRoute {
  analytics = {
    section: 'resolution-bot',
    place: 'fin-content',
  };

  controllerName = 'apps.app.fin-ai-agent.content';
  templateName = 'apps.app.fin-ai-agent.content';
}
