/* RESPONSIBLE TEAM: team-product-guidance */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

export default class GettingStartedRoute extends Route {
  @service declare router: Router;
  @service declare appService: $TSFixMe;
  @service declare greatGuidanceService: GreatGuidanceService;

  async beforeModel() {
    if (!this.appService.app.canUseGreatGuidanceMilestoneOne) {
      this.router.transitionTo('apps.app.guide-library', this.appService.app.id);
    }
  }

  async model() {
    return await this.greatGuidanceService.fetchSteps(this.appService.app.id);
  }
}
