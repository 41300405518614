/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import AttributeSettingsBase from 'embercom/routes/apps/app/settings/base/attribute-settings-base';

let QualificationAttributeRoute = AttributeSettingsBase.extend({
  store: service(),
  intl: service(),

  analytics: {
    section: 'settings',
    place: 'qualification_settings',
  },

  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.qualification-settings');
  },

  model() {
    return this.store.findAll('people/qualification-attribute');
  },

  setupController(controller, model) {
    this._super(controller, model);
    controller.set('initialSelectedIdentifiers', model.mapBy('identifier'));
  },
});

export default QualificationAttributeRoute;
