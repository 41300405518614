/* RESPONSIBLE TEAM: team-web */
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default ProductSettingsRoute.extend({
  store: service(),
  analytics: {
    section: 'settings',
    place: 'web',
  },
  appService: service(),
  intl: service(),
  app: readOnly('appService.app'),

  get titleToken() {
    return this.intl.t('apps.app.settings.installation.web.title');
  },

  activate() {
    this._super(...arguments);
    this.replaceWith('apps.app.settings.installation', {
      queryParams: { installationType: 'web' },
    });
    if (!this.app.api_secret) {
      // this will only work if the teammate has permissions, otherwise it will set the secret to null
      this.app.fetchAPISecret.perform();
    }
  },

  model() {
    return hash({
      userSources: this.store.findAll('user-source'),
      pingDomains: this.store.query('ping-domain', { ignore_anonymous: true }),
    });
  },
});
