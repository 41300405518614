/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class FinStandaloneSetupRoute extends Route {
  @service declare appService: any;
  @service declare finStandaloneService: any;

  async beforeModel() {
    await this.finStandaloneService.initialize();
  }
}
