/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { confirmSubscriptionChanges as confirmSubscriptionChangesFromInboxSeats } from 'embercom/lib/admins/inbox-seat-info';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { hash } from 'rsvp';
import { COPILOT_SEAT_TYPE as COPILOT } from 'embercom/lib/settings/seats/constants';

export default class PermissionsRoute extends ManageTeammatesRoute {
  @service permissionsMutatorService;
  @service permissionsHierarchyService;
  @service appService;
  @service store;
  @service intl;
  @service intercomConfirmService;

  @tracked seatTypes = [];
  hasSeatsToBeSaved = false;

  analytics = {
    section: 'settings',
    place: 'teammate-permissions',
  };

  closeWithoutSaving = false;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);
    this.closeWithoutSaving = false;
    this.hasSeatsToBeSaved = false;
    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();

    if (
      this.appService.app.currentAdmin.id ===
      this.modelFor('apps.app.settings.teammates.teammate').id
    ) {
      await transition.router
        .transitionTo('apps.app.settings.teammates', {
          queryParams: { forcePlatform: true },
        })
        .followRedirects();
    }
  }

  model() {
    let admin = this.modelFor('apps.app.settings.teammates.teammate');
    return hash({
      app: this.appService.app,
      admin,
      permissionsObject: admin.currentAppPermissions,
      reassignments: undefined,
      roles: this.store.findAll('role'),
      copilotData: this.modelFor('apps.app.settings.teammates.index')?.copilotData,
    });
  }

  async saveChanges() {
    try {
      this.currentModel.permissionsObject.reassignments = this.currentModel.reassignments;
      if (this.appService.app.hasMultipleSeatTypes || this.appService.app.hasCopilotSeatType) {
        await this.permissionsMutatorService.savePermissionAndSeats(
          this.currentModel.admin,
          this.currentModel.permissionsObject,
          this.seatTypes,
        );
        this.hasSeatsToBeSaved = false;
        this.currentModel.permissionsObject.rollbackAttributes();
        this.store.findRecord('admin', this.currentModel.admin.id, {
          reload: true,
          backgroundReload: false,
        });
      } else {
        await this.permissionsMutatorService.savePermission(
          this.currentModel.admin,
          this.currentModel.permissionsObject,
          this.currentModel.permissionsObject.has_inbox_access,
        );
        this.currentModel.permissionsObject.rollbackAttributes();
        this.store.findRecord('admin', this.currentModel.admin.id, {
          reload: true,
          backgroundReload: false,
        });
      }
      this.transitionTo('apps.app.settings.teammates');
    } catch (error) {
      console.error(error);
    }
  }

  get numberOfInboxSeatsRequired() {
    let model = this.currentModel.permissionsObject;
    return model.hasDirtyAttributes &&
      model.changedAttributes().has_inbox_access &&
      model.has_inbox_access
      ? 1
      : 0;
  }

  @action
  async confirmAndSaveChanges(teammates, seatTypes) {
    this.seatTypes = seatTypes;
    if (
      await confirmSubscriptionChangesFromInboxSeats(
        this.numberOfInboxSeatsRequired,
        this.showConfirmModal,
        {
          confirmationButtonLabel: this.intl.t(
            'settings.teammates.permissions.save-changes-modal.save-and-fill-seat',
          ),
          editFlow: true,
        },
        true,
        this.appService.app.canUseFinAiCopilotAddon && this.seatTypes.includes(COPILOT),
        this.currentModel.copilotData,
      )
    ) {
      await this.saveChanges();
    }
  }

  @action
  async showConfirmModal(options) {
    return this.intercomConfirmService.confirm(options);
  }

  @action
  seatsUpdated(hasUpdatedSeats) {
    this.hasSeatsToBeSaved = hasUpdatedSeats;
  }

  @action
  async willTransition(transition) {
    if (this.closeWithoutSaving) {
      return;
    }

    let permissionsObject = this.currentModel.permissionsObject;
    if (
      (permissionsObject && permissionsObject.hasDirtyAttributes) ||
      (this.hasSeatsToBeSaved && this.appService.app.hasMultipleSeatTypes)
    ) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('settings.teammates.permissions.close-without-saving-modal.title'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'settings.teammates.permissions.close-without-saving-modal.close-without-saving',
        ),
        cancelButtonText: this.intl.t(
          'settings.teammates.permissions.close-without-saving-modal.keep-editing',
        ),
        body: this.intl.t('settings.teammates.permissions.close-without-saving-modal.message-body'),
      };

      if (await this.intercomConfirmService.confirm(confirmOptions)) {
        // I wish I didn't have to do this. Normally we would rollback then retry
        // which would run this action again and exit the route. However, doing that
        // in this route looks bad. The IcOnOffToggle components which are bound to
        // the model are animated with CSS transitions - so you'll see them animate
        // back to their original positions before we exit the route.
        this.closeWithoutSaving = true;
        await transition.retry();
        permissionsObject.rollbackAttributes();
      }
    }
  }
}
