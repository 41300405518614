/* RESPONSIBLE TEAM: team-ai-chatbot */
import FinAIAgentCustomAnswersAnswerRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer';

export default class AutomationFinAIAgentCustomAnswersAnswerRoute extends FinAIAgentCustomAnswersAnswerRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.custom-answers.answer',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer';
}
