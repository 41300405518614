/* RESPONSIBLE TEAM: team-channels */

import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';

export default class MonitoringRoute extends SettingsRoute {
  @service permissionsService;
  @service intl;

  get titleToken() {
    return this.intl.t('channels.switch.settings.monitor.title');
  }

  beforeModel(transition) {
    super.beforeModel(...arguments);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_product_settings',
      transition,
    );
  }
}
