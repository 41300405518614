/* RESPONSIBLE TEAM: team-channels */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import type IntlService from 'ember-intl/services/intl';

export default class SwitchRoute extends SettingsRoute {
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t(`new-settings.submenu.channels.switch`);
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('switch_settings_page_visits');
  }

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_product_settings',
      transition,
    );
  }
}
