/* RESPONSIBLE TEAM: team-tickets-1 */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import storage from 'embercom/vendor/intercom/storage';
import { captureException } from 'embercom/lib/sentry';

export default IntercomRoute.extend({
  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-tickets-1',
    };
  },
  analytics: {
    section: 'respond',
  },
  store: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  userCacheService: service(),
  macrosService: service(),
  inboxVersion: service(),
  inboxRedirectionService: service(),

  app: computed(function () {
    return this.modelFor('apps.app');
  }),

  activate() {
    storage.set('lastProductUsed', 'inbox');
    this.macrosService.enableRealtimeHandlers();
    this.inboxVersion.setVersion(1);
  },

  deactivate() {
    // this addresses an issue with destroyed User objects hanging round the user cache service
    // for future reference the problem results in the following Ember assertion being triggered:
    // "Cannot create a new tag for `<embercom@model:user::ember2527:52ef87ea89b4e60739000021>` after it has been destroyed."
    this.userCacheService.clear();
    this.macrosService.disableRealtimeHandlers();
    this.inboxVersion.unsetVersion();
  },

  beforeModel(transition) {
    this.redirectToInbox2(transition);
  },

  redirectToInbox2(transition) {
    let routeName = transition.to.name;
    let params = routeParams(transition.to);
    // console.log(routeName, params);

    // new conversation
    if (routeName === 'apps.app.inbox.new-conversation') {
      return this.transitionToInbox2('inbox.workspace.inbox.new-conversation', this.app.id);
    }

    // a single conversation
    if (
      routeName === 'apps.app.inbox.inbox.conversation' ||
      routeName === 'apps.app.inbox.conversation'
    ) {
      return this.transitionToInbox2(
        'inbox.workspace.inbox.conversation.conversation',
        this.app.id,
        params.conversation_id,
      );
    }

    // a conversation in an unidentifiable inbox
    if (
      routeName === 'apps.app.inbox.conversations.conversation' &&
      params.assignee_identifier === 'inbox'
    ) {
      return this.transitionToInbox2(
        'inbox.workspace.inbox.conversation.conversation',
        this.app.id,
        params.conversation_id,
      );
    }

    // a conversation via search
    if (
      routeName === 'apps.app.inbox.inbox.conversations.conversation' &&
      params.assignee_identifier === 'search'
    ) {
      return this.transitionToInbox2(
        'inbox.workspace.search.conversation.conversation',
        this.app.id,
        params.conversation_id,
      );
    }

    // a conversation in an inbox
    if (
      routeName === 'apps.app.inbox.inbox.conversations.conversation' ||
      routeName === 'apps.app.inbox.conversations.conversation'
    ) {
      let inboxParams = this.inboxFromParams(params);
      if (inboxParams) {
        return this.transitionToInbox2(
          'inbox.workspace.inbox.inbox.conversation.conversation',
          this.app.id,
          inboxParams.inbox_category,
          inboxParams.inbox_id,
          params.conversation_id,
        );
      }
    }

    // an inbox
    if (routeName === 'apps.app.inbox.inbox.conversations.index') {
      let inboxParams = this.inboxFromParams(params);
      if (inboxParams) {
        return this.transitionToInbox2(
          'inbox.workspace.inbox.inbox',
          this.app.id,
          inboxParams.inbox_category,
          inboxParams.inbox_id,
        );
      }
    }

    // dashboard
    if (routeName === 'apps.app.inbox.dashboard') {
      return this.transitionToInbox2('inbox.workspace.dashboard', this.app.id);
    }

    // workload management
    if (routeName === 'apps.app.inbox.automation.workload-management') {
      return this.transitionToInbox2('apps.app.settings.workload-management', this.app.id);
    }

    // automation
    if (routeName.startsWith('apps.app.inbox.automation.workflows')) {
      let leaf = routeName.replace('apps.app.inbox.automation.workflows.', '');
      let newRoute = 'apps.app.settings.inbox-rules';
      if (leaf) {
        newRoute = `${newRoute}.${leaf}`;
      }

      if (leaf === 'edit') {
        return this.transitionToInbox2(newRoute, this.app.id, params.id);
      } else {
        return this.transitionToInbox2(newRoute, this.app.id);
      }
    }

    // a search
    if (
      routeName === 'apps.app.inbox.inbox.conversations.index' &&
      params.assignee_identifier === 'search'
    ) {
      return this.transitionToInbox2('inbox.workspace.search', this.app.id);
    }

    // inbox1 home
    if (routeName === 'apps.app.inbox.index') {
      return this.transitionToInbox2('inbox.workspace.inbox', this.app.id);
    }

    // unknown route, just redirect to inbox 2 home
    captureException(new Error('Unknown redirect from Inbox 1, redirecting to Inbox 2 home'), {
      extra: {
        routeName,
        params,
        href: window.location.href,
      },
    });
    return this.transitionToInbox2('inbox.workspace.inbox', this.app.id);
  },

  transitionToInbox2(...args) {
    if (window.location.hash) {
      this.inboxRedirectionService.setConversationPartAnchor(window.location.hash);
    }

    this.transitionTo(...args, { queryParams: { force: true } });
    return true;
  },

  inboxFromParams({ assignee_identifier }) {
    if (['all', 'mentions', 'unassigned'].includes(assignee_identifier)) {
      return {
        inbox_category: 'shared',
        inbox_id: assignee_identifier,
      };
    } else if (assignee_identifier.startsWith('view:')) {
      let viewID = assignee_identifier.replace('view:', '');

      return {
        inbox_category: 'view',
        inbox_id: viewID,
      };
    } else if (Number(assignee_identifier)) {
      // we don't know if it's an admin or a team at this point
      // so find the model and figure it out
      let admin = this.app.admins.findBy('id', assignee_identifier);
      if (!admin) {
        return {
          inbox_category: 'shared',
          inbox_id: 'all',
        };
      } else if (admin.is_team) {
        return {
          inbox_category: 'team',
          inbox_id: assignee_identifier,
        };
      } else {
        return {
          inbox_category: 'admin',
          inbox_id: assignee_identifier,
        };
      }
    } else {
      // unknown inbox type
      return null;
    }
  },
});

function routeParams(routeInfo) {
  let params = {};

  while (routeInfo) {
    params = { ...params, ...routeInfo.params };
    routeInfo = routeInfo.parent;
  }

  return params;
}
