/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-workflows */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class WorkflowsBaseRoute extends IntercomRoute {
  REDIRECT_ROUTE_FOR_RESTRICTED_USERS = 'apps.app.settings.workload-management';

  @service appService;
  @service notificationsService;
  @service intl;
  @service permissionsService;
  @service store;

  get app() {
    return this.appService.app;
  }

  displayErrorAndRedirect(message, redirectTo) {
    this.notificationsService.notifyError(message, -1);
    return this.replaceWith(redirectTo);
  }

  handleError(error) {
    return this.displayErrorAndRedirect(
      this.intl.t('settings.inbox-rules.base.error-load-workflow'),
      'apps.app.settings.inbox-rules.list',
    );
  }

  contextModels() {
    // We need to load these on routing as the UI expects the lists to be available
    return {
      whatsappIntegrations: this.store.query('whatsapp/integration', { no_external_updates: true }),
      conversationSlas: this.store.findAll('inbox/conversation-sla'),
      officeHoursSchedules: this.store.findAll('office-hours-schedule'),
      conversationCustomAttributes: this.store.findAll('conversation-attributes/descriptor'),
      workflowConnectorActions: this.store.findAll('workflow-connector/insertable-action'),
      instagramIntegrations: this.store.findAll('instagram/integration').catch(() => {
        this.notificationsService.notifyError('Error loading Instagram business accounts');
      }),
    };
  }

  beforeModel(transition) {
    if (this.appService.app.canUseWorkflowsNetNewExperience) {
      this.transitionTo('apps.app.automation.workflows-overview');
    }

    if (!this.permissionsService.currentAdminCan('can_manage_inbox_rules')) {
      // if transition is not from a automation route(i.e. conversations)
      // we should redirect the user to the workload management so that she knows we have a feature for that.
      this.replaceWith(this.REDIRECT_ROUTE_FOR_RESTRICTED_USERS);
    }
  }

  @action
  error(response) {
    console.error(response);
    this.handleError(response);
  }
}
