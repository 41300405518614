/* RESPONSIBLE TEAM: team-standalone */
import FinReportsRoute from 'embercom/routes/apps/app/reports/customer-support/fin-ai-agent';
import { inject as service } from '@ember/service';
import Range from 'embercom/models/reporting/range';

export default class FinStandaloneReportsRoute extends FinReportsRoute {
  controllerName = 'embercom/controllers/apps/app/reports';

  @service appService;
  @service reportingService;
  @service reportingMetrics;

  queryParams = {
    rangeEnd: { refreshModel: true },
    rangeStart: { refreshModel: true },
  };

  async beforeModel(transition) {
    let { rangeStart, rangeEnd } = transition.to.queryParams;
    let range = Range.createFromParams(rangeStart, rangeEnd, this.appService.app.timezone);
    this.reportingService.set('range', range);

    await this.reportingMetrics.setup.perform();

    await super.beforeModel(transition);
  }
}
