/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';

export default class InviteRoute extends ManageTeammatesRoute {
  @service permissionsMutatorService;
  @service appService;
  @service store;
  @service intl;
  @service intercomConfirmService;

  analytics = {
    section: 'settings',
    place: 'teammate-invite',
  };

  get getCurrentModel() {
    return this.modelFor(this.routeName);
  }

  inviteEmails = [];

  async beforeModel() {
    await super.beforeModel(...arguments);

    if (this.appService.app.isTestApp) {
      this.transitionTo('apps.app.settings.teammates');
    }
  }

  model(_params, transition) {
    let emails = transition?.to?.queryParams?.emails?.split(',') || [];
    if (this.inviteEmails) {
      emails.push(...this.inviteEmails);
    }
    return {
      app: this.appService.app,
      emails,
      roles: this.store.findAll('role'),
    };
  }

  @action
  onUnsavedChanges() {
    // @ts-ignore
    this.set('currentModel.hasUnsavedChanges', true);
  }

  @action
  onEmailsChange(emails) {
    this.set('currentModel.hasUnsavedChanges', false);

    this.set('currentModel.emails', emails);
  }

  @action
  async willTransition(transition) {
    if (this.getCurrentModel.hasUnsavedChanges) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('settings.teammates.invite.cancel-modal.close-without-saving'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'settings.teammates.invite.cancel-modal.close-without-saving-btn',
        ),
        cancelButtonText: this.intl.t('settings.teammates.invite.cancel-modal.keep-editing'),
        body: this.intl.t('settings.teammates.invite.cancel-modal.lose-your-changes'),
      };

      if (await this.intercomConfirmService.confirm(confirmOptions)) {
        // @ts-ignore
        this.set('currentModel.hasUnsavedChanges', false);
        await transition.retry();
      }
    }
  }
}
