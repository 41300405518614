/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import Resolver from 'ember-resolver';

export default Resolver.extend({
  moduleNameLookupPatterns: computed(function () {
    return [this.mainModuleName, this.defaultModuleName];
  }),

  _normalize(fullName) {
    let normalized = this._super(fullName);

    if (window.__embercom_boot_data?.record_resource_usage) {
      if (normalized.startsWith('component:') || normalized.startsWith('template:')) {
        if (!window.EMBER_COMPONENT_USAGE) {
          window.EMBER_COMPONENT_USAGE = new Set();
        }
        window.EMBER_COMPONENT_USAGE.add(normalized);
      }
    }

    return normalized;
  },
});
