/* RESPONSIBLE TEAM: team-channels */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';

export default ManageMessagesSettingsRoute.extend({
  analytics: {
    section: 'settings',
    place: 'inbound_email_addresses',
  },
  getTranslation(key) {
    return this._super(key);
  },
  get titleToken() {
    return this.getTranslation('apps.app.settings.email-forwarding.title');
  },
});
