/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import ModelCacheHelper from 'embercom/lib/model-cache/helper';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import {
  SETTINGS_REDIRECT_MAPPING,
  routeRedirector,
} from 'embercom/lib/settings/settings-redirect-map';

export default class TeammatesRoute extends ManageTeammatesRoute {
  @service customerService;
  @service modelDataCacheService;
  @service store;
  @service router;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);
    let app = this.modelFor('apps.app');
    if (app.canSeeNewIASettingsHub) {
      routeRedirector(
        transition,
        this.router,
        SETTINGS_REDIRECT_MAPPING,
        'apps.app.settings.index',
      );
      return;
    }
    if (app.hasMultipleSeatTypes) {
      if (ModelCacheHelper.isDisabled()) {
        this.store.findRecord('app', app.get('id'));
      } else {
        this.modelDataCacheService.fetch('app', app.get('id'));
      }
    }
    if (!this.customerService.customer) {
      this.customerService.loadData({ fetchPrices: false });
    }
  }
}
