/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import RolesSettingsRoute from 'embercom/routes/apps/app/settings/base/roles-settings-route';
import { action } from '@ember/object';
import PredicateGroup from 'predicates/models/predicate-group';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class extends RolesSettingsRoute {
  @service intl;
  @service notificationsService;
  @service intercomConfirmService;
  @service store;

  get titleToken() {
    return this.intl.t('apps.app.settings.roles.new.new-role');
  }

  model() {
    let role = this.store.createRecord('role');
    role.defaultPredicatesGroup = this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, []),
    });

    return role;
  }

  @action
  async validateAndSave() {
    try {
      await this.currentModel.validate();
      await this.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.roles.new.role-created'),
      );
    } catch (error) {
      this.notificationsService.notifyError(error.message);
    }
  }

  @action
  async save() {
    try {
      await this.currentModel.save();
      this.transitionTo('apps.app.settings.roles.index');
    } catch (error) {
      let message;
      if (
        this.appService.app.cannotAssignWiderAccess &&
        isPresent(error?.jqXHR?.responseJSON?.tokens)
      ) {
        message = this.intl.t(
          'settings.error_message.cannot_modify_unowned_permissions_role_creation_error',
        );
      } else {
        message =
          error.jqXHR?.responseJSON?.errors ||
          error.jqXHR?.responseJSON?.message ||
          this.intl.t('settings.error_message.cannot_save_role_error');
      }
      throw new Error(message);
    }
  }

  get currentModelIsDirty() {
    // we can't use .hasDirtyAttributes because that's always true for Ember Data models where .isNew is true
    return (
      !this.currentModel.isDestroying &&
      Object.keys(this.currentModel.changedAttributes()).length > 0
    );
  }

  @action
  async willTransition(transition) {
    if (this.currentModelIsDirty) {
      transition.abort();

      if (
        await this.intercomConfirmService.confirm({
          title: this.intl.t('apps.app.settings.roles.close-without-saving-modal.title'),
          primaryButtonType: 'primary-destructive',
          confirmButtonText: this.intl.t(
            'apps.app.settings.roles.close-without-saving-modal.close-without-saving',
          ),
          cancelButtonText: this.intl.t(
            'apps.app.settings.roles.close-without-saving-modal.keep-editing',
          ),
          body: this.intl.t('apps.app.settings.roles.close-without-saving-modal.message-body'),
        })
      ) {
        this.currentModel.unloadRecord();
        await transition.retry();
      }
    } else {
      // unload so it doesn't appear in the index if you click the back button
      this.currentModel.unloadRecord();
    }
  }
}
