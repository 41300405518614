/* RESPONSIBLE TEAM: team-help-desk-experience */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsIndexRoute extends Route {
  @service permissionsService;
  @service appService;
  @service intl;

  DEFAULT_ROUTE = 'apps.app.settings.general';
  ASSIGNMENT_PREFERENCES_ROUTE = 'apps.app.settings.assignment-preferences';

  get titleToken() {
    return this.intl.t('new-settings.page-title');
  }

  beforeModel(transition) {
    if (
      transition.targetName === 'apps.app.settings.index' &&
      !this.appService.app.canSeeNewIASettingsHub
    ) {
      let defaultRoute = isTransitionFromInbox2(transition)
        ? this.permissionsService.getFirstAccessibleInboxSettingsRoute() ||
          this.ASSIGNMENT_PREFERENCES_ROUTE
        : this.DEFAULT_ROUTE;
      let replacementRoute =
        this.permissionsService.getFirstAccessibleSettingsRoute() || defaultRoute;

      this.replaceWith(replacementRoute);
    }
  }
}

function isTransitionFromInbox2(transition) {
  return !!(transition && transition.from && transition.from.name.includes('inbox.workspace'));
}
