/* RESPONSIBLE TEAM: team-proactive-support */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class AppsAppOutboundEmailEditRoute extends ContentEditorRoute {
  @service appService;
  @service navbarCollapsingService;
  @service store;

  get app() {
    return this.appService.app;
  }

  beforeModel() {
    return Promise.all([
      this.app.currentAdmin.getEmailAddressForApp(),
      this.app.loadEmailDomains(),
      this.store.findAll('settings/custom-email-assets-domain'),
    ]);
  }

  analytics = {
    section: 'messages',
    place: 'email_editor',
  };

  afterModel() {
    this.outboundHomeService.setActiveList(objectTypes.email);
  }

  activate() {
    if (!this.navbarCollapsingService.collapsed) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }
}
