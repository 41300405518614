/* RESPONSIBLE TEAM: team-ai-chatbot */
import FinAIAgentSettingsRoute from 'embercom/routes/apps/app/fin-ai-agent/settings';

export default class FinAiAgentRoute extends FinAIAgentSettingsRoute {
  analytics = {
    section: 'fin-ai-agent',
    place: 'new-settings',
  };

  get titleToken(): string {
    return this.intl.t('new-settings.submenu.ai-automation.fin-ai-agent');
  }
}
