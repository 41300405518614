/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import RSVP from 'rsvp';
import { action } from '@ember/object';

export default class NewSubscriptionTypesRoute extends ManageMessagesSettingsRoute {
  @service appService;
  @service intl;
  @service store;

  get app() {
    return this.modelFor('apps.app');
  }

  @action
  async willTransition(transition) {
    let subscriptionTypes = await this.store.findAll('outbound-subscriptions/subscription-type');

    // Safely unloading unsaved subscription because we fetch all subscriptions via store.findAll in editor sidebar
    subscriptionTypes
      .filter((subscriptionType) => !subscriptionType.id)
      .map((subscriptionType) => this.store.unloadRecord(subscriptionType));
  }

  model() {
    return RSVP.hash({
      translationSettings: this.store.findAll('outbound-subscriptions/translation-settings'),
    });
  }
}
