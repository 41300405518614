/* RESPONSIBLE TEAM: team-frontend-tech */
import IntercomTimezoneOptions from 'embercom/lib/intercom-timezone-options';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import { inject as service } from '@ember/service';
import { all } from 'rsvp';
import { action } from '@ember/object';

export default class GeneralRoute extends WorkspaceSettingsRoute {
  @service store;
  @service intl;
  @service customerService;
  @service permissionsService;

  analytics = {
    section: 'settings',
    place: 'general',
  };
  hasAlreadyCancelled = false;

  activate() {
    window.onbeforeunload = function () {
      if (this.controller && this.controller.get('model.hasDirtyAttributes')) {
        return this.intl.t('apps.app.settings.security.warning');
      }
    }.bind(this);
  }

  deactivate() {
    this.hasAlreadyCancelled = false;
    window.onbeforeunload = undefined;
  }

  beforeModel(_transition) {
    if (this.canManageSavedRepliesPermission && this.noAccessProductSettingsPermission) {
      return this.transitionTo('apps.app.settings.saved-replies');
    }

    return all([
      super.beforeModel(...arguments),
      this.customerService.ensureDataIsLoaded.perform({ fetchPrices: false }),
    ]);
  }

  model() {
    let app = this.modelFor('apps/app');
    return this.store.findRecord('general-app-settings', app.get('id'));
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    let timezoneName = model.get('time_zone_name');
    let customTimezoneRequired = typeof timezoneName === 'string' && timezoneName.length > 0;
    for (let i = 0, l = IntercomTimezoneOptions.length; i < l; i++) {
      if (IntercomTimezoneOptions[i].name === timezoneName) {
        controller.set('selectedTimezone', IntercomTimezoneOptions[i]);
        customTimezoneRequired = false;
        break;
      }
    }
    if (customTimezoneRequired) {
      controller.set('useCustomTimezone', true);
      controller.set('customTimezoneName', timezoneName);
      controller.set('selectedTimezone', {
        name: timezoneName,
        description: timezoneName,
      });
    }
  }

  get titleToken() {
    return this.intl.t('settings.general.title-token');
  }

  get canManageSavedRepliesPermission() {
    return this.permissionsService.currentAdminCan('can_manage_saved_replies');
  }

  get noAccessProductSettingsPermission() {
    return !this.permissionsService.currentAdminCan('can_access_product_settings');
  }

  @action
  willTransition(transition) {
    if (this.hasAlreadyCancelled) {
      transition.abort();
      this.hasAlreadyCancelled = false;
    } else if (
      this.controller.get('model.hasDirtyAttributes') &&
      !window.confirm(this.intl.t('apps.app.settings.security.warning-leave-page'))
    ) {
      this.hasAlreadyCancelled = true;
      transition.abort();
    } else {
      return true;
    }
  }
}
