/* RESPONSIBLE TEAM: team-ai-chatbot */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { EMPTY_SUGGESTED_ANSWER_CLUSTER_IDS } from 'embercom/lib/operator/resolution-bot/constants';

export default class FinAIAgentCustomAnswersUnansweredQuestionsRoute extends IntercomRoute {
  @service declare appService: any;

  queryParams = {
    interval: {
      refreshModel: true,
      replace: true,
    },
    language: {
      refreshModel: true,
      replace: true,
    },
    selectedTab: {
      refreshModel: false,
      replace: true,
    },
  };

  model({ interval, language }: { interval: $TSFixMe; language: $TSFixMe }) {
    return ajax({
      url: '/ember/custom_answers/suggested_answer_cluster_ids',
      type: 'GET',
      data: {
        interval,
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        ...(language && { locale: language }),
      },
    }).catch((_e: $TSFixMe) => EMPTY_SUGGESTED_ANSWER_CLUSTER_IDS);
  }

  setupController(
    controller: $TSFixMe,
    model: ModelFromRoute<FinAIAgentCustomAnswersUnansweredQuestionsRoute>,
    _: $TSFixMe,
  ) {
    super.setupController(controller, model, _);
    controller.setProperties({
      clusterIds: model.cluster_ids,
      clusterCount: model.cluster_ids.length,
      dismissedClusterIds: model.dismissed_cluster_ids,
      dismissedCount: model.dismissed_cluster_ids.length,
      clusterRunId: model.cluster_run_id,
      clusterRunUpdatedAt: model.cluster_run_updated_at,
    });
  }
  analytics = {
    section: 'ai-chatbot',
    place: 'custom-answers-unanswered-questions',
  };
}
