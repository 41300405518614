/* RESPONSIBLE TEAM: team-ai-chatbot */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';

export default class ResolutionBotRoute extends Route {
  @service store;
  @service appService;
  @service intercomEventService;
  @service modalService;
  @service intl;

  titleToken = this.intl.t('operator.sub-menu.resolution-bot.title');

  get app() {
    return this.appService.app;
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    let showUpgradeModal =
      transition &&
      transition.to &&
      transition.to.queryParams &&
      transition.to.queryParams.add_answer_bot_modal;
    if (showUpgradeModal) {
      controller.set('launchUpgradeModal', true);
    }
  }

  beforeModel() {
    if (this.app.canUseFinExperience) {
      this.titleToken = this.intl.t('operator.sub-menu.fin.title');
    } else {
      this.titleToken = this.intl.t('operator.sub-menu.resolution-bot.title');
    }
  }

  async model() {
    let findDescriptorPromise = async () => {
      return this.store.findAll('conversation-attributes/descriptor');
    };
    let findCustomObjectsPromise = async () => {
      return this.store.findAll('custom-objects/object-type');
    };

    let findInsertableActionsPromise = async () => {
      return this.store.findAll('workflow-connector/insertable-action');
    };

    let [descriptors, customObjects] = await Promise.all([
      findDescriptorPromise(),
      findCustomObjectsPromise(),
      findInsertableActionsPromise(),
    ]);

    return hash({
      descriptors,
      customObjects,
    });
  }

  resetController(controller) {
    controller.set('launchUpgradeModal', false);
  }

  @action
  didTransition() {
    if (this.app.canUseFinExperience) {
      this.intercomEventService.trackEvent('fin-automation-page-visited');
    } else {
      this.intercomEventService.trackEvent('answer-bot-visited');
    }
  }
}
