/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';

export default Route.extend({
  notificationsService: service(),
  intl: service(),
  createTemplate(transition) {
    let templateId = transition.to.queryParams['templateId'];
    let app = this.modelFor('apps.app');

    post('/ember/email_templates', {
      template_id: templateId,
      app_id: app.id,
      type: 'blocks',
    }).then(
      (template) => {
        transition.abort();
        return this.replaceWith(
          'apps.app.outbound.settings.email-templates.edit-template',
          template.id,
        );
      },
      (error) => {
        transition.abort();
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('outbound.settings.email-templates.new-template-error'),
        });
        return this.replaceWith('apps.app.outbound.settings.email-templates');
      },
    );
  },

  beforeModel(transition) {
    this._super(...arguments);
    this.createTemplate(transition);
  },
});
