/* RESPONSIBLE TEAM: team-data-interop */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import type Transition from '@ember/routing/transition';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { get } from 'embercom/lib/ajax';

export default class CustomActionsCustomAction extends SettingsRoute {
  @service declare store: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare intercomConfirmService: $TSFixMe;
  @service declare contentImportService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;

  @tracked filters = {
    success: 'any',
    error_type: 'any',
    created_at: 168,
  };

  analytics = {
    section: 'settings',
    place: 'action-builder',
  };

  get titleToken() {
    return this.intl.t('new-settings.app-settings.custom-actions.action-builder-title');
  }

  async beforeModel(transition: Transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_developer_hub',
      transition,
      'apps.app.settings',
    );

    let action = this.store.peekRecord('workflow-connector/action', transition.to.params.id);

    if (action) {
      action.unloadRecord();
    }
  }

  async model(params: $TSFixMe) {
    let ticketTypes = await this.store.findAll('inbox/ticket-type');
    let objectTypes = await this.store.findAll('custom-objects/object-type');
    // conversation-attributes/descriptor are loaded so they can be used by attribute-service
    let conversationAtributes = await this.store.findAll('conversation-attributes/descriptor');

    await Promise.all([
      this.contentImportService.fetchAiContentLibrarySummary(),
      this.contentImportService.fetchContentIngestionState(),
    ]);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'settings',
      action_id: params.id,
    });
    return await hash({
      action: this.store.find('workflow-connector/action', params.id),
      objectTypes,
      conversationAtributes,
      actionLogs: [],
      ticketTypes,
      loadNextPage: this.loadNextPage,
      updateFilters: this.updateFilters,
    });
  }

  @task({ restartable: true })
  *loadNextPage(): TaskGenerator<void> {
    let actionId = this.controller.model.action.id;
    let actionLogs = this.controller.model.actionLogs;
    let currentPage = actionLogs.meta ? actionLogs.meta.page : 0;
    let nextPage = actionLogs.meta ? actionLogs.meta.next_page : 1;
    if (nextPage > currentPage) {
      let actionLogParams = {
        page: nextPage,
        app_id: this.appService.app.id,
        action_id: actionId,
      } as $TSFixMe;
      if (this.filters) {
        actionLogParams.success = this.filters.success;
        actionLogParams.error_type = this.filters.error_type;
        actionLogParams.created_at = this.filters.created_at;
      }

      let response = yield get(
        '/ember/workflow_connector/action_execution_results',
        actionLogParams,
      );

      this.store.pushPayload({
        'workflow-connector/action-execution-result': response.action_execution_results,
      });

      actionLogs.set('meta', response.meta);
      actionLogs.pushObjects(
        response.action_execution_results.map((aer: $TSFixMe) =>
          this.store.peekRecord('workflow-connector/action-execution-result', aer.id),
        ),
      );
    }
  }

  setupController(_controller: $TSFixMe, _model: $TSFixMe) {
    super.setupController(...arguments);
    taskFor(this.loadNextPage).perform();
  }

  @action
  updateFilters(filters = {}) {
    this.controller.model.actionLogs.set('meta', null);
    this.controller.model.actionLogs.clear();
    this.filters = filters as $TSFixMe;
    taskFor(this.loadNextPage).perform();
  }

  @action
  async willTransition(transition: Transition) {
    let confirmOptions = {
      primaryButtonType: 'primary-destructive',
      title: this.intl.t('workflow-connector.builder.modal.title'),
      confirmButtonText: this.intl.t('workflow-connector.builder.modal.confirm-button'),
      cancelButtonText: this.intl.t('workflow-connector.builder.modal.cancel-button'),
      body: this.intl.t('workflow-connector.builder.modal.body'),
    };
    if (this.controller.model.action.hasDirtyAttributes) {
      transition.abort();
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (isConfirmed) {
        this.controller.model.action.rollbackAllAttributes();
        transition.retry();
      }
    }
  }
}
