/* RESPONSIBLE TEAM: team-ai-chatbot */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  router: service(),

  redirect(model) {
    this.router.replaceWith('apps.app.automation.resolution-bot.answers.answer.edit', model.id);
  },
});
