/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { Promise as EmberPromise } from 'rsvp';
import Route from '@ember/routing/route';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { inject as service } from '@ember/service';

export default Route.extend(EventTracking, {
  store: service(),

  titleToken(model) {
    if (model.defaultLocalizedContent) {
      return `${model.defaultLocalizedContent.name || 'Add a name'} | Help Center`;
    }
    return `${model.name} | Help Center`;
  },

  reloadCollectionAndChildren(collectionId) {
    // We need the ancestors or else the parent relationship will cause Ember
    // issue a new request for each ancestor
    return this.store.query('articles/article-group', {
      parent_id: collectionId,
      include_ancestors: true,
      include_article_stats: false,
    });
  },

  copyModelToCollectionsController(model) {
    // the collections controller template includes a header, which contains info
    // from the route's 'collection' property (e.g. the collection name)
    let collectionsController = this.controllerFor('apps.app.articles.site.collections');
    collectionsController.set('collection', model);
  },

  beforeModel(transition) {
    let collectionId = transition.to.params.identifier;
    if (collectionId === 'home') {
      return this.replaceWith('apps.app.articles.site.collections');
    }

    // sometimes we have the collection already in the store. we still want to
    // reload later to make sure we have fresh data, but we can copy the data
    // over to the parent route at this point so that the UI feels more
    // responsive.
    this.copyModelToCollectionsController(this.getModelFromCache(collectionId));

    return EmberPromise.all([
      this.reloadCollectionAndChildren(collectionId),
      this.store.query('article-multilingual', {
        collection_id: collectionId,
        disable_pagination: true,
      }),
    ]);
  },

  getModelFromCache(collectionId) {
    return this.store.peekRecord('articles/article-group', collectionId);
  },

  model(params) {
    return this.getModelFromCache(params.identifier);
  },

  afterModel(model) {
    if (!model) {
      this.transitionTo('apps.app.articles.site.collections');
    } else if (model.get('isSection')) {
      this.transitionTo('apps.app.articles.site.collections.collection', model.get('parent.id'));
    }
    this.copyModelToCollectionsController(model);
    $('.js__collections__content-pane').scrollTop(0);
  },

  actions: {
    error(response) {
      if (response.errorThrown === 'Not Found') {
        this.transitionTo('apps.app.articles.site.collections');
      } else {
        return true;
      }
    },

    deleteCollection(collection) {
      let collectionController = this.controllerFor(
        'apps.app.articles.site.collections.collection',
      );
      collectionController.send('deleteCollection', collection);
    },

    didTransition() {
      // Prevents race conditions when changing locale tabs while saving state
      this.currentModel.initLocalizedContent();
    },
  },
});
