/* RESPONSIBLE TEAM: team-ai-chatbot */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default class AppsAppOperatorResolutionBotBehaviorsIndexRoute extends IntercomRoute {
  @service router;

  redirect() {
    this.router.replaceWith('apps.app.automation.resolution-bot.behaviors');
  }
}
