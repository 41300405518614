/* RESPONSIBLE TEAM: team-tickets-1 */
import Route from '@ember/routing/route';
//@ts-ignore

export default class ExternalLinkShieldRoute extends Route {
  queryParams = {
    href: { refreshModel: true },
  };

  model(params: any) {
    let linkURL = params.href;
    let linkDomain;

    try {
      linkDomain = new URL(params.href).hostname;
    } catch {
      linkDomain = '';
    }

    if (linkDomain.startsWith('www.')) {
      linkDomain = linkDomain.slice(4);
    }

    return {
      linkURL: linkURL.toString(),
      linkDomain,
      closeTab: () => window.close(),
    };
  }
}
