/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/-private/transition';
import { get } from 'embercom/lib/ajax';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type IntlService from 'embercom/services/intl';
import type ArticleGroup from 'embercom/models/articles/article-group';
import RSVP from 'rsvp';

export interface CollectionModel {
  helpCenterSite: any;
  allCollections: any;
  allArticlesSummaries: any;
}

export default class CollectionRoute extends IntercomRoute {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare helpCenterService: any;
  @service declare intl: IntlService;
  @service declare store: Store;

  beforeModel() {
    // @ts-ignore
    return this.controllerFor('apps.app.articles.site.collections').set('collection', null);
  }

  async model(params: any): Promise<CollectionModel | undefined> {
    let model;
    try {
      model = await RSVP.hash({
        allCollections: this.getAllCollections(params.id),
        allArticlesSummaries: this.getAllArticleSummariesInGroups(params.id),
        helpCenterSite: this.gethelpCenterSite(params.id),
      });
    } catch (error) {
      this.intercomEventService.trackEvent('educate-event', {
        action: 'error',
        object: 'site_collection_list',
        place: 'site_collection_list',
        owner: 'educate',
        error: error.message,
      });

      this.transitionTo('apps.app.articles');
    }

    return model;
  }

  afterModel(model: CollectionModel, transition: Transition) {
    let { allCollections } = model;
    let collectionId = transition.to.queryParams.collectionId;
    allCollections.forEach(
      (collection: ArticleGroup) => (collection.shouldFocusName = collectionId === collection.id),
    );
  }

  activate() {
    this.intercomEventService.trackEvent('educate-event', {
      action: 'visited',
      object: 'site_collection_list',
      place: 'site_collection_list',
      owner: 'educate',
    });
    // eslint-disable-next-line ember/no-jquery
    $('.js__collections__content-pane').scrollTop(0);
  }

  getAllCollections(helpCenterId: string) {
    return this.store.query('articles/article-group', {
      include_article_stats: true,
      help_center_ids: [helpCenterId],
    });
  }

  async getAllArticleSummariesInGroups(helpCenterId: string) {
    return get(
      `/ember/articles_collections/articles/?app_id=${this.app.id}&help_center_id=${helpCenterId}`,
    );
  }

  get app() {
    return this.appService.app;
  }

  async gethelpCenterSite(helpCenterId: string) {
    await this.helpCenterService.forceFetchSite(helpCenterId);
    return this.store.peekRecord('help-center-site', helpCenterId);
  }

  @action
  createCollection() {
    this.controllerFor('apps.app.articles.site.collections').send('createCollection');
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
