/* RESPONSIBLE TEAM: team-ai-chatbot */
import FinAIAgentCustomAnswersAnswerShowRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer/show';

export default class AutomationFinAIAgentCustomAnswersAnswerShowRoute extends FinAIAgentCustomAnswersAnswerShowRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.custom-answers.answer.show',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer.show';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer.show';
}
