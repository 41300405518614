/* RESPONSIBLE TEAM: team-ai-chatbot */
import FinAIAgentCustomAnswersUnansweredQuestionsRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/unanswered-questions';

export default class AnswerClustersRoute extends FinAIAgentCustomAnswersUnansweredQuestionsRoute {
  analytics = {
    section: 'resolution-bot',
    place: 'clusters',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.unanswered-questions';
  templateName = 'apps.app.fin-ai-agent.custom-answers.unanswered-questions';
}
