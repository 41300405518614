/* RESPONSIBLE TEAM: team-data-interop */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CustomAuthenticationTokensNewRoute extends SettingsRoute {
  @service store;
  @service intl;
  @service intercomConfirmService;

  model() {
    return this.store.createRecord('custom-authentication/token');
  }

  get currentModelIsDirty() {
    // we can't use .hasDirtyAttributes because that's always true for Ember Data models where .isNew is true
    return (
      !this.currentModel.isDestroying &&
      Object.keys(this.currentModel.changedAttributes()).length > 0
    );
  }

  @action
  async willTransition(transition) {
    let confirmOptions = {
      primaryButtonType: 'primary-destructive',
      title: this.intl.t('workflow-connector.builder.modal.title'),
      confirmButtonText: this.intl.t('workflow-connector.builder.modal.confirm-button'),
      cancelButtonText: this.intl.t('workflow-connector.builder.modal.cancel-button'),
      body: this.intl.t('workflow-connector.builder.modal.body'),
    };
    if (this.currentModelIsDirty) {
      transition.abort();
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }
      this.currentModel.unloadRecord();
      transition.retry();
    } else {
      // unload so it doesn't appear in the index if you click the back button
      this.currentModel.unloadRecord();
    }
  }
}
