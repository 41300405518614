/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import storage from 'embercom/vendor/intercom/storage';
import { readOnly } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';
import { DataFormat } from 'embercom/objects/content-service/saved-views/constants/data-formats';

export default Route.extend({
  store: service(),
  permissionsService: service(),
  helpCenterService: service(),
  intercomEventService: service(),
  onboardingHomeExternalStepService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-knowledge-and-data-setup',
    };
  },

  analytics: {
    section: 'educate',
  },

  async model(params) {
    await this.helpCenterService.maybeFetchSite();
    let site = this.helpCenterService.site;

    if (isPresent(params.selectedLocaleId) && !site.supportsLocale(params.selectedLocaleId)) {
      this.transitionTo({ queryParams: { selectedLocaleId: undefined } });
    }

    return site;
  },

  async afterModel(model, transition) {
    this.set('helpCenterSite', model);
    if (transition.to.queryParams.external_step) {
      this.onboardingHomeExternalStepService.start(transition.to.queryParams.external_step, {
        source: 'articles route afterModel',
      });
    }
    await this.store.query('content-service/saved-view', {
      scope: Scope.ARTICLES,
      saved_view_data_format: DataFormat.ARTICLES,
    });
  },

  activate() {
    storage.set('lastProductUsed', 'articles');
  },

  async deactivate() {
    // TODO: Create a re-usable hook and use for all external step routes.
    // use willTransition and check if transition.to matches external step
    // tourInProgressRoute before unsetting. Explore other options too.
    this.set('controller.externalStep', null);
  },

  actions: {
    didTransition() {
      this.intercomEventService.trackEvent('articles-visited');
    },
  },
});
