/* RESPONSIBLE TEAM: team-help-desk-ai */

import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { registerDestructor } from '@ember/destroyable';
import { dropTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type CopilotApi from 'embercom/services/copilot-api';
import type InboxState from 'embercom/services/inbox-state';
import type LogService from 'embercom/services/log-service';
import type Conversation from 'embercom/objects/inbox/conversation';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { TrackedArray } from 'tracked-built-ins';
import { captureException } from '@sentry/browser';
import type Session from 'embercom/services/session';
import ENV from 'embercom/config/environment';

interface CopilotQuestionSuggestionsArgs {
  conversationId?: Conversation['id'];
  userCommentId?: RenderablePart['id'];
  hasSuggestionsPinned?: boolean;
  isConversationLoading?: boolean;
}

export type CopilotSuggestion = {
  question: string;
  emoji: string;
  generatedForInboxConversationId: number;
  answerBotTransactionId: string;
};

export default class CopilotQuestionSuggestions extends Resource<
  Named<CopilotQuestionSuggestionsArgs>
> {
  @service declare copilotApi: CopilotApi;
  @service declare inboxState: InboxState;
  @service declare logService: LogService;
  @service declare session: Session;

  private lastConversationId?: number;
  private lastUserCommentId?: number;
  private previousIsConversationLoading?: boolean;
  private previousHasSuggestionsPinned?: boolean;

  private suggestionsUsedInCurrentConversation = new TrackedArray<string>();
  @tracked private _suggestionsResponse:
    | Awaited<ReturnType<CopilotApi['extractSuggestionsForConversation']>>
    | undefined;

  constructor(owner: unknown) {
    super(owner);
    registerDestructor(this, this.teardown);
  }

  modify(_: never, args: CopilotQuestionSuggestionsArgs) {
    let shouldFetch = false;
    let shouldTearDown = false;

    // Determine if we should fetch based on conversation or user comment changes
    if (
      this.lastConversationId !== args.conversationId ||
      this.lastUserCommentId !== args.userCommentId
    ) {
      shouldTearDown = true;
      shouldFetch = true;
      if (this.lastConversationId !== args.conversationId) {
        this.suggestionsUsedInCurrentConversation = new TrackedArray<string>();
      }
    }

    // Determine if we should fetch based on conversation loading state
    if (this.previousIsConversationLoading !== args.isConversationLoading) {
      shouldTearDown = true;
      shouldFetch = !args.isConversationLoading;
    }

    // Determine if we should fetch based on suggestions pinned state
    if (this.previousHasSuggestionsPinned !== args.hasSuggestionsPinned) {
      shouldTearDown = true;
      shouldFetch = !!args.hasSuggestionsPinned;
    }

    // Tear down if necessary
    // This is done before fetching to ensure that the fetch task is cancelled
    if (shouldTearDown || shouldFetch) {
      this.teardown();
    }

    // Update last known states
    this.lastConversationId = args.conversationId;
    this.lastUserCommentId = args.userCommentId;
    this.previousIsConversationLoading = args.isConversationLoading;
    this.previousHasSuggestionsPinned = args.hasSuggestionsPinned;

    // Perform fetch if conditions are met
    if (
      shouldFetch &&
      this.lastConversationId &&
      !this.previousIsConversationLoading &&
      this.previousHasSuggestionsPinned
    ) {
      taskFor(this.fetchSuggestionsTask).perform();
    }
  }

  // Public API

  get visibleSuggestions(): CopilotSuggestion[] | undefined {
    if (this.shouldShowSuggestions) {
      return this.suggestions;
    }
    return;
  }

  @action handleSuggestionClick(suggestion: CopilotSuggestion) {
    if (!this.lastConversationId) {
      return;
    }

    this.assertGuardrails('sendCopilotSuggestion', suggestion.generatedForInboxConversationId);

    this.markSuggestionAsUsedForActiveConversation(suggestion.question);

    this.inboxState.conversationsThatUsedSuggestions.add(this.lastConversationId);

    this.copilotApi.triggerAndSearchCopilotQuery(suggestion.question);
  }

  // Private API

  private get shouldShowSuggestions(): boolean {
    // we only want to show suggestions when a teammate hasn't interacted with them in this session
    return !!(
      this.lastConversationId &&
      !this.inboxState.conversationsThatUsedSuggestions.has(this.lastConversationId)
    );
  }

  private get suggestions(): CopilotSuggestion[] | undefined {
    if (this.isLoading || !this._suggestionsResponse) {
      return;
    }
    let { conversationId, answerBotTransactionId, suggestions } = this._suggestionsResponse;

    this.assertGuardrails('showCopilotSuggestion', conversationId);

    return suggestions
      .filter(
        (suggestion) => !this.suggestionsUsedInCurrentConversation.includes(suggestion.question),
      )
      .map((suggestion) => ({
        ...suggestion,
        generatedForInboxConversationId: conversationId,
        answerBotTransactionId,
      }));
  }

  @action private teardown() {
    this._suggestionsResponse = undefined;
    taskFor(this.fetchSuggestionsTask).cancelAll();
  }

  @dropTask
  private *fetchSuggestionsTask() {
    let response = (yield this.copilotApi.extractSuggestionsForConversation(
      this.lastConversationId!,
      this.suggestionsUsedInCurrentConversation,
    )) as Awaited<ReturnType<CopilotApi['extractSuggestionsForConversation']>>;

    if (!response) {
      return;
    }

    this.assertGuardrails('fetchCopilotSuggestion', response.conversationId);

    this._suggestionsResponse = response;
  }

  private markSuggestionAsUsedForActiveConversation(suggestion: string) {
    this.suggestionsUsedInCurrentConversation.push(suggestion);
  }

  private get isLoading() {
    return taskFor(this.fetchSuggestionsTask).isRunning;
  }

  private assertGuardrails(location: string, conversationId: Conversation['id']) {
    if (conversationId !== this.lastConversationId) {
      let error = new Error('Attempted to show copilot suggestion from a different conversation');
      let stack = error.stack?.split('\n').slice(1, 15).join('\n');
      this.logService.logJSON({
        log_type: location,
        actual_conversation_id: this.lastConversationId,
        expected_conversation_id: conversationId,
        stack,
      });

      if (
        this.session.workspace.isFeatureEnabled('composer-insertion-mismatch-killswitch') &&
        ENV.environment !== 'test'
      ) {
        captureException(error);
      } else {
        throw error;
      }
    }
  }
}
