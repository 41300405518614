/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import storage from 'embercom/vendor/intercom/storage';
import { inject as service } from '@ember/service';
import Range from 'embercom/models/reporting/range';

export default class ReportsRoute extends Route {
  @service store;
  @service appService;
  @service reportingService;
  @service permissionsService;
  @service favoritesService;
  @service reportingFolderManagerService;
  @service reportingMetrics;
  @service reportingViews;
  @service reportingTemplates;
  @service reportAccessService;

  reportsForNavBar = [];

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-reporting',
    };
  }

  analytics = {
    section: 'reports',
  };
  titleToken = 'Reports';
  queryParams = {
    rangeEnd: { refreshModel: true },
    rangeStart: { refreshModel: true },
  };

  activate() {
    storage.set('lastProductUsed', 'reports');
  }

  async beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning('can_access_reporting', transition);

    if (transition.to.queryParams.embeddedContent) {
      this.controllerFor('apps/app').set('showPrimaryNav', false);
    }

    let { rangeStart, rangeEnd } = transition.to.queryParams;
    let range = Range.createFromParams(rangeStart, rangeEnd, this.appService.app.timezone);
    this.reportingService.set('range', range);
    //setup platform
    let promises = [];
    //setup metrics, favorites
    if (this.reportingViews.canViewCustomReports) {
      promises.push(this.reportingViews.setUp.perform());
    }
    promises.push(this.reportingMetrics.setup.perform());
    promises.push(this.favoritesService.preloadFavorites.perform());
    promises.push(this.reportingFolderManagerService.loadFlexibleFolders());
    if (this.appService.app.canSeeR2Beta && this.reportingViews.canViewCustomReports) {
      promises.push(this.reportingTemplates.loadTemplates());
    }
    if (this.appService.app.canShareReportsInternally) {
      promises.push(this.reportAccessService.loadAdminReportAccess());
    }
    await Promise.all(promises);
  }

  deactivate() {
    this.reportingFolderManagerService.resetFolders();
    this.reportingViews.shutDown();
  }
}
