/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ReportsIndexRoute extends Route {
  @service appService;
  @service router;

  beforeModel(_transition) {
    if (this.appService.app.canSeeNewReportingOverview) {
      this.router.transitionTo('apps.app.reports.new-overview');
    } else {
      this.router.transitionTo('apps.app.reports.overview');
    }
  }
}
