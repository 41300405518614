/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';
import type GuideLibraryService from 'embercom/services/guide-library-service';

export default class IntershopRoute extends Route {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare guideLibraryService: GuideLibraryService;

  analytics = {
    section: 'channels',
    place: 'channels_hub',
  };

  get titleToken(): string {
    return this.intl.t('channels.channels-hub.title');
  }

  get app(): any {
    return this.appService.app;
  }

  get products(): any {
    return this.app.products;
  }

  beforeModel() {
    if (this.appService.app.canSeeNewIASettingsHub) {
      this.router.transitionTo('apps.app.settings.channels.messenger.install', {
        queryParams: { tab: 'web', section: 'messenger-setup' },
      });
    }
  }

  model() {
    return this.products;
  }

  afterModel() {
    this.guideLibraryService.updateIsMessengerInstalled();
  }
}
