/* RESPONSIBLE TEAM: team-proactive-support */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import RSVP from 'rsvp';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class EditSubscriptionTypeRoute extends ManageMessagesSettingsRoute {
  @service intl;
  @service store;

  @action
  willTransition(transition) {
    if (this.controller.model.subscriptionType.hasDirtyAttributes) {
      if (confirm(this.intl.t('outbound.settings.subscription-types.warn-unsaved-changes'))) {
        this.controller.model.subscriptionType.rollbackAttributes();
      } else {
        transition.abort();
      }
    }
  }

  model(params) {
    return RSVP.hash({
      subscriptionType: this.store.findRecord(
        'outbound-subscriptions/subscription-type',
        params.id,
      ),
      translationSettings: this.store.findAll('outbound-subscriptions/translation-settings'),
    });
  }
}
