/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class FinContentReviewsRoute extends Route {
  @service declare router: RouterService;
  redirect(): void {
    this.router.replaceWith('apps.app.automation.resolution-bot.fin-content-reviews');
  }
}
