/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';

export default ProductSettingsRoute.extend({
  store: service(),
  intl: service(),
  appService: service(),

  async model() {
    return {
      schedules: await this.store.findAll('office-hours-schedule', { reload: true }),
      settings: await this.store.findRecord(
        'messenger-settings/availability',
        this.appService.app.id,
      ),
    };
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties({
      app: this.modelFor('apps/app'),
    });
  },

  get titleToken() {
    return this.intl.t('components.settings.office-hours.title');
  },
});
