/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type RouterService from '@ember/routing/router-service';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

export default class StandaloneRoute extends IntercomRoute {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare store: any;
  @service declare greatGuidanceService: GreatGuidanceService;

  beforeModel() {
    let appsController: any = this.controllerFor('apps/app');
    appsController.set('showPrimaryNav', false);
    this.greatGuidanceService.hideFloatingWidget = true;
  }

  redirect(): any {
    if (!this.appService.app.canUseStandalone) {
      return this.router.transitionTo('apps.app', this.appService.app.id);
    }
  }
}
