/* RESPONSIBLE TEAM: team-ai-chatbot */
import FinAIAgentCustomAnswersAnswerShowIndexRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer/show/index';

export default class ResolutionBotAnswersAnswerShowIndexRoute extends FinAIAgentCustomAnswersAnswerShowIndexRoute {
  analytics = {
    section: 'resolution-bot',
    place: 'answer-show-index',
  };

  templateName = 'apps.app.fin-ai-agent.custom-answers.answer.show.index';
  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer.show.index';
}
