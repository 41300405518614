/* RESPONSIBLE TEAM: team-data-interop */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CustomAuthenticationTokensEditRoute extends SettingsRoute {
  @service store;
  @service intl;
  @service intercomConfirmService;

  async model(params) {
    return await this.store.find('custom-authentication/token', params.id);
  }

  @action
  async willTransition(transition) {
    let confirmOptions = {
      primaryButtonType: 'primary-destructive',
      title: this.intl.t('workflow-connector.builder.modal.title'),
      confirmButtonText: this.intl.t('workflow-connector.builder.modal.confirm-button'),
      cancelButtonText: this.intl.t('workflow-connector.builder.modal.cancel-button'),
      body: this.intl.t('workflow-connector.builder.modal.body'),
    };
    if (this.currentModel.hasDirtyAttributes) {
      transition.abort();
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }
      this.currentModel.rollbackAllAttributes();
      transition.retry();
    }
  }
}
