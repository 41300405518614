/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

export default Route.extend(EventTracking, {
  intercomEventService: service(),
  permissionsService: service(),
  notificationsService: service(),
  store: service(),

  async beforeModel(transition) {
    let app = this.modelFor('apps.app');

    this.permissionsService.ensurePermissionWhenTransitioning(
      CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION,
      transition,
    );

    try {
      let article = await this.store
        .createRecord('article-multilingual', {
          authorId: app.get('currentAdmin.id'),
          folder: this.store.peekRecord('articles/article-group', 'home'),
        })
        .save();

      article.set('state', 'published');
      this.trackEducateArticleEvent(article, {
        action: 'created',
        place: 'article_list',
        eventName: 'created_educate_article',
      });
      this.intercomEventService.trackEvent('visited_article_composer', {
        action: 'visited',
        object: 'article',
        place: 'article_composer',
        owner: 'growth-sp',
      });

      this.replaceWith(
        'apps.app.articles.articles.article-content.edit',
        article.articleContents.firstObject,
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
      this.replaceWith('apps.app.articles.articles.index');
    }
  },
});
