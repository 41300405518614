/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { fetchAdminPageviews } from 'embercom/lib/admin-view-tracking';

export default Route.extend({
  store: service(),
  beforeModel() {
    return fetchAdminPageviews(this.store);
  },
  model() {
    return this.store.peekRecord('articles/article-group', 'home');
  },
  async setupController(controller, model) {
    this._super(controller, model);

    let currentImport = await controller.getApiImport();
    if (currentImport) {
      controller.set('hasActiveApiImport', true);
      controller.set('currentImport', currentImport);
    } else {
      controller.set('hasActiveApiImport', false);
      controller.set('currentImport', null);
    }

    controller.getSyncSettings.perform();
  },
  registerTreeAction: action(function (createNewCollectionAction) {
    this.set('createNewCollectionAction', createNewCollectionAction);
  }),
  createNewCollection: action(function () {
    if (this.createNewCollectionAction) {
      this.createNewCollectionAction();
    }
  }),
  deleteCollection: action(function (collection) {
    let collectionController = this.controllerFor('apps.app.articles.site.collections.collection');
    collectionController.send('deleteCollection', collection);
  }),
});
