/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class FinContentRoute extends Route {
  @service declare router: RouterService;

  redirect(): void {
    this.router.replaceWith('apps.app.automation.resolution-bot.fin-content');
  }
}
