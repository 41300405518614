/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';

export default class WorkFlowConnectorActionsCustomAction extends SettingsRoute {
  @service store;
  @service intl;
  @service intercomEventService;
  @service intercomConfirmService;
  @service contentImportService;

  analytics = {
    section: 'settings',
    place: 'action-builder',
  };
  titleToken = 'Action Builder';

  async beforeModel(transition) {
    let action = this.store.peekRecord('workflow-connector/action', transition.to.params.id);

    if (action) {
      action.unloadRecord();
    }
  }

  async model(params) {
    let ticketTypes = await this.store.findAll('inbox/ticket-type');

    await Promise.all([
      this.contentImportService.fetchAiContentLibrarySummary(),
      this.contentImportService.fetchContentIngestionState(),
    ]);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'settings',
      action_id: params.id,
    });

    return await hash({
      action: this.store.find('workflow-connector/action', params.id),
      actionLogs: [],
      ticketTypes,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.loadNextPage.perform();
  }

  @action
  async willTransition(transition) {
    let confirmOptions = {
      primaryButtonType: 'primary-destructive',
      title: this.intl.t('workflow-connector.builder.modal.title'),
      confirmButtonText: this.intl.t('workflow-connector.builder.modal.confirm-button'),
      cancelButtonText: this.intl.t('workflow-connector.builder.modal.cancel-button'),
      body: this.intl.t('workflow-connector.builder.modal.body'),
    };
    if (this.currentModel.action.hasUnsavedChanges) {
      transition.abort();
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (isConfirmed) {
        this.currentModel.action.rollbackAllAttributes();
        transition.retry();
      }
    }
  }
}
