/* RESPONSIBLE TEAM: team-purchase-experience */
import Route from '@ember/routing/route';
import { get } from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import { inject as service } from '@ember/service';
export default class Referrals extends Route {
  @service declare appService: any;
  @service declare router: any;

  beforeModel() {
    if (!this.appService.app.canUseReferrals) {
      this.router.transitionTo('apps.app.index');
    }
  }

  async model() {
    let referral;
    try {
      referral = await get(`/ember/referrals?app_id=${this.appService.app.id}`);
    } catch (error) {
      captureException(new Error('Referrals get failed'), {
        fingerprint: ['referrals'],
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
    }
    return referral.code;
  }
}
