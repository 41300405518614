/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { startTour } from 'embercom/lib/intercom-widget-helper';
import { type InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { parseUpdatedCount } from 'embercom/objects/inbox/inboxes/inbox';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type InboxCounters from 'embercom/services/inbox2-counters';
import type Inbox2OptIn from 'embercom/services/inbox2-opt-in';
import { type CountersUpdatedEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName, CountersUpdatedSource } from 'embercom/services/nexus';
import type Session from 'embercom/services/session';
import type TracingService from 'embercom/services/tracing';
import storage from 'embercom/vendor/intercom/storage';
import getTransitionParams from 'embercom/lib/router/get-transition-params';
import type RouteInfo from '@ember/routing/route-info';
import type InboxRedirectionService from 'embercom/services/inbox-redirection-service';
import { get as getRequest, post } from 'embercom/lib/ajax';
import { INBOX_PAGEVIEW_KEY } from 'embercom/lib/operator/resolution-bot/constants';
import type Router from '@ember/routing/router-service';

export type ExtendedTransition = Transition & {
  targetName: string;
  intent: { url: string | null };
  routeInfos: RouteInfo[];
};

// tour: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/26372173
let collaboratorComposerOnboardingTourId = 370585;
// tour: https://app.intercom.com/a/apps/tx2p130c/outbound/tour/26408173
let collaboratorWithoutComposerOnboardingTourId = 371166;
let collaboratorWithoutComposerOnboardingLocalStorage = 'collaboratorOnboardingWithoutComposer';
let conversationRoute = 'inbox.workspace.inbox.inbox.conversation.conversation';
let inboxRoute = 'inbox.workspace.inbox.inbox.index';
let singleConversationRoute = 'inbox.workspace.inbox.conversation.conversation';

export default class InboxRoute extends Route {
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare nexus: Nexus;
  @service declare session: Session;
  @service declare inbox2OptIn: Inbox2OptIn;
  @service declare inbox2Counters: InboxCounters;
  @service declare tracing: TracingService;
  @service declare router: Router;
  @service declare inboxRedirectionService: InboxRedirectionService;

  async model(params: { inbox_category: InboxCategory; inbox_id: string }): Promise<Inbox> {
    this.tracing.tagRootSpan({ inbox_category: params.inbox_category, inbox_id: params.inbox_id });

    return await this.inboxApi.fetchInbox(params.inbox_category, params.inbox_id);
  }

  serialize(inbox: Inbox) {
    return {
      inbox_category: inbox.category,
      inbox_id: inbox.id,
    };
  }

  async beforeModel(transition: ExtendedTransition) {
    this.inboxState.activeConversation = undefined;
    if (
      this.session.workspace.isFeatureEnabled('team-product-guidance-great-guidance-milestone-one')
    ) {
      let appCreationDate = this.session.workspace.createdAt;
      let earliestDateForWelcomeHelpdesk = new Date('2024-07-25');

      if (appCreationDate && appCreationDate >= earliestDateForWelcomeHelpdesk) {
        let hasAdminSeenInbox = await this.hasAdminSeenInboxFromPageViews();
        if (!hasAdminSeenInbox) {
          this.markInboxViewAsSeen();
          this.session.teardownTheme();
          this.router.transitionTo('apps.app.welcome-helpdesk', this.session.workspace.id);
          return;
        }
      }
    }
    if (this.session.workspace.isFeatureEnabled('inbox2-single-conversation-view')) {
      if (this.isDeeplink(transition) && !this.isRefresh(transition)) {
        let target = this.getTransitionTarget(transition);
        if (target) {
          if (window.location.hash) {
            this.inboxRedirectionService.setConversationPartAnchor(window.location.hash);
          }
          this.transitionTo(singleConversationRoute, target);
        }
      }
    }
  }

  private async hasAdminSeenInboxFromPageViews() {
    let allPageViews = await getRequest(
      `/ember/admin_pageviews?app_id=${this.session.workspace.id}`,
    );
    let inboxPageViews = allPageViews.filter(
      (pageView: any) => pageView.page_key === INBOX_PAGEVIEW_KEY,
    );
    return inboxPageViews.length > 0;
  }

  private async markInboxViewAsSeen() {
    let params = {
      page_key: INBOX_PAGEVIEW_KEY,
    };
    await post(`/ember/admin_pageviews?app_id=${this.session.workspace.id}`, params);
  }

  private isDeeplink(transition: ExtendedTransition): boolean {
    return transition.targetName === conversationRoute;
  }

  private isRefresh(transition: ExtendedTransition): boolean {
    if (!transition.intent.url) {
      return false;
    }
    let target = this.extractLastUrlPathPart(transition.intent.url);
    let lastViewed;
    try {
      lastViewed = sessionStorage.getItem('inbox2:last-viewed-conversation');
    } catch (e) {
      // Nothing to do here
    }
    if (!lastViewed) {
      return false;
    }
    return target === lastViewed;
  }

  private getTransitionTarget(transition: ExtendedTransition): string | undefined {
    let transitionParams: Partial<Record<string, any>> = getTransitionParams(transition);
    if (transition.intent.url) {
      return this.extractLastUrlPathPart(transition.intent.url);
    } else if (transitionParams[singleConversationRoute]) {
      return transitionParams[singleConversationRoute].conversation_id;
    } else if (transitionParams[conversationRoute]) {
      return transitionParams[conversationRoute].conversation_id;
    }
    return;
  }

  private extractLastUrlPathPart(url: string): string | undefined {
    return url.split('/').pop()?.split('?')[0];
  }

  afterModel(inbox: Inbox, transition: Transition) {
    if (this.session.showLightInbox) {
      if (this.showOnboardingWithComposerForCollaborator(transition.to.name, inbox.count || 0)) {
        startTour(collaboratorComposerOnboardingTourId);
        // collaborator is opted in if collaborator saw the full onboarding tour
        this.inbox2OptIn.optIn();
      } else if (this.showOnboardingWithOutComposerForCollaborator) {
        startTour(collaboratorWithoutComposerOnboardingTourId);
        this.setCollaboratorOnboardingWithoutComposerStatus();
      }
    }
    this.inboxState.setInbox(inbox);
  }

  activate() {
    this.nexus.addListener(NexusEventName.CountersUpdated, this.handleCounterUpdatedEvent);
  }

  deactivate() {
    this.inboxState.clearInbox();
    this.nexus.removeListener(NexusEventName.CountersUpdated, this.handleCounterUpdatedEvent);
  }

  @action
  private async handleCounterUpdatedEvent(e: CountersUpdatedEvent) {
    if (!this.inboxState.activeInbox) {
      return;
    }

    let data = e.eventData;
    if (data.source === CountersUpdatedSource.Database) {
      return;
    }

    let currentInboxCount = parseUpdatedCount([this.inboxState.activeInbox], data)[0];
    if (currentInboxCount !== undefined) {
      this.inbox2Counters.updateCount(this.inboxState.activeInbox, currentInboxCount);
    }
  }

  private showOnboardingWithComposerForCollaborator(transitionName: string, inboxCount: number) {
    return (
      (transitionName === conversationRoute || (transitionName === inboxRoute && inboxCount > 0)) &&
      this.inbox2OptIn.hasNeverSeenInbox2
    );
  }

  private get showOnboardingWithOutComposerForCollaborator() {
    return !this.collaboratorDidOnboardingWithoutComposer && this.inbox2OptIn.hasNeverSeenInbox2;
  }

  private get collaboratorDidOnboardingWithoutComposer() {
    return !!storage.get(collaboratorWithoutComposerOnboardingLocalStorage);
  }

  private setCollaboratorOnboardingWithoutComposerStatus() {
    return storage.set(collaboratorWithoutComposerOnboardingLocalStorage, true);
  }
}
