/* RESPONSIBLE TEAM: team-help-desk-ai */

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import type RouterService from '@ember/routing/router-service';

export default class AiAssistRoute extends WorkspaceSettingsRoute {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;

  analytics = {
    section: 'settings',
    place: 'ai-assist',
  };

  get titleToken() {
    return this.intl.t('ai-assist.settings.title');
  }

  beforeModel() {
    super.beforeModel(...arguments);

    if (!this.appService.app.canAccessAiAssistBeta && !this.appService.app.canUseInboxCopilot) {
      this.router.transitionTo('apps.app.settings');
    }
  }
}
