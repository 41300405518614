/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';

export default class CustomAuthenticationTokensRoute extends SettingsRoute {
  @service appService;
  @service store;
  @service permissionsService;

  titleToken = 'Authentication tokens';

  beforeModel(transition) {
    super.beforeModel(transition);

    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_developer_hub',
      transition,
    );
  }

  async model() {
    return await this.store.findAll('custom-authentication/token');
  }
}
