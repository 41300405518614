/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

export default class KnowledgeHubIndexRoute extends Route {
  @service declare router: RouterService;
  @service declare knowledgeHubService: KnowledgeHubService;

  async beforeModel() {
    this.router.replaceWith('apps.app.knowledge-hub.overview');
  }
}
