/* RESPONSIBLE TEAM: team-tickets-1 */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/-private/transition';
import { type Router } from '@ember/routing';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import type Session from 'embercom/services/session';
import type TicketStateService from 'embercom/services/ticket-state-service';

export default class TicketStates extends BaseSettingsRoute {
  @service declare permissionsService: any;
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare store: Store;
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare ticketStateService: TicketStateService;

  get app() {
    return this.appService.app;
  }

  get titleToken() {
    return this.intl.t('settings.ticket-states.title');
  }

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);
    this.session.switchWorkspace(this.app.id);
    await this.session.workspace.fetchRequiredAttributes();

    let permission = 'can_access_product_settings';
    this.permissionsService.ensurePermissionWhenTransitioning(permission, transition);
  }

  async model() {
    return this.ticketStateService.setup();
  }

  @action didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'ticket_states',
      place: 'ticket_states_details',
    });
  }

  @action refreshRoute() {
    this.refresh();
  }
}
