/* RESPONSIBLE TEAM: team-data-interop */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Transition from '@ember/routing/-private/transition';
import type IntlService from 'ember-intl/services/intl';

export default class CustomActions extends SettingsRoute {
  @service declare store;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService;
  @service declare permissionsService: $TSFixMe;
  @service declare intl: IntlService;

  analytics = {
    section: 'settings',
    place: 'action-builder',
  };

  get titleToken() {
    return this.intl.t('new-settings.app-settings.custom-actions.action-builder-title');
  }

  beforeModel(transition: Transition) {
    super.beforeModel(transition);
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_developer_hub',
      transition,
    );
  }

  async model() {
    let actions = await this.store.findAll('workflow-connector/action');

    if (!actions) {
      return {
        actions: [],
        templateActions: [],
      };
    }

    let workflowActions = actions.filterBy('isTemplate', false);
    let templateActions = actions.filterBy('isTemplate', true);

    return {
      actions: workflowActions,
      templateActions,
    };
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('has_visited_custom_actions');
  }
}
