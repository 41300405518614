/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import CONFIRMATION_MODAL_CONTENT from 'embercom/lib/reporting/custom/navigation-guard';

export default class NewChartCustomReportRoute extends Route {
  @service store;
  @service appService;
  @service navbarCollapsingService;
  @service intercomConfirmService;
  @service notificationsService;
  @service router;
  @service paywallService;
  @service intercomEventService;
  @service permissionsService;
  @service reportingMetrics;
  @service customReportsService;

  queryParams = {
    source: '',
    metricProperty: '',
    viewBy: '',
    metricId: '',
  };

  model(params) {
    let { report, settings } = this.modelFor('apps.app.reports.custom-reports.report.show');
    let metricId = params.metricId || this.reportingMetrics.defaultCustomChartMetric.id;
    let chart = this.store.createRecord('reporting/custom/chart', {
      chartSeries: [{ metricId }],
    });
    chart.updateMetric(metricId, chart.chartSeries.firstObject);

    if (params.viewBy) {
      chart.updateViewBy(params.viewBy);
    }
    return {
      report,
      settings,
      chart,
    };
  }

  activate() {
    if (!this.appService.app.canSeeR2Beta) {
      this.navbarCollapsingService.setActiveSubmenu('Custom reports');
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }
  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }
  @action
  async willTransition(transition) {
    let { report, chart } = this.modelFor(this.routeName);
    let shouldCollapseNavbar = true;
    let currentPage = 'apps.app.reports.custom-reports.report.show.chart.new';
    let isPaywallActive = this.paywallService.isPaywallActive({
      featureName: 'custom_reports',
    });
    if (isPaywallActive) {
      chart.unloadRecord();
    } else if (transition.to.queryParams.cancelling && chart.isNew) {
      chart.unloadRecord();
    } else if (
      !transition.data.skipConfirmation &&
      !transition.to.queryParams.cancelling &&
      chart.isNew &&
      !transition.to.queryParams.cr2AddingToChart &&
      transition.to.name !== currentPage &&
      !isPaywallActive &&
      this.canChangeCustomReports
    ) {
      transition.abort();
      let confirmed = await this.intercomConfirmService.confirm(CONFIRMATION_MODAL_CONTENT);
      if (confirmed === true) {
        transition.data.skipConfirmation = true;
        await report.saveChart(chart);
        this.notificationsService.notifyConfirmation('Your chart has been saved');
        transition.retry();
      } else if (confirmed.canceled) {
        transition.data.skipConfirmation = true;
        chart.unloadRecord();
        transition.retry();
      } else if (confirmed.closed) {
        // Workaround for https://github.com/emberjs/ember.js/issues/5210#issuecomment-623967508
        this.router.location.setURL(this.router.currentURL);
        shouldCollapseNavbar = false;
      }
    }
    if (
      this.navbarCollapsingService.collapsed &&
      shouldCollapseNavbar &&
      !this.appService.app.canSeeR2Beta
    ) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }

  @action
  didTransition() {
    let params = this.paramsFor(this.routeName);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'chart_creator',
      from_url: params.source !== 'custom_report',
    });
  }
}
