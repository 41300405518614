/* RESPONSIBLE TEAM: team-ai-chatbot */
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import type Store from '@ember-data/store';
import type UsageLimitsService from 'embercom/services/usage-limits-service';
import { type ConversationContentSettings } from 'embercom/controllers/apps/app/fin-ai-agent/setup';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import ajax from 'embercom/lib/ajax';
import { get, post } from 'embercom/lib/ajax';
import type SetupController from 'embercom/controllers/apps/app/fin-ai-agent/setup';
import { FIN_SETUP_BEHAVIOR_ENDPOINT } from 'embercom/components/operator/fin/setup/configure-starter-plan';
import { taskFor } from 'ember-concurrency-ts';
import { adminHasSeenFromPageviews, fetchAdminPageviews } from 'embercom/lib/admin-view-tracking';
import { FIN_LANDING_PAGE_PAGEVIEW_KEY } from 'embercom/lib/operator/resolution-bot/constants';
const LAST_VIEWED_QUESTION_EXTRACTION_RUN_ID = 'last-viewed-question-extraction-run-id';

export default class FinAIAgentSetupRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare contentImportService: $TSFixMe;
  @service declare outboundHomeService: $TSFixMe;
  @service declare onboardingHomeService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare usageLimitsService: UsageLimitsService;
  @service declare store: Store;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare reportingMetrics: ReportingMetrics;

  analytics = {
    section: 'ai-chatbot',
    place: 'setup',
  };

  liveResolutionBotBehaviors: $TSFixMe;
  customAnswerCounts: $TSFixMe;
  hasFinEmailWorkflows = false;
  hasFinChatWorkflows = false;
  liveFinWorkflowsCount = 0;
  liveFinEmailWorkflowsCount = 0;
  liveFinChatWorkflowsCount = 0;
  finSetupRuleset: $TSFixMe;
  emailSetupRuleset: $TSFixMe;
  previewConfiguration: $TSFixMe;
  latestQuestionExtractionRunId: number | undefined;
  shouldShowUpdatedLabel: boolean | undefined;
  hasSeenFinLandingPageWithPreview: boolean | undefined;

  async beforeModel() {
    if (!this.appService.app.canUseFinExperience) {
      this.transitionTo('apps.app.automation.resolution-bot.behaviors');
    }
    this.outboundHomeService.setActiveList(objectTypes.resolutionBotBehavior);
    await taskFor(this.reportingMetrics.setup).perform();
    let adminPageviews = await fetchAdminPageviews(this.store);
    this.hasSeenFinLandingPageWithPreview = adminHasSeenFromPageviews(
      adminPageviews,
      FIN_LANDING_PAGE_PAGEVIEW_KEY,
    );
  }

  get app() {
    return this.appService.app;
  }

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.fin-overview.title');
  }

  async model() {
    await Promise.all([
      this.loadLiveResolutionBotBehaviors(),
      this.loadCustomAnswerCounts(),
      this.usageLimitsService.fetchFinUsageLimits(),
      this.getFinWorkflowsCount(),
      this.getFinSetupRuleset(),
      this.getEmailSetupRuleset(),
      this.store.findAll('template'),
      this.contentImportService.fetchAiContentLibrarySummary(),
      this.loadOnboardingGuide(),
      this.getPreviewConfiguration(),
      this.loadLastQuestionGroupsRunDetails(),
    ]);

    let liveFinProfilesCount = 0;

    let liveProfilesWithAiAnswersEnabled: boolean =
      this.liveResolutionBotBehaviors.contentWrappers.some((contentWrapper: ContentWrapper) => {
        let content = contentWrapper.contents.firstObject;
        return content?.contentData?.use_ai_answers;
      });

    let liveProfilesWithCustomAnswersEnabled: boolean =
      this.liveResolutionBotBehaviors.contentWrappers.some((contentWrapper: ContentWrapper) => {
        let content = contentWrapper.contents.firstObject;
        return content?.contentData?.use_custom_answers;
      });

    this.liveResolutionBotBehaviors.contentWrappers.forEach((contentWrapper: ContentWrapper) => {
      let content = contentWrapper.contents.firstObject;
      if (content?.contentData?.use_ai_answers || content?.contentData?.use_custom_answers) {
        liveFinProfilesCount += 1;
      }
    });

    let librarySummary = this.contentImportService.aiContentLibrarySummary;

    this.maybeTriggerFirstSetupIngestion();

    return RSVP.hash({
      liveProfilesWithAiAnswersEnabled,
      liveProfilesWithCustomAnswersEnabled,
      liveFinProfilesCount,
      allArticlesCount: librarySummary[EntityType.ArticleContent]?.total_count || 0,
      finUsableArticlesCount: librarySummary[EntityType.ArticleContent]?.used_by_fin_count || 0,
      allCustomAnswersCount: this.customAnswerCounts.all,
      finUsableCustomAnswersCount: this.customAnswerCounts.finUsable,
      finUsableExternalContentCount:
        librarySummary[EntityType.ExternalContent]?.used_by_fin_count || 0,
      allSnippetsCount: librarySummary[EntityType.ContentSnippet]?.total_count || 0,
      finUsableSnippetsCount: librarySummary[EntityType.ContentSnippet]?.used_by_fin_count || 0,
      allActionsCount: librarySummary[EntityType.WorkflowConnectorAction]?.total_count || 0,
      finUsableActionsCount:
        librarySummary[EntityType.WorkflowConnectorAction]?.used_by_fin_count || 0,
      allFilesCount: librarySummary[EntityType.FileSourceContent]?.total_count || 0,
      finUsableFilesCount: librarySummary[EntityType.FileSourceContent]?.used_by_fin_count || 0,
      helpCenterSitesCount: this.helpCenterService.allSites?.length || 1,
      hardLimitBreached: this.usageLimitsService.finHardLimitBreached,
      conversationContentSettings: this.contentImportService.finConversationContentSettings,
      hasFinEmailWorkflows: this.hasFinEmailWorkflows,
      hasFinChatWorkflows: this.hasFinChatWorkflows,
      liveFinWorkflowsCount: this.liveFinWorkflowsCount,
      liveFinEmailWorkflowsCount: this.liveFinEmailWorkflowsCount,
      liveFinChatWorkflowsCount: this.liveFinChatWorkflowsCount,
      finSetupRuleset: this.finSetupRuleset,
      emailSetupRuleset: this.emailSetupRuleset,
      finIngestionState: this.contentImportService.fetchContentIngestionState(),
      previewConfiguration: this.previewConfiguration,
      latestQuestionExtractionRunId: this.latestQuestionExtractionRunId,
      shouldShowUpdatedLabel: this.shouldShowUpdatedLabel,
    });
  }

  setupController(
    controller: SetupController,
    model: {
      liveProfilesWithAiAnswersEnabled: boolean;
      liveProfilesWithCustomAnswersEnabled: boolean;
      liveFinProfilesCount: number;
      allArticlesCount: number;
      finUsableArticlesCount: number;
      allCustomAnswersCount: number;
      finUsableCustomAnswersCount: number;
      finUsableExternalContentCount: number;
      allSnippetsCount: number;
      finUsableSnippetsCount: number;
      allActionsCount: number;
      finUsableActionsCount: number;
      allFilesCount: number;
      finUsableFilesCount: number;
      helpCenterSitesCount: number;
      hardLimitBreached: boolean;
      conversationContentSettings?: ConversationContentSettings;
      hasFinEmailWorkflows: boolean;
      hasFinChatWorkflows: boolean;
      liveFinWorkflowsCount: number;
      liveFinEmailWorkflowsCount: number;
      liveFinChatWorkflowsCount: number;
      finSetupRuleset: $TSFixMe;
      emailSetupRuleset: $TSFixMe;
      previewConfiguration: $TSFixMe;
      latestQuestionExtractionRunId: number;
      shouldShowUpdatedLabel: boolean;
    },
  ) {
    controller.setProperties({
      liveProfilesWithAiAnswersEnabled: model.liveProfilesWithAiAnswersEnabled,
      liveProfilesWithCustomAnswersEnabled: model.liveProfilesWithCustomAnswersEnabled,
      liveFinProfilesCount: model.liveFinProfilesCount,
      allArticlesCount: model.allArticlesCount,
      finUsableArticlesCount: model.finUsableArticlesCount,
      allCustomAnswersCount: model.allCustomAnswersCount,
      finUsableCustomAnswersCount: model.finUsableCustomAnswersCount,
      finUsableExternalContentCount: model.finUsableExternalContentCount,
      allSnippetsCount: model.allSnippetsCount,
      finUsableSnippetsCount: model.finUsableSnippetsCount,
      allActionsCount: model.allActionsCount,
      finUsableActionsCount: model.finUsableActionsCount,
      allFilesCount: model.allFilesCount,
      finUsableFilesCount: model.finUsableFilesCount,
      helpCenterSitesCount: model.helpCenterSitesCount,
      hardLimitBreached: model.hardLimitBreached,
      conversationContentSettings: model.conversationContentSettings,
      hasFinEmailWorkflows: model.hasFinEmailWorkflows,
      hasFinChatWorkflows: model.hasFinChatWorkflows,
      liveFinWorkflowsCount: model.liveFinWorkflowsCount,
      liveFinEmailWorkflowsCount: model.liveFinEmailWorkflowsCount,
      liveFinChatWorkflowsCount: model.liveFinChatWorkflowsCount,
      finSetupRuleset: model.finSetupRuleset,
      emailSetupRuleset: model.emailSetupRuleset,
      previewConfiguration: model.previewConfiguration,
      latestQuestionExtractionRunId: model.latestQuestionExtractionRunId,
      shouldShowUpdatedLabel: model.shouldShowUpdatedLabel,
      hasSeenFinLandingPageWithPreview: this.hasSeenFinLandingPageWithPreview,
    });
  }

  activate() {
    this.guideLibraryService.addWorkflowSetLiveListener();
  }

  resetController(controller: SetupController, isExiting: boolean) {
    if (isExiting) {
      controller.set('previewFin', false);
    }
  }

  async loadOnboardingGuide() {
    await this.onboardingHomeService.findOrLoadGuide(false);
  }

  async loadLiveResolutionBotBehaviors() {
    this.liveResolutionBotBehaviors = await this.outboundHomeService.contentSearch({
      object_types: [objectTypes.resolutionBotBehavior],
      states: [states.live],
      app_id: this.appService.app.id,
    });
  }

  async loadCustomAnswerCounts() {
    let response = await ajax({
      url: '/ember/custom_answers/counts',
      type: 'GET',
      data: { app_id: this.app.id },
    });

    this.customAnswerCounts = {
      all: response.all || 0,
      finUsable: response.live || 0,
    };
  }

  async getFinWorkflowsCount() {
    let response = await get('/ember/operator_workflows/get_live_fin_workflows_count', {
      app_id: this.appService.app.id,
    });

    this.liveFinChatWorkflowsCount = response.live_chat_count;
    this.liveFinEmailWorkflowsCount = response.live_email_count;
    this.liveFinWorkflowsCount = +response.live_chat_count + +response.live_email_count;
    this.hasFinChatWorkflows = response.has_chat_workflows;
    this.hasFinEmailWorkflows = response.has_email_workflows;
  }

  async getFinSetupRuleset() {
    if (this.app.canUseFeature('bot_workflows')) {
      this.finSetupRuleset = null;
      return;
    }

    let finSetupRuleset = await get(FIN_SETUP_BEHAVIOR_ENDPOINT, {
      app_id: this.appService.app.id,
      setup_type: 'chat',
    });

    this.finSetupRuleset = finSetupRuleset;
  }

  async getEmailSetupRuleset() {
    if (this.app.canUseFeature('bot_workflows')) {
      this.emailSetupRuleset = null;
      return;
    }

    let emailSetupRuleset = await get(FIN_SETUP_BEHAVIOR_ENDPOINT, {
      app_id: this.appService.app.id,
      setup_type: 'email',
    });

    this.emailSetupRuleset = emailSetupRuleset;
  }

  async maybeTriggerFirstSetupIngestion() {
    try {
      await post('/ember/fin_initial_setup_ingestion', {
        app_id: this.appService.app.id,
      });
    } catch {
      // Errors in this request shouldn't block the page from loading
    }
  }

  async getPreviewConfiguration(): Promise<$TSFixMe> {
    let settings = await this.store.findRecord('messenger-settings/all', this.appService.app.id);
    this.previewConfiguration = settings.previewConfiguration;
  }

  async loadLastQuestionGroupsRunDetails(): Promise<$TSFixMe> {
    let response = await get(
      '/ember/ai_agent/question_groups/latest_question_extraction_run_details',
      {
        app_id: this.appService.app.id,
      },
    );
    this.latestQuestionExtractionRunId = response.id;
    this.shouldShowUpdatedLabel =
      !!this.latestQuestionExtractionRunId &&
      this.latestQuestionExtractionRunId.toString() !==
        localStorage.getItem(LAST_VIEWED_QUESTION_EXTRACTION_RUN_ID);
  }

  @action
  didTransition() {
    this.intercomEventService.trackEvent('fin-automation-page-visited');
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
