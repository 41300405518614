/* RESPONSIBLE TEAM: team-messenger */
import SdkBaseRoute from 'embercom/routes/apps/app/settings/base/sdk-base-route';

export default SdkBaseRoute.extend({
  analytics: {
    section: 'settings',
    place: 'android',
  },
  titleToken: 'Android',
});
