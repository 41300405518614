/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { getAdminProfile } from 'embercom/lib/admins/admin-profiles';
import Admin from 'embercom/models/admin';

let AdminRoute = Route.extend({
  intl: service(),
  store: service(),
  intercomEventService: service(),

  titleToken(model) {
    return model.get('name');
  },
  model(params) {
    let admin = Admin.peekAndMaybeLoad(this.store, params.admin_id);
    if (!admin.location_data) {
      admin = this.store.findRecord('admin', params.admin_id);
    }

    this.trackProfileAccess(params.admin_id);
    return admin;
  },
  afterModel(model) {
    if (model.get('isOperator')) {
      this.replaceWith('apps.app.automation.settings');
      return;
    }
    let socialAccounts = this.store
      .query('social-account', { admin_id: model.get('id') })
      .then((models) => model.set('socialAccounts', models));

    let profile = getAdminProfile(
      this.store,
      this.modelFor('apps.app').get('id'),
      model.get('id'),
    ).then((profile) => {
      model.set('profile', profile);
    });

    return hash({
      socialAccounts,
      profile,
    });
  },
  trackProfileAccess(adminId) {
    let currentAdminId = this.modelFor('apps.app').get('currentAdmin.id');
    if (typeof Intercom !== 'undefined') {
      this.intercomEventService.trackEvent('activity-profile-visited', {
        admin_accessed_by_id: currentAdminId,
        admin_accessed_id: adminId,
      });
    }
  },
  actions: {
    willTransition(transition) {
      // check if only change made is the avatar, that's saved prior to leaving page
      // if it is, don't prompt user about unsaved changes
      let changed = this.currentModel?.changedAttributes();
      if (changed && Object.keys(changed).isEqual(['avatar'])) {
        return;
      }
      if (this.controller.hasUnsavedChanges()) {
        if (!confirm(this.intl.t('admins.admin.unsaved-changes'))) {
          transition.abort();
        }
      }
    },
  },
});

export default AdminRoute;
