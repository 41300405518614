/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import { A } from '@ember/array';
import { action } from '@ember/object';
import { assign } from '@ember/polyfills';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { confirmSubscriptionChanges as confirmSubscriptionChangesFromInboxSeats } from 'embercom/lib/admins/inbox-seat-info';
import {
  confirmMultipleSeatOveragesIncrease,
  confirmSeatOveragesForSelfServeAnnualInvite,
} from 'embercom/lib/admins/multiple-seats-info';
import { confirmPriceIncrease as confirmPriceIncreaseFromSeats } from 'embercom/lib/admins/seat-info';
import { post } from 'embercom/lib/ajax';
import {
  INVITE_USAGE as INVITE,
  ANNUAL_BILLING_PERIOD,
  MONTHLY_BILLING_PERIOD,
  COPILOT_SEAT_TYPE as COPILOT,
} from 'embercom/lib/settings/seats/constants';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'embercom/services/intl';

export default class PermissionsRoute extends ManageTeammatesRoute {
  @service appService;
  @service store;
  @service router;
  @service intl;
  @service permissionsHierarchyService;
  @service notificationsService;
  @service intercomEventService;
  @service cardlessTrialService;
  @service intercomConfirmService;
  @service guideLibraryService;

  analytics = {
    section: 'settings',
    place: 'teammate-invite-permissions',
  };

  initialUsage;
  updatedUsage;
  coreSeatBillingPeriodSelected = MONTHLY_BILLING_PERIOD;
  fullSeatPriceForBillingPeriod = {};

  async beforeModel() {
    await super.beforeModel(...arguments);
    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();
  }

  _getPermissionsObject() {
    let permissionsObject = this.store.createRecord('permission', {
      app_id: this.appService.app.id,
    });
    if (!this.appService.app.canUseFeature('app_permissions')) {
      permissionsObject.setAllPermissions(true);
    }
    return permissionsObject;
  }

  model() {
    return {
      app: this.appService.app,
      emails: this.modelFor('apps.app.settings.teammates.invite').emails,
      permissionsObject: this._getPermissionsObject(),
      seatTypes: this.appService.app.onPricing5 ? ['core'] : A(),
      inviteLocale: this._getLocaleFromLocalStorage(),
      roles: this.modelFor('apps.app.settings.teammates.invite').roles,
      copilotData: this.modelFor('apps.app.settings.teammates.index')?.copilotData,
      usageType: INVITE,
    };
  }

  afterModel(model) {
    if (model.emails.length === 0) {
      this.transitionTo('apps.app.settings.teammates.invite');
    }
  }

  async sendInvites() {
    try {
      let permissions = this.currentModel.permissionsObject.serialize();
      let invitees = this.currentModel.emails.map((email) =>
        assign(
          {
            email,
            seat_types: this.currentModel.seatTypes.toArray(),
            locale: this.currentModel.inviteLocale,
          },
          permissions,
        ),
      );

      await post('/ember/invite_admin_groups', { invitees, app_id: this.appService.app.id });

      this._setLocaleInLocalStorage(this.currentModel.inviteLocale);

      this.intercomEventService.trackAnalyticsEvent({
        action: 'created',
        object: 'teammate-invite',
        place: 'teammate-list',
        numberOfInvites: invitees.length,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammates.invite.notification.invite-success', {
          inviteCount: invitees.length,
          appName: this.appService.app.name,
        }),
      );
      let transition = this.transitionTo('apps.app.settings.teammates');
      transition.data.selectedTab = 'invites';
    } catch (error) {
      if (error.jqXHR?.responseJSON?.error_code === 'seat_limit_reached') {
        this.notificationsService.notifyErrorWithButton(
          this.intl.t('pricing-and-packaging.multi_workspace_seat_limit_reached.error_message'),
          {
            label: this.intl.t(
              'pricing-and-packaging.multi_workspace_seat_limit_reached.button_label',
            ),
            action: this.redirectToBilling.bind(this),
          },
          10000,
        );
      } else if (
        this.appService.app.cannotAssignWiderAccess &&
        isPresent(error?.jqXHR?.responseJSON?.tokens)
      ) {
        this.notificationsService.notifyError(
          this.intl.t(
            'settings.error_message.cannot_modify_unowned_permissions_invite_creation_error',
          ),
        );
      } else if (error.jqXHR?.responseJSON?.error_code === 'invite_limit_reached') {
        this.notificationsService.notifyError(
          this.intl.t('settings.error_message.cannot_send_invites_limit_error'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.error_message.cannot_send_invites_error'),
        );
      }
      console.error(error);
    }
  }

  redirectToBilling() {
    this.router.transitionTo('apps.app.billing');
  }

  get numberOfInboxSeatsRequired() {
    return this.currentModel.permissionsObject.has_inbox_access
      ? this.currentModel.emails.length
      : 0;
  }

  get numberOfTeammateSeatsRequired() {
    return this.appService.app.canSeeCostChangeCard ? this.currentModel.emails.length : 0;
  }

  get numberOfMultipleSeatTeammateInvitesRequired() {
    return this.appService.app.hasMultipleSeatTypes ? this.currentModel.emails.length : 0;
  }

  @action
  setInitialUsage(usage) {
    this.initialUsage = usage;
  }

  @action
  setUpdatedUsage(usage) {
    this.updatedUsage = usage;
  }

  @action
  setCoreSeatBillingPeriodSelected(billingPeriod, fullSeatPriceForBillingPeriod) {
    this.coreSeatBillingPeriodSelected = billingPeriod;
    this.fullSeatPriceForBillingPeriod = fullSeatPriceForBillingPeriod;
  }

  async confirmChanges() {
    return (
      (await confirmSubscriptionChangesFromInboxSeats(
        this.numberOfInboxSeatsRequired,
        this.showConfirmModal,
        {},
        true,
        this.appService.app.canUseFinAiCopilotAddon &&
          this.currentModel.seatTypes.includes(COPILOT),
        this.currentModel.copilotData,
      )) &&
      (await confirmPriceIncreaseFromSeats(
        this.numberOfTeammateSeatsRequired,
        this.appService.app.canUseFinAiCopilotAddon &&
          this.currentModel.seatTypes.includes(COPILOT),
        this.showConfirmModal,
      )) &&
      (await this.confirmMultipleSeatOveragesIncreaseIfHasSeats())
    );
  }

  async confirmMultipleSeatOveragesIncreaseIfHasSeats() {
    let onNonPricing5FreeTrial =
      this.cardlessTrialService.isInCardlessTrial && !this.appService.app.onPricing5;
    if (this.coreSeatBillingPeriodSelected === ANNUAL_BILLING_PERIOD) {
      await confirmSeatOveragesForSelfServeAnnualInvite(
        this.initialUsage,
        this.updatedUsage,
        this.fullSeatPriceForBillingPeriod,
        this.router,
        this.appService.app.id,
      );
      // prevents invites from being sent
      return false;
    } else if (this.appService.app.hasMultipleSeatTypes && !onNonPricing5FreeTrial) {
      return await confirmMultipleSeatOveragesIncrease(
        this.appService.app,
        {
          seatTypes: this.currentModel.seatTypes,
          initialUsage: this.initialUsage,
          updatedUsage: this.updatedUsage,
          teammateCount: this.currentModel.emails.length,
          isInvite: true,
          haveSeatsChanged: true,
          copilotSeatChanged: this.currentModel.seatTypes.includes(COPILOT),
        },
        this.showConfirmModal,
      );
    }
    return true;
  }

  @action
  async confirmAndSendInvites() {
    try {
      this.currentModel.permissionsObject.validate();
      if (await this.confirmChanges()) {
        await this.sendInvites();
        if (this.guideLibraryService.canUseGuideLibraryService) {
          await this.guideLibraryService.markStepCompleted(
            'guide_library_foundational_steps_invite_teammates',
          );
        }
      }
    } catch (error) {
      this.notificationsService.notifyError(error.message);
    }
  }

  @action
  async showConfirmModal(options) {
    return this.intercomConfirmService.confirm(options);
  }

  _getLocaleFromLocalStorage() {
    let storedLocale = localStorage.getItem('localeForInvite');
    if (storedLocale && SUPPORTED_LOCALES.includes(storedLocale)) {
      return storedLocale;
    }
    return DEFAULT_LOCALE;
  }

  _setLocaleInLocalStorage(newLocale) {
    localStorage.setItem('localeForInvite', newLocale);
  }
}
