/* RESPONSIBLE TEAM: team-customer-data-platform */
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';

export default ProductSettingsRoute.extend({
  intl: service(),

  analytics: {
    section: 'settings',
    place: 'people_segments',
  },
  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.people-segments');
  },
});
