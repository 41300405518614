/* RESPONSIBLE TEAM: team-ai-chatbot */
import FinAIAgentCustomAnswersAnswerShowIndexRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer/show/index';

export default class AutomationFinAIAgentCustomAnswersAnswerShowIndexRoute extends FinAIAgentCustomAnswersAnswerShowIndexRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.custom-answers.answer.show.index',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer.show.index';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer.show.index';
}
