/* RESPONSIBLE TEAM: team-tickets-1 */
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import type Transition from '@ember/routing/-private/transition';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type Session from 'embercom/services/session';
import { hash } from 'rsvp';
import type IntlService from 'embercom/services/intl';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

export default class Tickets extends ProductSettingsRoute {
  @service declare permissionsService: any;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare ticketStateService: TicketStateService;
  @service declare session: Session;

  get app() {
    return this.appService.app;
  }

  get titleToken() {
    return this.intl.t('settings.ticket-data.detail-title');
  }

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);
    this.session.switchWorkspace(this.app.id);
    await this.session.workspace.fetchRequiredAttributes();

    let permission = 'can_access_product_settings';
    this.permissionsService.ensurePermissionWhenTransitioning(permission, transition);
  }

  async model() {
    let canAccessTicketsPortal = await this.permissionsService.currentAdminCan(
      CAN_MANAGE_ARTICLES_PERMISSION,
    );
    await this.ticketStateService.setup();

    let ticketTypes = await this.fetchTicketTypes();

    return hash({ ticketTypes: ticketTypes.toArray(), canAccessTicketsPortal });
  }

  async fetchTicketTypes() {
    return this.store.findAll('inbox/ticket-type');
  }
}
