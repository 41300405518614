/* RESPONSIBLE TEAM: team-workflows */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { get } from 'embercom/lib/ajax';

export default class AutomationRoute extends Route {
  @service store;
  @service appService;
  @service router;
  @service contentImportService;

  get app() {
    return this.appService.app;
  }

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-workflows',
    };
  }

  beforeModel(transition) {
    this.maybeRedirectToFinAIAgent(transition);
  }

  maybeRedirectToFinAIAgent(transition) {
    if (!this.app.canUseFinExperience) {
      return;
    }

    let transitionToName = transition.to.name;

    if (transitionToName === 'apps.app.automation.resolution-bot.setup') {
      this.router.replaceWith('apps.app.fin-ai-agent.setup');
    } else if (transitionToName === 'apps.app.automation.resolution-bot.fin-content') {
      this.router.replaceWith('apps.app.fin-ai-agent.content');
    } else if (transitionToName === 'apps.app.automation.resolution-bot.fin-content-reviews') {
      this.router.replaceWith('apps.app.fin-ai-agent.content-suggestions');
    } else if (transitionToName.includes('apps.app.automation.resolution-bot.behaviors')) {
      if (transitionToName === 'apps.app.automation.resolution-bot.behaviors.edit') {
        this.router.replaceWith(
          'apps.app.fin-ai-agent.profiles.edit',
          transition.to.params.id || transition.to.queryParams.id,
        );
      } else {
        this.router.replaceWith('apps.app.fin-ai-agent.setup');
      }
    } else if (transitionToName.includes('apps.app.automation.resolution-bot.answers')) {
      let answerId = this.paramsFor('apps.app.automation.resolution-bot.answers.answer').answer_id;

      let redirectToName;

      if (this.app.canUseStandalone) {
        redirectToName = transitionToName.replace(
          'automation.resolution-bot.answers',
          'standalone.knowledge.custom-answers',
        );
      } else {
        redirectToName = transitionToName.replace(
          'automation.resolution-bot.answers',
          'fin-ai-agent.custom-answers',
        );
      }

      if (answerId) {
        this.router.replaceWith(redirectToName, answerId);
      } else {
        this.router.replaceWith(redirectToName);
      }
    } else if (transitionToName.includes('apps.app.automation.resolution-bot.clusters')) {
      this.router.replaceWith('apps.app.fin-ai-agent.custom-answers.unanswered-questions');
    } else if (transitionToName.includes('apps.app.automation.settings')) {
      let selectedSection = transition.to.queryParams?.section;

      if (['quick-reply-settings', 'suggestion-settings', 'answer-bot'].includes(selectedSection)) {
        this.router.replaceWith('apps.app.fin-ai-agent.settings');
      }
    }
  }

  async model() {
    this.contentImportService.fetchContentIngestionState();
    this.contentImportService.fetchAiContentLibrarySummary();
    this.contentImportService.fetchContentReviewSummary();
    await this.contentImportService.fetchFinConversationContentSettings();

    let counts = {};

    // We don't want to fail the page load in case this ever goes wrong
    try {
      let { custom_bots: customBots, answers } = await get(
        `/ember/operator_workflows/workflow_counts`,
        {
          app_id: this.app.id,
        },
      );
      counts = {
        customBots,
        answers,
      };
    } catch (e) {
      console.error(e);
    }

    let [lookAndFeel, availability, upfrontEmailCollection] = await Promise.all([
      this.store.findRecord('messenger-settings/look-and-feel', this.app.id),
      this.store.findRecord('messenger-settings/availability', this.app.id),
      this.store.findRecord('messenger-settings/upfront-email-collection', this.app.id),
    ]);

    return hash({
      lookAndFeel,
      availability,
      upfrontEmailCollection,
      counts,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.initializeConfigurationModel();
  }
}
