/* RESPONSIBLE TEAM: team-tickets-1 */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type IntlService from 'embercom/services/intl';
import getTransitionParams from 'embercom/lib/router/get-transition-params';
import type Session from 'embercom/services/session';

type ModelParams = {
  ticket_type_id: string;
};

export default class extends BaseSettingsRoute {
  @service declare permissionsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare ticketStateService: TicketStateService;
  @service declare session: Session;

  get titleToken() {
    return this.intl.t('settings.ticket-data.detail-title');
  }

  get app() {
    return this.appService.app;
  }

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);

    let permission = 'can_access_product_settings';
    this.permissionsService.ensurePermissionWhenTransitioning(permission, transition);
    // Make sure session is setup before fetching workspace dependant data
    let routeParams = getTransitionParams(transition) as {
      'apps.app': { app_id: string };
    };
    let workspaceId = routeParams['apps.app'].app_id;

    this.session.switchWorkspace(workspaceId);

    await this.session.workspace.fetchRequiredAttributes();
  }

  async model(params: ModelParams) {
    await this.ticketStateService.setup();

    return hash({
      ticketType: this.store.findRecord('inbox/ticket-type', params.ticket_type_id),
      securitySettings: this.securityAppSettings,
      convertInboundEmailsToTicketsSettings: this.store.findRecord(
        'email-convert-inbound-emails-to-tickets-settings',
        this.app.id,
      ),
    });
  }

  get securityAppSettings() {
    return this.store.findRecord('security-app-settings', this.app.id).catch(function () {
      return {};
    });
  }
}
