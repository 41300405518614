/* RESPONSIBLE TEAM: team-channels */
import ProductMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/product-messages-settings-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { get } from 'embercom/lib/ajax';

export default class EmailOtherSettings extends ProductMessagesSettingsRoute {
  @service intl;
  @service store;
  analytics = {
    section: 'settings',
    place: 'email_settings_other',
    object: 'email_settings_other',
  };
  get titleToken() {
    return this.intl.t('channels.email.settings.other_settings.title');
  }

  get app() {
    return this.modelFor('apps.app');
  }

  async model() {
    return hash({
      conversationHistorySettings: this.store.findRecord(
        'email-conversation-history-settings',
        this.app.id,
      ),
      convertInboundEmailsToTicketsSettings: this.store.findRecord(
        'email-convert-inbound-emails-to-tickets-settings',
        this.app.id,
      ),
      hasLiveConvertToTicketWorkflows: await get(
        '/ember/operator_workflows/has_live_convert_to_ticket_workflows',
        {
          app_id: this.app.id,
        },
      ),
      stripInboundEmailLinksSettings: this.store.findRecord(
        'strip-inbound-email-links-settings',
        this.app.id,
      ),
      spamSettings: this.store.findRecord('email-spam-settings', this.app.id),
      continuedConversationsSettings: this.store.findRecord(
        'email-continued-conversations-settings',
        this.app.id,
      ),
    });
  }
}
