/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { hash } from 'rsvp';
import { captureException } from 'embercom/lib/sentry';

export default class RemoveRoute extends ManageTeammatesRoute {
  @service permissionsMutatorService;
  @service appService;
  @service customerService;
  @service intl;
  @service notificationsService;

  analytics = {
    section: 'settings',
    place: 'teammate-remove',
  };

  async beforeModel() {
    await super.beforeModel(...arguments);
    let teammateId = this.paramsFor('apps.app.settings.teammates.teammate').id;
    if (teammateId === this.appService.app.currentAdmin.id) {
      this.replaceWith('apps.app.settings.teammates.teammate.permissions', teammateId);
    }
    await this.customerService.ensureDataIsLoaded.perform();
  }

  model() {
    return hash({
      app: this.appService.app,
      teammate: this.modelFor('apps.app.settings.teammates.teammate'),
      reassignments: { teams: {} },
      annuallyContractedWithOveragesEnabled: this.annuallyContractedWithOveragesEnabled,
    });
  }

  @action
  async removeTeammate() {
    let { teammate, reassignments } = this.currentModel;
    let permission = teammate.currentAppPermissions;

    try {
      await permission.destroyRecord({ adapterOptions: { reassignments } });
      this.appService.app.admins.removeObject(teammate);
      permission.unloadRecord();
      teammate.unloadRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammates.remove-teammate.notification.successful-removal', {
          teammateName: teammate.displayAs,
          appName: this.currentModel.app.name,
        }),
      );
      this.transitionTo('apps.app.settings.teammates');
    } catch (error) {
      if (error.jqXHR.status === 403) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.teammates.remove-teammate.notification.unexpected-failure'),
        );
        captureException(error);
      }
    }
  }

  get annuallyContractedWithOveragesEnabled() {
    return this.appService.app.canUseSeatOverages && this.appService.app.isSalesforceContracted;
  }
}
