/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import RSVP from 'rsvp';

export default class SlaRoute extends ProductSettingsRoute {
  @service store;
  @service appService;
  @service router;
  @service notificationsService;
  @service intl;

  titleToken = 'SLAs';

  analytics = {
    section: 'settings',
    place: 'slas',
  };

  model() {
    return RSVP.hash({
      officeHoursSchedules: this.store.findAll('office-hours-schedule'),
      slas: this.store.findAll('inbox/conversation-sla'),
    });
  }

  beforeModel() {
    super.beforeModel(...arguments);
    let app = this.modelFor('apps.app');
    if (!app.get('canUseInboxSlas')) {
      this.notificationsService.notifyError(this.intl.t('new-settings.helpdesk.sla.no-access'));
      this.transitionTo('apps.app.settings.index');
    }
  }
}
