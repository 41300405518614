/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import { hash } from 'rsvp';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';

export default ProductSettingsRoute.extend({
  store: service(),
  intl: service(),
  analytics: {
    section: 'settings',
    place: 'imports',
  },
  titleToken: computed('intl.locale', function () {
    return this.intl.t('settings.imports.import-history');
  }),
  model() {
    let controller = this.controllerFor('apps/app/settings/imports');
    return hash({
      csvImports: this.store.query('csv-import', { page: 1 }).then((csvImports) => {
        controller.set('hasMoreCsvImports', csvImports.meta.pages_left);
        return this.store.peekAll('csv-import');
      }),
      mailchimpImports: this.store
        .query('mailchimp-import', { page: 1 })
        .then((mailchimpImports) => {
          controller.set('hasMoreMailchimpImports', mailchimpImports.meta.pages_left);
          return this.store.peekAll('mailchimp-import');
        }),
      mixpanelImports: this.store.query('mixpanel-import', { page: 1 }).then((mixpanelImports) => {
        controller.set('hasMoreMixpanelImports', mixpanelImports.meta.pages_left);
        return this.store.peekAll('mixpanel-import');
      }),
    });
  },
});
