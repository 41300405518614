/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';

export default class AttachmentSettings extends WorkspaceSettingsRoute {
  @service appService;
  @service store;

  analytics = {
    section: 'settings',
    place: 'attachment_settings',
  };
  titleToken = 'Attachment Settings';

  model() {
    return this.store.findRecord('attachment-settings', this.appService.app.id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    if (this.appService.app.canUseFeature('use-trusted-domains-for-url-verification-settings')) {
      controller.loadURLVerificationSettings();
    }
  }
}
