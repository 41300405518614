/* RESPONSIBLE TEAM: team-customer-data-platform */
import { inject as service } from '@ember/service';
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';

export default SettingsRoute.extend({
  modelDataCacheService: service(),
  intl: service(),
  permissionsService: service(),

  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.tags');
  },

  analytics: {
    place: 'tag_settings',
    location: 'tags',
    owner: 'inbox',
  },

  beforeModel(transition) {
    this._super(...arguments);
    this.permissionsService.ensurePermissionWhenTransitioning('can_manage_tags', transition);
  },

  async model() {
    let app = this.modelFor('apps/app');

    return await app.loadAndUpdateTags();
  },
});
