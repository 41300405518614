/* RESPONSIBLE TEAM: team-data-interop */
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import IdentityVerificationBasePlatform from 'embercom/routes/apps/app/settings/identity-verification/identity-verification-base-platform';

export default IdentityVerificationBasePlatform.extend({
  store: service(),
  analytics: {
    section: 'settings',
    place: 'identity_verification_android',
  },
  platform: 'Android',

  model() {
    return hash({
      sdk_apps: this.store.findAll('sdk-app'),
      userSources: this.userSources,
    });
  },

  afterModel(model) {
    this.set('app.sdkApps', model.sdk_apps);
  },
});
