/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { captureException } from 'embercom/lib/sentry';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import storage from 'embercom/vendor/intercom/storage';
import confetti from 'embercom/lib/signup/confetti';

export default IntercomRoute.extend({
  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-product-guidance',
    };
  },
  titleToken: 'Home',
  survey: null,
  onboardingHomeService: service(),
  onboardingHomeExternalStepService: service(),
  customerService: service(),
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  notificationsService: service(),
  intl: service(),

  analytics: {
    place: 'home',
  },

  activate() {
    storage.set('lastProductUsed', 'home');
    storage.set(`onboarding_home_banner_${this.appService.app.id}_dismissed`, false);
  },

  async beforeModel() {
    if (this.app.canUseGreatGuidanceMilestoneOne) {
      this.transitionTo('apps.app.getting-started', this.app.id);
    } else {
      this.transitionTo('apps.app.guide-library', this.app.id);
    }
    this.intercomEventService.trackEvent('nco_visited_home');
  },

  async model() {
    if (this.onboardingHomeService.guideForCurrentAppFromStore()) {
      await this.onboardingHomeService.findOrLoadGuide();
    } else {
      try {
        await Promise.all([
          this.customerService.refreshCustomer(),
          this.onboardingHomeService.findOrLoadGuide(),
        ]);
      } catch (e) {
        console.error('Home model hook error: ', e);
        captureException(e, {
          fingerprint: ['route', 'home', 'model', e.message],
        });
        throw e;
      }
    }
    return this.get('onboardingHomeService.guide');
  },

  afterModel() {
    let shouldShowConfetti = storage.get('shouldShowConfetti');
    if (shouldShowConfetti) {
      this.notificationsService.notifyConfirmation(this.intl.t('teams-checkout.confirm.success'));
      confetti.showSuccesAnimation();
    }
    storage.remove('shouldShowConfetti');
  },
});
