/* RESPONSIBLE TEAM: team-ai-chatbot */
import Route from '@ember/routing/route';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { PAGE_SIZE } from 'embercom/models/data/outbound/constants';
import { getOwner } from '@ember/application';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { REVIEWABLE_CONTENT_ENTITIES } from 'embercom/lib/ai-content-library/constants';
import { objectNamesToKeys } from 'embercom/models/data/matching-system/matching-constants';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import type Transition from '@ember/routing/transition';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';

type ModelParams = {
  searchTerm: string;
};
export default class FinAIAgentContentSuggestionsRoute extends Route {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare store: Store;

  async model(params: ModelParams): Promise<AiContentLibraryApi> {
    let api = new AiContentLibraryApi(getOwner(this), {
      object_types: [...REVIEWABLE_CONTENT_ENTITIES],
      content_wrapper_types: [ContentWrapperType.AI_CONTENT],
      app_id: this.appService.app.id as string,
      per_page: PAGE_SIZE,
      content_review_statuses: [Status.Pending],
      title: params.searchTerm,
    });

    await Promise.all([api.loadPage()]);

    return api;
  }

  setupController(
    controller: $TSFixMe,
    model: ModelFromRoute<FinAIAgentContentSuggestionsRoute>,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    let queryParams = transition.to.queryParams;
    let contentType = queryParams.content;
    if (!contentType) {
      return;
    }
    if (!REVIEWABLE_CONTENT_ENTITIES.includes(Number(objectNamesToKeys[contentType]))) {
      controller.setProperties({
        selectedContentType: undefined,
        selectedContentId: undefined,
      });
      this.redirectToFinContent(transition);
    }
  }

  private redirectToFinContent(transition: Transition): void {
    this.router.transitionTo({
      queryParams: {
        ...transition.to.queryParams,
        selectedContentType: undefined,
        selectedContentId: undefined,
        content: undefined,
        id: undefined,
      },
    });
  }

  analytics = {
    section: 'ai-chatbot',
    place: 'content-suggestions',
  };

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.content-suggestions.title');
  }
}
