/* RESPONSIBLE TEAM: team-customer-data-platform */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CustomObjectData extends BaseSettingsRoute {
  @service intercomEventService;
  @service appService;
  @service intl;
  @service permissionsService;
  @service store;

  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.custom-object-data');
  }

  async model() {
    return await this.store.findAll('custom-objects/object-type');
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('has_visited_custom_objects');
  }
}
