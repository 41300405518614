/* RESPONSIBLE TEAM: team-ai-chatbot */
/* eslint-disable @intercom/intercom/no-bare-strings */

import type IntlService from 'embercom/services/intl';
import { hash } from 'rsvp';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import ajax from 'embercom/lib/ajax';
import {
  IMPORT_BANNER_PAGEVIEW_KEY,
  ANSWERS_INDEX_PAGEVIEW_KEY,
} from 'embercom/lib/operator/resolution-bot/constants';
import { inject as service } from '@ember/service';
import { adminHasSeenFromPageviews, fetchAdminPageviews } from 'embercom/lib/admin-view-tracking';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { EMPTY_SUGGESTED_ANSWER_CLUSTER_IDS } from 'embercom/lib/operator/resolution-bot/constants';
import Range from 'embercom/models/reporting/range';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import type Store from '@ember-data/store';

const DEFAULT_CLUSTERS_INTERVAL = 90;

export default class FinAIAgentCustomAnswersIndexRoute extends IntercomRoute {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @service declare frontendStatsService: $TSFixMe;
  @service declare outboundHomeService: $TSFixMe;
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  loadingAnimationIsActive = false;
  hasSeenImportBanner = true;

  queryParams = {
    rangeStart: {
      refreshModel: true,
    },
    rangeEnd: {
      refreshModel: true,
    },
  };

  range = null;
  rangeStart = null;
  rangeEnd = null;

  get defaultRange() {
    return Range.createFromParams(this.app.created_at, null, this.app.timezone);
  }

  async beforeModel(transition: Transition) {
    let adminPageviews = await fetchAdminPageviews(this.store);

    let hasSeenImportBanner = adminHasSeenFromPageviews(adminPageviews, IMPORT_BANNER_PAGEVIEW_KEY);
    let hasSeenAnswerAnimation = adminHasSeenFromPageviews(
      adminPageviews,
      ANSWERS_INDEX_PAGEVIEW_KEY,
    );
    // if transition is not from answers.index or answers.show.index, then we use the default range
    if (
      this.routeNotFromAnswersIndex(transition) &&
      // since it will transition to answers.loading first before get to the answers.index,
      // we prevent endless loop by the following conditions
      (transition.to.queryParams?.rangeStart !== this.defaultRange.start ||
        transition.to.queryParams?.rangeEnd !== this.defaultRange.end)
    ) {
      transition.abort();
      return this.replaceWith(this.routeName, {
        queryParams: {
          ...transition.to.queryParams,
          rangeStart: this.defaultRange.start,
          rangeEnd: this.defaultRange.end,
        },
      });
    }

    this.setProperties({
      rangeStart: transition.to.queryParams.rangeStart || this.defaultRange.start,
      rangeEnd: transition.to.queryParams.rangeEnd || this.defaultRange.end,
    });

    this.set(
      'range',
      Range.createFromParamsWithAddedRanges(
        this.rangeStart,
        this.rangeEnd,
        this.app.timezone,
        this._createRangeOption(
          'custom-answers.date-range.since-went-live',
          this.defaultRange.start,
          this.app.timezone,
        ),
      ),
    );

    if (!hasSeenAnswerAnimation || !hasSeenImportBanner) {
      return this.setProperties({
        loadingAnimationIsActive: !hasSeenAnswerAnimation,
        hasSeenImportBanner,
      });
    }
    return;
  }

  async model() {
    this.frontendStatsService.startInteractionTime('AnswerBot Index page model hook');
    let include = this.app.canUseFeature('custom-answers-over-email')
      ? 'stats,target_channels'
      : 'stats';
    let queryParams = {
      include,
      start_date: this.rangeStart,
      end_date: this.rangeEnd,
    };
    let answers = await this.store.query('custom-answers/custom-answer', queryParams);

    let model = hash({
      answers,
      clusterData: this._fetchClusterData(DEFAULT_CLUSTERS_INTERVAL).catch(
        (_e) => EMPTY_SUGGESTED_ANSWER_CLUSTER_IDS,
      ),
      conversationImports: this.store.findAll('conversation-import'),
      aiAgentSettings: this.store.findRecord('ai-agent/settings', this.app.id),
      liveResolutionBotBehaviors: this.outboundHomeService.contentSearch({
        object_types: [objectTypes.resolutionBotBehavior],
        states: [states.live],
        match_behaviors: [],
        app_id: this.app.id,
        per_page: 1,
      }),
    });
    this.frontendStatsService.stopInteractionTime('AnswerBot Index page model hook');
    return model;
  }

  afterModel(model: $TSFixMe, transition: Transition) {
    if (
      !transition.to.queryParams.status &&
      model.answers.any((answer: $TSFixMe) => answer.get('isLive')) &&
      transition.to.queryParams?.rangeStart === this.defaultRange.start &&
      transition.to.queryParams?.rangeEnd === this.defaultRange.end
    ) {
      // There's a bug where queryParams are getting stripped when using replaceWith / transitionTo in afterModel
      // workaround: https://github.com/emberjs/ember.js/issues/12169#issuecomment-380543689
      transition.abort();
      this.replaceWith(this.routeName, {
        queryParams: { status: 'live', ...transition.to.queryParams },
      });
    }
  }

  setupController(controller: $TSFixMe, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);

    controller.setProperties({
      loadingAnimationIsActive: this.loadingAnimationIsActive,
      hasSeenImportBanner: this.hasSeenImportBanner,
      range: this.range,
    });
  }

  @action
  refreshRoute() {
    this.refresh();
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'all_answers',
      clusters_available:
        (this.get('context.clusterData.cluster_ids.length' as $TSFixMe) as number) > 0,
    });

    // zendesk import email click analytics
    if (
      this.controller.get('importType' as $TSFixMe) === 'zendesk_chat' &&
      this.controller.get('importStatus' as $TSFixMe) === 'success'
    ) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'main_page',
        context: 'from_zendesk_chat_import_email',
      });
      // reset query params so that they don't persist when navigating back to the page
      this.controller.set('importType' as $TSFixMe, null);
      this.controller.set('importStatus' as $TSFixMe, null);
    }
  }

  async _fetchClusterData(interval: $TSFixMe) {
    let clusterData = await ajax({
      url: '/ember/custom_answers/suggested_answer_cluster_ids',
      type: 'GET',
      data: {
        interval,
        app_id: this.app.id,
        admin_id: this.app.currentAdmin.id,
      },
    });
    return { ...clusterData, interval };
  }

  _createRangeOption(label: $TSFixMe, startOfRange: $TSFixMe, timezone: $TSFixMe) {
    let today = moment.tz(timezone).endOf('day');
    let sinceStartofRange = today.diff(moment.tz(startOfRange, timezone), 'days') + 1;
    return { [label]: [sinceStartofRange, 1] };
  }

  routeNotFromAnswersIndex(transition: Transition) {
    let indexRoutes = [
      'apps.app.automation.resolution-bot.answers.index',
      'apps.app.automation.resolution-bot.answers.answer.show.index',
      'apps.app.fin-ai-agent.custom-answers.index',
      'apps.app.fin-ai-agent.custom-answers.show.index',
    ];
    return !indexRoutes.includes(transition.from?.name as string);
  }
  analytics = {
    section: 'ai-chatbot',
    place: 'custom-answers-index',
  };

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.custom-answers.title');
  }
}
