/* RESPONSIBLE TEAM: team-data-interop */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export default class WorkFlowConnectorActionsIndex extends SettingsRoute {
  @service declare store: Store;

  async model() {
    let actions = await this.store.findAll('workflow-connector/action', { reload: true });

    if (!actions) {
      return {
        actions: [],
        templateActions: [],
      };
    }

    let workflowActions = actions.filterBy('isTemplate', false);
    let templateActions = actions.filterBy('isTemplate', true);

    return {
      actions: workflowActions,
      templateActions,
    };
  }
}
