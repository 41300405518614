/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';

export default SettingsRoute.extend({
  permissionsService: service(),

  analytics: {
    section: 'settings',
    place: 'webhooks',
  },
  titleToken: 'Webhooks',

  beforeModel(transition) {
    this._super(...arguments);
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_developer_hub',
      transition,
    );
  },
});
