/* RESPONSIBLE TEAM: team-customer-data-platform */
import { inject as service } from '@ember/service';
import AttributeSettingsBase from 'embercom/routes/apps/app/settings/base/attribute-settings-base';

let PeopleDataSettingsRoute = AttributeSettingsBase.extend({
  intl: service(),

  analytics: {
    section: 'settings',
    place: 'people_data',
  },

  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.people-data');
  },
});

export default PeopleDataSettingsRoute;
