/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import { resolve } from 'rsvp';
import { inject as service } from '@ember/service';
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';

export default ManageMessagesSettingsRoute.extend({
  store: service(),
  navbarCollapsingService: service(),
  notificationsService: service(),
  intercomConfirmService: service(),
  model(params) {
    return this.store.findRecord('email-template', params.id).then(resolve, () => {
      this.notificationsService.notifyError("We couldn't find that email template");
      this.transitionTo('apps.app.outbound.settings.email-templates');
    });
  },
  async showDiscardChangeWarning(transition) {
    let confirmOptions = {
      title: 'Discard your template changes?',
      body: 'Are you sure you want to discard the changes you made to this template?',
      confirmButtonText: 'Discard changes',
    };
    let confirm = await this.intercomConfirmService.confirm(confirmOptions);
    if (confirm) {
      this.currentModel.rollbackAttributes();
      transition.retry();
    }
  },
  actions: {
    willTransition(transition) {
      if (this.currentModel.isDirtyIgnoringWhitespace) {
        this.showDiscardChangeWarning(transition);
        transition.abort();
      }
    },
  },
  activate() {
    if (!this.navbarCollapsingService.collapsed) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  },
});
