/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import storage from 'embercom/vendor/intercom/storage';

export default IntercomRoute.extend({
  store: service(),
  appService: service(),
  router: service(),
  app: readOnly('appService.app'),
  permissionsService: service(),
  analytics: {
    section: 'platform',
    place: 'accounts',
  },
  beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning('can_access_contacts', transition);
    if (this.appService.app.canSeeUpdatedProductIA) {
      this.router.transitionTo('apps.app.users.segments.segment', 'all');
    }
  },
  afterModel(_, transition) {
    transition.trigger(false, 'setDocumentTitle', `Accounts`);
  },
  async model() {
    await this.store.query('account', {
      skip_cache: true,
    });
    return this.store.peekAll('account');
  },
  activate() {
    storage.set('lastProductUsed', 'accounts');
  },
});
