/* RESPONSIBLE TEAM: team-data-interop */
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import IdentityVerificationBasePlatform from 'embercom/routes/apps/app/settings/identity-verification/identity-verification-base-platform';

export default IdentityVerificationBasePlatform.extend({
  store: service(),
  analytics: {
    section: 'settings',
    place: 'identity_verification_web',
  },
  platform: 'web',

  model() {
    return hash({
      identityVerificationSettings: this.store.queryRecord('identity-verification-settings', {}),
      pingDomains: this.store.query('ping-domain', { ignore_anonymous: true }),
      reminder: this.store.queryRecord('onboarding/home/identity_verification_reminder', {}),
      userSources: this.userSources,
      api_secret: this.get('app.fetchAPISecret').perform(),
    });
  },
});
