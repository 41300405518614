/* RESPONSIBLE TEAM: team-ai-chatbot */
import FinAIAgentCustomAnswersAnswerShowConversations from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer/show/conversations';

export default class AutomationFinAIAgentCustomAnswersAnswerShowConversations extends FinAIAgentCustomAnswersAnswerShowConversations {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.custom-answers.answer.show.conversations',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer.show';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer.show.conversations';
}
