/* RESPONSIBLE TEAM: team-ai-chatbot */

import FinAIAgentCustomAnswersAnswerEditRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer/edit';

export default class ResolutionBotAnswersAnswerEditRoute extends FinAIAgentCustomAnswersAnswerEditRoute {
  analytics = {
    section: 'resolution-bot',
    place: 'answer-edit',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer.edit';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer.edit';
}
