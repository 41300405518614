/* RESPONSIBLE TEAM: team-app-security */
import AppAdminEventsQuery from 'embercom/lib/admins/app-admin-events-query';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { inject as service } from '@ember/service';

export default ManageTeammatesRoute.extend({
  intl: service(),
  get titleToken() {
    return this.intl.t('apps.app.settings.logs.title-token');
  },
  analytics: {
    section: 'settings',
    place: 'logs',
  },
  model() {
    let app = this.modelFor('apps/app');
    return AppAdminEventsQuery.create({ appId: app.get('id'), content: [] });
  },
  setupController(controller, query) {
    this._super(...arguments);
    controller?.afterSetup();
  },
});
