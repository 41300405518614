/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CustomReportsReportRoute extends Route {
  @service store;
  @service notificationsService;
  @service appService;
  @service reportingMetrics;
  @service reportingTemplates;
  @service reportingUnderlyingDataService;
  @service ticketStateService;
  @service session;

  analytics = {
    section: 'custom_reports',
  };

  async beforeModel() {
    // ensure that the descriptors for the conversation attributes are all loaded before
    // rendering anything within this route.
    // this simplifies the cases where we have dependencies on a single descriptor, e.g.
    // when rendering the "break down by" dropdown when a chart is grouped by a conversation attribute
    // we don't have to deal with the case that we haven't yet loaded the conversation attribute models.
    let promises = [
      this.store.findAll('conversation-attributes/descriptor', {
        backgroundReload: false,
      }),
      this.reportingMetrics.loadMetricsAndProperties(),
      this.store.findAll('inbox/ticket-type', { reload: true }),
    ];

    if (this.appService.app.canSeeChatbotCsatReporting) {
      promises.push(
        this.reportingUnderlyingDataService.loadAttributeMappings.perform(
          'conversation.workflow_conversation_rating.workflow_id',
          'conversation',
          true,
        ),
      );
    }

    if (this.appService.app.canUseSlaFiltersAndAttributes) {
      promises.push(
        this.reportingUnderlyingDataService.loadAttributeMappings.perform(
          'conversation_sla_status_log.conversation_sla_id',
          'conversation_sla_status_log',
          true,
        ),
      );
    }

    if (this.appService.app.canSeeR2Beta) {
      promises.push(this.reportingTemplates.loadTemplates());
    }

    this.session.switchWorkspace(this.appService.app.id);
    promises.push(this.ticketStateService.setup());

    return Promise.all(promises);
  }

  @action
  error(error) {
    if (error?.jqXHR?.status === 404) {
      this.notificationsService.notifyError('Sorry that report or chart cannot be found');
      this.replaceWith('apps.app.reports.views.view', 'all-reports');
    } else {
      // Let the route above this handle the error.
      return true;
    }
  }

  @action
  onReportSaved() {
    this.send('updatePageTitle');
  }
}
