/* RESPONSIBLE TEAM: team-reporting */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

export default class NewOverviewRoute extends IntercomRoute {
  analytics = { place: 'new-overview' };

  model() {
    return {
      conversationAttributeDescriptors: ConversationAttributeDescriptor.peekAllAndMaybeLoad(),
    };
  }
}
