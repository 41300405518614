/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';

export default class BillingRoute extends Route {
  @service declare store: Store;
  @service declare paywallService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare stripeLoaderService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('new-settings.workspace.billing.title');
  }

  analytics = {
    section: 'billing_settings',
  };

  beforeModel(transition: Transition) {
    if (!transition.to.queryParams.from_billing_admin) {
      this.permissionsService.ensurePermissionWhenTransitioning(
        'can_access_billing_settings',
        transition,
      );
    }
  }

  model() {
    let app = this.modelFor('apps/app') as $TSFixMe;
    return this.store.findRecord('billing/customer', app.get('customer_id'));
  }

  afterModel(model: $TSFixMe, transition: Transition) {
    let hasPermission = this.permissionsService.currentAdminCan('can_access_billing_settings');
    if (!hasPermission) {
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_access_billing_settings',
      );
    }

    let customer = model;
    let app = this.appService.app;

    if (app.isDeveloperWorkspace || app.isTestApp) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.workspace.billing.dev-workspace-error'),
      );
      return this.transitionTo('apps.app');
    }

    if (
      customer.get('hasNoActiveSubscription') &&
      !customer.get('hasEverHadSubscription') &&
      !(this.appService.app.hasValueBasedPricing || this.appService.app.onPricing5) &&
      !this._openingModal(transition)
    ) {
      return this._noSubscriptionRedirect(customer);
    }
    return taskFor(this.loadData).perform(customer);
  }

  setupController(_controller: $TSFixMe, _model: $TSFixMe, transition: Transition) {
    super.setupController(_controller, _model, transition);

    if (transition.to.queryParams.feature) {
      this.paywallService.paywall({ featureName: transition.to.queryParams.feature });
    }
  }

  @task *loadData(customer: $TSFixMe) {
    yield Promise.all([
      this.stripeLoaderService.load(),
      this.customerService.loadData({
        customer,
        fetchPrices: false,
      }),
      this.customerService.getStripeMigration(),
    ]);
  }

  _noSubscriptionRedirect(customer: $TSFixMe) {
    let regularPlan = customer.get('canModifyOrRemoveProducts');
    let app = this.appService.app;

    if (app.validEarlyStageApplicant) {
      return this._redirectForEarlyStageApplicant();
    } else if (regularPlan) {
      this.paywallService.redirectToPurchase();
    }
  }

  _redirectForEarlyStageApplicant() {
    this.transitionTo(
      this.paywallService.earlyStageApplicationRoute,
      this.paywallService.earlyStageApplicationRouteParameters,
    );
  }

  _openingModal(transition: Transition) {
    return (
      transition &&
      (transition.to.queryParams.addressModalIsOpen ||
        transition.to.queryParams.creditCardModalIsOpen)
    );
  }
}
