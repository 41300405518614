/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { computed } from '@ember/object';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import { inject as service } from '@ember/service';

export default WorkspaceSettingsRoute.extend({
  store: service(),

  titleToken: computed('platform', function () {
    return `Identity verification of ${this.platform} users`;
  }),

  app: computed(function () {
    return this.modelFor('apps.app');
  }),

  userSources: computed(function () {
    return this.store.findAll('user-source');
  }),
});
