/* RESPONSIBLE TEAM: team-channels */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SwitchRoute extends SettingsRoute {
  @service permissionsService;
  @service intercomEventService;
  @service intl;

  get titleToken() {
    return this.intl.t('apps.app.settings.switch.title');
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('switch_settings_page_visits');
  }

  beforeModel(transition) {
    super.beforeModel(...arguments);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_product_settings',
      transition,
    );
  }
}
