/* RESPONSIBLE TEAM: team-customer-data-platform */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class extends BaseSettingsRoute {
  @service intercomEventService;
  @service appService;
  @service customObjectsService;

  model(params) {
    let customObjects = this.modelFor('apps.app.settings.custom-object-data');
    let customObject = customObjects.findBy('identifier', params.object_type_identifier);
    return hash({ customObjects, customObject });
  }
}
