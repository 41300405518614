/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import RolesSettingsRoute from 'embercom/routes/apps/app/settings/base/roles-settings-route';
import { inject as service } from '@ember/service';

export default class extends RolesSettingsRoute {
  @service store;
  titleToken = 'Roles';

  beforeModel(transition) {
    super.beforeModel(...arguments);
    if (!transition.isAborted) {
      return this.store.findAll('permission');
    }
  }

  model() {
    return this.store.findAll('role');
  }
}
