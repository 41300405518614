/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class SavedReplies extends BaseSettingsRoute {
  @service intercomEventService;
  @service intl;
  @service store;

  @action
  willTransition(transition) {
    if (
      this.controller.hasUnsavedChanges &&
      !confirm(this.intl.t('inbox.manage-macros.saved-replies.warning-text'))
    ) {
      transition.abort();
    } else {
      this.controller.cancelEditingReply();
    }
  }

  model() {
    let app = this.modelFor('apps.app');

    return RSVP.hash({
      app,
      savedReplyTypes: app.savedReplyTypes,
      savedReplies: app.savedReplies,
      conversationCustomAttributes: this.store.findAll('conversation-attributes/descriptor'),
    });
  }
}
