/* RESPONSIBLE TEAM: team-proactive-support */
import { isPresent } from '@ember/utils';
import ENV from 'embercom/config/environment';
import { inject as service } from '@ember/service';
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';

export default class SendersRoute extends SettingsRoute {
  @service appService;
  @service intl;
  @service notificationsService;

  analytics = {
    section: 'settings',
    place: 'senders',
  };

  get app() {
    return this.appService.app;
  }

  get titleToken() {
    return this.intl.t('settings.senders.outbound-sender-settings.sender-email-addresses');
  }

  model() {
    return this.app.loadEmailDomains();
  }

  beforeModel(transition) {
    this._notifyConfirmationForQueryParams(transition.to.queryParams);
  }

  _notifyConfirmationForQueryParams(queryParams) {
    if (isPresent(queryParams) && isPresent(queryParams['verifiedCustomEmail'])) {
      let email = `${queryParams['verifiedCustomEmail']}`;
      let confirmationText = this.intl.t('settings.senders.outbound-sender-settings.verified', {
        email,
      });
      this.notificationsService.notifyConfirmation(confirmationText, ENV.APP._15000MS);
      delete queryParams['verifiedCustomEmail'];
      this.replaceWith('apps.app.settings.senders', this.appService.app, { queryParams });
    }
  }
}
