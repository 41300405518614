/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';

export default class MessagingRoute extends ManageMessagesSettingsRoute {
  @service appService;
  @service store;

  analytics = {
    section: 'settings',
    place: 'messaging',
  };
  titleToken = 'Message settings';

  get app() {
    return this.appService.app;
  }

  async model() {
    return this.store.findRecord('messaging-settings', this.app.id);
  }
}
