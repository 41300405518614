/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import Admin from 'embercom/models/admin';
import { inject as service } from '@ember/service';

export default class TeammateRoute extends ManageTeammatesRoute {
  @service store;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    if (transition.targetName === 'apps.app.settings.teammates.teammate.index') {
      this.replaceWith('apps.app.settings.teammates.teammate.permissions');
    }
  }

  model({ id }) {
    return Admin.peekAndMaybeLoad(this.store, id);
  }
}
