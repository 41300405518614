/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';
import { readOnly } from '@ember/object/computed';

export default IntercomRoute.extend(EventTracking, {
  analytics: {
    section: 'articles',
    place: 'collections',
  },
  intercomEventService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  titleToken: 'Help Center',
  permissionsService: service(),
  intl: service(),
  store: service(),

  beforeModel() {
    this.controllerFor('apps.app.articles.site.collections').set('collection', null);
  },

  model() {
    if (this.app.canUseArticlesSubSections) {
      return Promise.all([this.getAllCollections(), this.getAllArticleSummariesInGroups()]);
    }
    return this.findCollectionAndChildren();
  },

  afterModel(model, transition) {
    if (this.app.canUseArticlesSubSections) {
      let [collections, _] = model;
      let collectionId = transition.to.queryParams.collectionId;
      collections.forEach((collection) =>
        collection.set('shouldFocusName', collectionId === collection.get('id')),
      );
    }
  },

  activate() {
    this.intercomEventService.trackEvent('educate-event', {
      action: 'visited',
      object: 'collection_list',
      place: 'collection_list',
      owner: 'educate',
    });
    $('.js__collections__content-pane').scrollTop(0);
  },

  findCollectionAndChildren() {
    return this.store.query('articles/article-group', {
      parent_id: 'home',
      include_ancestors: true,
      include_article_stats: true,
    });
  },

  getAllCollections() {
    return this.store.query('articles/article-group', {
      include_article_stats: true,
    });
  },

  getAllArticleSummariesInGroups() {
    let appId = this.app.id;
    let helpCenterId = this.controllerFor('apps.app.articles.site.collections').helpCenterSite.id;

    return get(
      `/ember/articles_collections/articles/?app_id=${appId}&help_center_id=${helpCenterId}`,
    );
  },

  actions: {
    createCollection() {
      this.controllerFor('apps.app.articles.site.collections').send('createCollection');
    },

    goToCollection(collection) {
      this.transitionTo('apps.app.articles.site.collections.collection', collection.id);
    },

    refreshModel() {
      this.refresh();
    },
  },
});
