/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { hash } from 'rsvp';
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ConversationData extends BaseSettingsRoute {
  @service intercomEventService;
  @service appService;
  @service modalService;
  @service store;

  titleToken = 'Conversation data';

  @service permissionsService;

  beforeModel(transition) {
    super.beforeModel(transition);
    let permission = 'can_access_product_settings';
    this.permissionsService.ensurePermissionWhenTransitioning(permission, transition);
  }

  model() {
    let app = this.modelFor('apps.app');

    return hash({
      app,
      conversationAttributeDescriptors: this.store.query('conversation-attributes/descriptor', {
        system_defined: false,
      }),
    });
  }

  @action refreshRoute() {
    this.refresh();
  }
}
