/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import CONFIRMATION_MODAL_CONTENT from 'embercom/lib/reporting/custom/navigation-guard';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Range from 'embercom/models/reporting/range';

export default class NewReportRoute extends Route {
  @service store;
  @service appService;
  @service intercomEventService;
  @service intercomConfirmService;
  @service router;
  @service notificationsService;
  @service paywallService;
  @service permissionsService;
  @service intl;
  @service navbarCollapsingService;
  @service customReportsService;

  analytics = {
    place: 'custom_reports',
  };

  referrer = 'apps.app.reports.overview';

  get newReport() {
    return this.store.peekAll('reporting/custom/report').findBy('isNew', true);
  }

  activate() {
    if (this.appService.app.canSeeR2Beta && !this.navbarCollapsingService.collapsed) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }

  getReferrer(transition) {
    if (transition.from === null) {
      return this.referrer;
    } else if (
      transition.from.name === 'apps.app.reports.custom-reports.report.new.index' ||
      transition.from.name === 'apps.app.reports.custom-reports.report.new.chart.new'
    ) {
      return this.referrer;
    } else {
      return this.router.currentURL;
    }
  }

  generateID() {
    return `new-${new Date().valueOf()}`;
  }

  beforeModel(transition) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'create_report',
    });

    let report = this.newReport;
    this.referrer = this.getReferrer(transition);
    if (this.appService.app.canSeeR2Beta) {
      report = this.store.createRecord('reporting/custom/report', {
        id: this.generateID(),
      });
      report.applyDateRange(Range.createFromPreset('past_4_weeks', this.appService.app.timezone));
    } else if (!report) {
      report = this.store.createRecord('reporting/custom/report');
    }

    if (!this.customReportsService.canChangeCustomReports) {
      report.unloadRecord();
      this.transitionTo('apps.app.reports.views.view', 'all-reports');
      this.customReportsService.loadChangePermissionModal();
    }

    if (this.appService.app.canSeeR2Beta) {
      this.router.transitionTo('apps.app.reports.custom-reports.report.show', report.id, {
        queryParams: {
          fromReportCreation: true,
        },
      });
    }
  }

  model() {
    return hash({
      report: this.newReport,
      referrer: this.referrer,
    });
  }

  @action
  async willTransition(transition) {
    let { report } = this.modelFor(this.routeName);
    let isPaywallActive = this.paywallService.isPaywallActive({
      featureName: 'custom_reports',
    });
    let isDirty = Object.keys(report.changedAttributes()).length > 0;

    if (
      isPaywallActive ||
      (report.isNew &&
        !isDirty &&
        transition.to.name !== 'apps.app.reports.custom-reports.report.new.chart.new')
    ) {
      report.unloadRecord();
      return;
    }

    if (transition.data.skipConfirmation) {
      return;
    }

    // This guard prevents an issue with ember where an infinite loop is triggered if you abort a transition
    // and have a query param with `refreshModel` set to true: https://github.com/emberjs/ember.js/issues/12473
    // For more context see: https://github.com/intercom/embercom/pull/47042
    if (transition.queryParamsOnly) {
      return;
    }
    if (isDirty && this.notAddingCR2Chart(transition)) {
      transition.abort();
      let confirmed = await this.intercomConfirmService.confirm(CONFIRMATION_MODAL_CONTENT);
      if (confirmed === true) {
        await report.save();
        transition.data.skipConfirmation = true;
        this.notificationsService.notifyConfirmation(
          this.intl.t('reporting.custom-reports.report.changes-discarded'),
        );
        if (report.charts.length > 0) {
          this.intercomEventService.trackEvent('saved-custom-report');
        }
        if (transition.targetName === 'apps.app.reports.custom-reports.report.new.chart.new') {
          this.replaceWith('apps.app.reports.custom-reports.report.show.chart.new', report.id);
        } else {
          transition.retry();
        }
      } else if (confirmed.canceled) {
        report.rollbackAttributes();
        transition.data.skipConfirmation = true;
        transition.retry();
      } else if (confirmed.closed) {
        // Workaround for https://github.com/emberjs/ember.js/issues/5210#issuecomment-623967508
        this.router.location.setURL(this.router.currentURL);
      }
    }
  }

  @action
  revert() {
    let { report } = this.modelFor(this.routeName);
    this.store.unloadRecord(report);
  }

  @action
  hasChanges() {
    let { report } = this.modelFor(this.routeName);
    return report.hasCharts || report.hasTitle || report.hasDescription;
  }

  notAddingCR2Chart(transition) {
    return (
      !transition.to.queryParams.cr2AddingChartToReport ||
      transition.to.queryParams.cr2AddingChartToReport === 'false'
    );
  }
}
