/* RESPONSIBLE TEAM: team-tickets-1 */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type IntlService from 'embercom/services/intl';

export default class TicketData extends BaseSettingsRoute {
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;

  get titleToken() {
    return this.intl.t('settings.ticket-data.index-title');
  }

  get app() {
    return this.appService.app;
  }

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    let permission = 'can_access_product_settings';
    this.permissionsService.ensurePermissionWhenTransitioning(permission, transition);
  }

  async model() {
    let ticketTypes = await this.fetchTicketTypes();

    return ticketTypes.toArray();
  }

  async fetchTicketTypes() {
    return this.store.findAll('inbox/ticket-type');
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('ticket-types-visited');
  }
}
