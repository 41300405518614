/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isValidEmail } from 'embercom/lib/email';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';

export default class CsvImportRoute extends ManageTeammatesRoute {
  @service appService;

  analytics = {
    section: 'settings',
    place: 'teammate-invite-csv-import',
  };

  beforeModel(transition) {
    if (!transition.data.parsedCsv) {
      this.transitionTo('apps.app.settings.teammates');
    }
  }

  model(_params, transition) {
    return {
      columns: this._columnsFromParsedCsv(transition.data.parsedCsv),
      hasHeaderRow: this._guessHasHeaderRow(transition.data.parsedCsv),
      selectedIndex: this._guessEmailAddressColumnIndex(transition.data.parsedCsv),
    };
  }

  _columnsFromParsedCsv(parsedCsv) {
    // convert rows of columns to columns of rows
    let rows = parsedCsv.data;
    let columns = new Array(rows[0].length);
    for (let col = 0; col < columns.length; col++) {
      columns[col] = new Array(rows.length);
      for (let row = 0; row < columns[col].length; row++) {
        columns[col][row] = rows[row][col];
      }
    }
    return columns;
  }

  _guessHasHeaderRow(parsedCsv) {
    let firstRow = parsedCsv.data[0];
    if (firstRow && firstRow.length > 0) {
      return firstRow.find((item) => isValidEmail(item)) === undefined;
    } else {
      return false;
    }
  }

  _guessEmailAddressColumnIndex(parsedCsv) {
    let headerRow = parsedCsv.data[0];
    let columnNames = headerRow.map((n) => n.toLowerCase());
    let emailColumnName =
      columnNames.find((n) => n === 'email') ||
      columnNames.find((n) => n.startsWith('email')) ||
      columnNames.find((n) => n.includes('email'));
    if (emailColumnName) {
      return columnNames.indexOf(emailColumnName);
    }

    let sampleRow = parsedCsv.data.length > 1 ? parsedCsv.data[1] : parsedCsv.data[0];
    let firstPossibleEmail = sampleRow.findIndex((value) => isValidEmail(value));
    if (firstPossibleEmail !== -1) {
      return firstPossibleEmail;
    }
    return null;
  }

  _getInviteEmails() {
    let inviteEmails = this.currentModel.columns[this.currentModel.selectedIndex];
    if (this.currentModel.hasHeaderRow) {
      inviteEmails = inviteEmails.slice(1);
    }
    return inviteEmails;
  }

  @action
  onContinue() {
    let inviteModel = this.modelFor('apps.app.settings.teammates.invite');
    inviteModel.emails = this._getInviteEmails();
    this.transitionTo('apps.app.settings.teammates.invite');
  }
}
