/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';

export default class NewSubscriptionRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare router: RouterService;

  get app() {
    return this.modelFor('apps.app');
  }

  activate() {
    this.router.on('routeWillChange', async () => {
      let subscriptionTypes = await this.store.findAll('outbound-subscriptions/subscription-type');

      // Safely unloading unsaved subscription because we fetch all subscriptions via store.findAll in editor sidebar
      subscriptionTypes
        .filter((subscriptionType) => !subscriptionType.id)
        .map((subscriptionType) => this.store.unloadRecord(subscriptionType));
    });
  }

  model() {
    return RSVP.hash({
      translationSettings: this.store.findAll('outbound-subscriptions/translation-settings'),
    });
  }
}
