/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class WorkFlowConnectorActions extends SettingsRoute {
  @service store;
  @service appService;
  @service intercomEventService;
  @service permissionsService;

  analytics = {
    section: 'settings',
    place: 'action-builder',
  };
  titleToken = 'Action Builder';

  beforeModel(transition) {
    super.beforeModel(transition);
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_developer_hub',
      transition,
    );
  }

  async model() {
    await this.store.findAll('custom-objects/object-type');
    // conversation-attributes/descriptor are loaded so they can be used by attribute-service
    await this.store.findAll('conversation-attributes/descriptor');
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('has_visited_custom_actions');
  }
}
