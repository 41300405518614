/* RESPONSIBLE TEAM: team-proactive-support */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';

export default class SmsRoute extends ManageMessagesSettingsRoute {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare intl: IntlService;

  analytics = {
    section: 'settings',
    place: 'sms',
  };

  get titleToken() {
    return this.intl.t(`new-settings.submenu.channels.sms`);
  }

  async model() {
    let customerTwilioCredentialsList = await this.store.findAll(
      'outbound-customer-twilio-credentials',
    );

    let customerTwilioCredentials =
      customerTwilioCredentialsList.firstObject ||
      this.store.createRecord('outbound-customer-twilio-credentials');

    let smsOutboundSettingsList = await this.store.findAll('sms-outbound-settings');

    let smsOutboundSettings =
      smsOutboundSettingsList.firstObject || this.store.createRecord('sms-outbound-settings');

    let smsKeywordResponsesList = await this.store.findAll('sms/keyword-response');
    let smsKeywordResponses =
      smsKeywordResponsesList.toArray() || this.store.createRecord('sms/keyword-response');

    let smsPhoneNumbersList = await this.store.findAll('sms/phone-number');
    let smsPhoneNumbers = smsPhoneNumbersList.toArray();

    return {
      customerTwilioCredentials,
      smsOutboundSettings,
      smsKeywordResponses,
      smsPhoneNumbers,
    };
  }

  beforeModel() {
    super.beforeModel(...arguments);
    if (!this.appService.app.canUseSMS) {
      this.router.transitionTo('apps.app.outbound.settings.messaging');
    }
  }
}
