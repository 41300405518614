/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class SubscriptionTypesRoute extends ManageMessagesSettingsRoute {
  @service intercomEventService;
  @service store;

  titleToken = 'Subscriptions';
  analytics = {
    place: 'subscription_types',
  };

  get app() {
    return this.modelFor('apps.app');
  }

  beforeModel() {
    super.beforeModel(...arguments);
  }

  model() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'subscription_types',
      place: 'settings',
      section: 'outbound',
      admin_id: this.app.currentAdmin.id,
    });

    return RSVP.hash({
      subscriptionTypes: this.store.findAll('outbound-subscriptions/subscription-type'),
      translationSettings: this.store.findAll('outbound-subscriptions/translation-settings'),
    });
  }
}
