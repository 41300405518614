/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { confirmSubscriptionChanges } from 'embercom/lib/admins/inbox-seat-info';
import { confirmMultipleSeatOveragesIncrease } from 'embercom/lib/admins/multiple-seats-info';
import { INVITE_USAGE as INVITE } from 'embercom/lib/settings/seats/constants';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { hash } from 'rsvp';
import { COPILOT_SEAT_TYPE as COPILOT } from 'embercom/lib/settings/seats/constants';

export default class EditRoute extends ManageTeammatesRoute {
  @service appService;
  @service store;
  @service router;
  @service intl;
  @service permissionsHierarchyService;
  @service notificationsService;
  @service intercomEventService;
  @service intercomConfirmService;

  closeWithoutSaving = false;

  analytics = {
    section: 'settings',
    place: 'edit-single-invite-permissions',
  };

  initialUsage;
  updatedUsage;

  async beforeModel() {
    await super.beforeModel(...arguments);
    this.closeWithoutSaving = false;
    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();
  }

  async model({ id }) {
    // We need to fetch all invites because there is no endpoint for fetching a single invite
    await this.store.findAll('invited-admin');
    return hash({
      app: this.appService.app,
      invite: this.store.peekRecord('invited-admin', id),
      roles: this.modelFor('apps.app.settings.teammates.invite').roles,
      usageType: INVITE,
      copilotData: this.modelFor('apps.app.settings.teammates.index')?.copilotData,
    });
  }

  async afterModel(model) {
    if (this.appService.app.onPricing5 && model.invite.seatTypes.length === 0) {
      model.set('invite.seatTypes', ['core']);
    }
  }

  async saveChanges() {
    try {
      let invite = this.currentModel.invite;
      await invite.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'teammate-invite',
        place: 'teammate-list',
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammates.invite.notification.edit-success-generic', {
          email: invite.email,
        }),
      );
      let transition = this.transitionTo('apps.app.settings.teammates');
      transition.data.selectedTab = 'invites';
    } catch (error) {
      if (error.jqXHR?.responseJSON?.error_code === 'seat_limit_reached') {
        this.notificationsService.notifyErrorWithButton(
          this.intl.t('pricing-and-packaging.multi_workspace_seat_limit_reached.error_message'),
          {
            label: this.intl.t(
              'pricing-and-packaging.multi_workspace_seat_limit_reached.button_label',
            ),
            action: this.redirectToBilling.bind(this),
          },
          10000,
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.teammates.invite.notification.unexpected-error'),
        );
      }
      console.error(error);
    }
  }

  redirectToBilling() {
    this.router.transitionTo('apps.app.billing');
  }

  get numberOfSeatsRequired() {
    let invite = this.currentModel.invite;
    return invite.hasDirtyAttributes &&
      invite.has_inbox_access &&
      invite.changedAttributes().has_inbox_access
      ? 1
      : 0;
  }

  @action
  setInitialUsage(usage) {
    this.initialUsage = usage;
  }

  @action
  onChangeLocale(locale) {
    this.currentModel.invite.locale = locale;
  }

  @action
  setUpdatedUsage(usage) {
    this.updatedUsage = usage;
  }

  @action
  async confirmAndSaveChanges() {
    if (
      (await this.confirmMultipleSeatOveragesIncreaseIfHasSeats()) &&
      (await confirmSubscriptionChanges(
        this.numberOfSeatsRequired,
        this.showConfirmModal,
        {
          confirmationButtonLabel: this.intl.t(
            'settings.teammates.invite.save-changes-modal.save-and-fill-seat',
          ),
          editFlow: true,
        },
        false,
        this.appService.app.canUseFinAiCopilotAddon &&
          this.currentModel.invite.seatTypes.includes(COPILOT),
        this.currentModel.copilotData,
      ))
    ) {
      await this.saveChanges();
    }
  }

  @action
  async showConfirmModal(options) {
    return this.intercomConfirmService.confirm(options);
  }

  async confirmMultipleSeatOveragesIncreaseIfHasSeats() {
    if (this.appService.app.hasMultipleSeatTypes) {
      return await confirmMultipleSeatOveragesIncrease(
        this.appService.app,
        {
          seatTypes: this.currentModel.invite.seatTypes,
          initialUsage: this.initialUsage,
          updatedUsage: this.updatedUsage,
          teammateCount: 1,
          isInvite: true,
        },
        this.showConfirmModal,
      );
    }
    return true;
  }

  @action
  async willTransition(transition) {
    if (this.closeWithoutSaving) {
      return;
    }

    let permissionsObject = this.currentModel.invite;
    if (permissionsObject && permissionsObject.hasDirtyAttributes) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('settings.teammates.invite.cancel-modal.close-without-saving'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'settings.teammates.invite.cancel-modal.close-without-saving-btn',
        ),
        cancelButtonText: this.intl.t('settings.teammates.invite.cancel-modal.keep-editing'),
        body: this.intl.t('settings.teammates.invite.cancel-modal.lose-your-changes'),
      };

      if (await this.intercomConfirmService.confirm(confirmOptions)) {
        // I wish I didn't have to do this. Normally we would rollback then retry
        // which would run this action again and exit the route. However, doing that
        // in this route looks bad. The IcOnOffToggle components which are bound to
        // the model are animated with CSS transitions - so you'll see them animate
        // back to their original positions before we exit the route.
        this.closeWithoutSaving = true;
        await transition.retry();
        permissionsObject.rollbackAttributes();
      }
    }
  }
}
