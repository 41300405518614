/* RESPONSIBLE TEAM: team-data-interop */
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';

export default ProductSettingsRoute.extend({
  intl: service(),
  analytics: {
    section: 'settings',
    place: 'bulk_export',
  },
  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.bulk-export');
  },
});
