/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/addon/services/intl';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { hash } from 'rsvp';

export default class RemoveRoute extends ManageTeammatesRoute {
  @service declare permissionsMutatorService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;

  analytics = {
    section: 'settings',
    place: 'teammate-remove',
  };

  async beforeModel() {
    await super.beforeModel(...arguments);
    let teammateId = (this.paramsFor('apps.app.settings.workspace.teammates.teammate') as $TSFixMe)
      .id;
    if (teammateId === this.appService.app.currentAdmin.id) {
      this.replaceWith('apps.app.settings.teammates.teammate.permissions', teammateId);
    }
    await this.customerService.ensureDataIsLoaded.perform();
  }

  model() {
    return hash({
      app: this.appService.app,
      teammate: this.modelFor('apps.app.settings.workspace.teammates.teammate'),
      reassignments: { teams: {} },
      annuallyContractedWithOveragesEnabled: this.annuallyContractedWithOveragesEnabled,
    });
  }

  get annuallyContractedWithOveragesEnabled() {
    return this.appService.app.canUseSeatOverages && this.appService.app.isSalesforceContracted;
  }
}
