/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-channels */
import ProductMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/product-messages-settings-route';

export default class EmailSignature extends ProductMessagesSettingsRoute {
  analytics = {
    section: 'settings',
    place: 'email_signature',
    object: 'email_signature',
  };
  titleToken = 'Email signature';
}
