/* RESPONSIBLE TEAM: team-product-guidance */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { captureException } from 'embercom/lib/sentry';
import type Router from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import storage from 'embercom/vendor/intercom/storage';
import confetti from 'embercom/lib/signup/confetti';
import type GuideLibraryService from 'embercom/services/guide-library-service';

export default class GuideLibraryRoute extends Route {
  @service declare customerService: $TSFixMe;
  @service declare onboardingHomeService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare router: Router;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare guideLibraryService: GuideLibraryService;

  async beforeModel() {
    if (this.appService.app.canUseGreatGuidanceMilestoneOne) {
      this.router.transitionTo('apps.app.getting-started', this.appService.app.id);
    }
  }

  async model() {
    if (this.onboardingHomeService.guideForCurrentAppFromStore()) {
      await this.onboardingHomeService.findOrLoadGuide();
    } else {
      try {
        await Promise.all([
          this.customerService.refreshCustomer(),
          this.onboardingHomeService.findOrLoadGuide(),
        ]);
      } catch (e) {
        console.error('Home model hook error:', e);
        captureException(e, {
          fingerprint: ['route', 'home', 'model', e.message],
        });
        throw e;
      }
    }
    return this.onboardingHomeService.guide;
  }

  afterModel() {
    let shouldShowConfetti = storage.get('shouldShowConfetti');
    if (shouldShowConfetti) {
      this.notificationsService.notifyConfirmation(this.intl.t('teams-checkout.confirm.success'));
      confetti.showSuccesAnimation();
    }
    storage.remove('shouldShowConfetti');
  }

  activate(_transition: Transition<unknown>): void {
    document.title = 'Getting Started guide | Intercom';
  }
}
