/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-jquery */
import { debounce, throttle } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { configureScope } from '@sentry/browser';
import ENV from 'embercom/config/environment';
import ModelCacheHelper from 'embercom/lib/model-cache/helper';
import { captureException } from 'embercom/lib/sentry';
import Admin from 'embercom/models/admin';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import $ from 'jquery';
import { isEmpty } from 'underscore';
import OldVersion from 'embercom/components/notifications/old-version';

const SHORTCUT_GROUP = 'global-shortcuts';

export default IntercomRoute.extend({
  intercomEventService: service(),
  notificationsService: service(),
  pubsub: service(),
  store: service(),
  cookies: service(),
  browserTabNotifications: service(),

  userPresenceService: service(),
  realTimeEventService: service(),
  adminPingService: service(),
  settingsService: service(),
  inboxService: service(),
  session: service(),
  bootService: service(),
  iamService: service(),
  shortcuts: service(),
  frontendStatsService: service(),
  pageLoadPerformanceInstrumentationService: service(),
  modelDataCacheService: service(),
  identityVerificationRiskStateService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  cardlessTrialService: service(),
  sounds: service(),
  reachabilityService: service(),

  async beforeModel(transition) {
    this.frontendStatsService.recordBootLocationAndType(transition.to.name);
    this.bootService.on('onSecondaryStage', (app) => {
      this.iamService.boot(app);
      this.adminPingService.start(
        app.id,
        app.get('currentAdmin').id,
        app.canUseTeammateStatusAutoAwayMode,
      );
      this.frontendStatsService.recordCacheMetrics();
      this.frontendStatsService.sendInteractionMetrics();
      this.pageLoadPerformanceInstrumentationService.sendSampledPageLoadPerformanceEvent();
      if (!Em.testing) {
        this.identityVerificationRiskStateService.start(app);
      }
    });

    if (this.app) {
      this._cleanup();
    }
  },
  model(params) {
    this._lastAppIdLoaded = params.app_id;
    if (ModelCacheHelper.isDisabled()) {
      return this.store.findRecord('app', params.app_id);
    } else {
      return this.modelDataCacheService.fetch('app', params.app_id);
    }
  },
  setupController(controller, model, transition) {
    this._super(controller, model);
    controller.set('solutionId', model.get('signedUpWithSolutionId'));
  },
  afterModel(model, transition) {
    this._eagerlyLoadAssociations(model);
    this.set('app', model);
    this.appService.refresh();
    this.cardlessTrialService.initializeUsingApp(model);
    this.bootService.setApp(model);

    if (model.get('is_test_app')) {
      this.intercomEventService.trackEvent('used-a-test-app');
    }

    this.reachabilityService.startNotifier(
      () => debounce(this, 'onNetworkIsReachable', ENV.APP._2000MS),
      () => debounce(this, 'onNetworkIsNotReachable', ENV.APP._2000MS),
    );

    this._setSentryUserContext();
    this.settingsService.start(this.app);

    this._onSeatlessExperience(model);

    // Prevent Fin Standalone only apps from transitioning to the main app
    let isStandaloneAppWithoutAccessOutside =
      model.canUseFinStandalone && !model.allowAccessOutsideStandaloneSection;

    if (
      isStandaloneAppWithoutAccessOutside &&
      !transition.to.name.includes('apps.app.standalone') &&
      !transition.to.name.includes('apps.app.settings')
    ) {
      this.transitionTo('apps.app.standalone.setup');
    }
  },

  activate() {
    this.registerShortcuts();
    this._startRealTime(this.app);
    this._startUserPresence(this.app);
    this.subscribeRealTimeEvents();
  },

  deactivate() {
    this._cleanup();
  },

  _cleanup() {
    this.unsubscribeRealTimeEvents();
    this.shortcuts.unregister(SHORTCUT_GROUP);
    this._stopRealTime();
    this._stopUserPresence();
    this.adminPingService.stop();
  },

  _onSeatlessExperience(app) {
    if (!app.isTestApp && app.hasMultipleSeatTypes && app.currentAdmin.seats.length === 0) {
      this.replaceWith('apps.app.seatless-experience');
    }
  },

  // This is because of relationship between ember data and ember model being lazy
  // So we fetch them eagerly to make sure they will be pushed in the store and we won't do extra network requests
  _eagerlyLoadAssociations(model) {
    model.get('segments');
  },

  onNetworkIsReachable() {
    if (this.isDestroying) {
      return;
    }
    this.pubsub.publish('networkIsReachable');

    if (this._inInbox()) {
      this.fetchInboxCounters();
    }
    this._startUserPresence(this.app);
  },

  onNetworkIsNotReachable() {
    this.pubsub.publish('networkIsNotReachable');
    this._stopUserPresence();
  },

  registerShortcuts() {
    let stateCheck = 'advancedEnabled';
    this.shortcuts.register(SHORTCUT_GROUP, this, [
      { stateCheck, key: 'g|a', method: this.allInboxShortcut },
      { stateCheck, key: 'g|i', method: this.inboxShortcut },
      { stateCheck, key: 'g|u', method: this.usersShortcut },
    ]);
  },

  inboxShortcut() {
    this.controller.send('transitionToInbox');
  },

  allInboxShortcut() {
    this.controller.send('transitionToInbox', 'all');
  },

  usersShortcut() {
    this.transitionTo('apps.app.users');
  },

  _startRealTime(app) {
    this.realTimeEventService.initForApp(app);
  },
  _stopRealTime() {
    try {
      this.realTimeEventService.unsubscribe();
    } catch (e) {
      // no-op
    }
  },

  _startUserPresence(app) {
    let userPresenceService = this.userPresenceService;
    if (userPresenceService) {
      userPresenceService.start(app);
    }
  },
  _stopUserPresence() {
    if (this.get('userPresenceService.isPollingStarted')) {
      this.userPresenceService.stop();
    }
  },
  _setSentryUserContext() {
    let currentAdminId = this._adminIdInt();
    configureScope((scope) => {
      scope.setUser({
        id: currentAdminId,
        app_id: this.get('app.id'),
      });
    });
  },
  _adminIdInt() {
    return parseInt(this.get('app.currentAdmin.id'), 10);
  },
  _operatorBotIdInt() {
    return parseInt(this.get('app.operatorBot.id'), 10);
  },
  _inAllInbox() {
    return this.get('inboxService.inbox.identifier') === 'all';
  },
  _inOwnInbox() {
    return this.get('inboxService.inbox.identifier') === this.modelFor('apps').get('id');
  },
  transitionToConversation(conversationId) {
    return function () {
      this.transitionTo(
        this.get('app.inboxConversationsConversationRoute'),
        this.modelFor('apps').get('email'),
        conversationId,
      );
    }.bind(this);
  },

  // Notifications: Thread Assigned
  // ==============================
  //
  _threadAssignedEvent(event) {
    this._refreshNavCountersIfNeeded(event);
    this.threadAssignedBrowserAlert(event);
  },
  threadAssignedBrowserAlert(event) {
    let currentAdminId = this._adminIdInt();
    let operatorBotId = this._operatorBotIdInt();

    if (event.assigneeIdWas === currentAdminId) {
      this.browserTabNotifications.reset(event.conversationId);
    } else if (event.assigneeId === currentAdminId || this._isNotificationForTeam(event)) {
      this._newMessageBrowserAlert(event);
    } else if (event.assigneeIdWas === operatorBotId) {
      // Conversation was moved out of the bot inbox
      this._newMessageBrowserAlert(event);
    }
  },

  // Notifications: Thread Updated
  // =============================
  //
  _threadUpdatedEvent(event) {
    this.app.set('has_unseen_feed_conversations', true);
    this._refreshNavCountersIfNeeded(event);

    this.threadUpdatedBrowserAlert(event);
  },
  threadUpdatedBrowserAlert(event) {
    if (!this._shouldReceiveBrowserAlert(event)) {
      return;
    }
    let isReopenedDueToReply = event.subType === 'open' && !isEmpty(event.lastActivityPreview);
    let isNotifyingUpdate = event.subType === 'comment' || isReopenedDueToReply;
    if (!isNotifyingUpdate) {
      return;
    }
    if (event.lastActivityOwnerId) {
      this.browserTabNotifications.reset(event.conversationId);
    } else {
      let notificationContent = `${event.userDisplayAs || 'Someone'} says…`;
      this.triggerBrowserAlert(notificationContent, event.conversationId);
    }
  },

  // Notifications: Thread Created
  // =============================
  //
  _threadCreatedEvent(event) {
    this.app.set('has_unseen_feed_conversations', true);
    this._refreshNavCountersIfNeeded(event);

    this.threadCreatedBrowserAlert(event);
  },
  threadCreatedBrowserAlert(event) {
    this._newMessageBrowserAlert(event);
  },
  _newMessageBrowserAlert(event) {
    if (!this._shouldReceiveBrowserAlert(event)) {
      return;
    }
    let notificationContent = event.userDisplayAs
      ? `New message from ${event.userDisplayAs}`
      : 'New message received';
    this.triggerBrowserAlert(notificationContent, event.conversationId);
  },
  _shouldReceiveBrowserAlert(event) {
    let admin = this.modelFor('apps');
    switch (admin && admin.get('browser_alert_setting')) {
      case 1: // Assigned conversations only
        return this._isNotificationForCurrentAdmin(event);
      case 3:
        return this._isNotificationForTeam(event) || this._isNotificationForCurrentAdmin(event);
      case 5: // Assigned and unassigned conversations
        return (
          this._isNotificationForUnassigned(event) || this._isNotificationForCurrentAdmin(event)
        );
      case 7:
        return (
          this._isNotificationForUnassigned(event) ||
          this._isNotificationForTeam(event) ||
          this._isNotificationForCurrentAdmin(event)
        );
      default:
        return false;
    }
  },
  triggerBrowserAlert(content, conversationId) {
    let admin = this.modelFor('apps');
    if (admin.get('audio_notifications_enabled')) {
      this.sounds.playAdminNotification();
    }
    this.browserTabNotifications.showBrowserAlert(content, conversationId);
  },

  // Notifications: Thread Closed
  // =============================
  //

  _threadClosedEvent(event) {
    if (this.get('app.inboxIsNotActive')) {
      this.app.refreshNavCounters();
    }
  },

  // AdminAwayReassignMode
  // =============================
  //
  _updatedAwayModeFromRealtimeEvent(event) {
    let adminToUpdate = Admin.peekAndMaybeLoad(this.store, event.admin_id);
    adminToUpdate.set('away_mode_enabled', event.away_mode_enabled);
    adminToUpdate.set('reassign_conversations', event.reassign_conversations);
  },

  // Notifications logic
  // ===================
  //
  _isNotificationForCurrentAdmin(event) {
    let adminIdInt = this._adminIdInt();
    if (event.ruleId) {
      return event.assigneeId === adminIdInt && event.assigneeIdWas !== adminIdInt;
    }
    return event.assigneeId === adminIdInt && event.lastActivityOwnerId !== adminIdInt;
  },
  _isNotificationForUnassigned(event) {
    if (this.app?.canUseDisableNotificationForRepliesToUnassigned && event.assigneeId === 0) {
      let exitingOperatorInbox =
        ['ThreadUpdated', 'ThreadAssigned'].includes(event.eventName) &&
        event.lastActivityOwnerId === this._operatorBotIdInt();
      return event.eventName === 'ThreadCreated' || exitingOperatorInbox;
    }
    return event.assigneeId === 0 && event.lastActivityOwnerId !== 0;
  },

  _isNotificationForTeam(event) {
    let team = this.get('app.teams').findBy('id', event.assigneeId?.toString());

    return team && team.get('member_ids').includes(this._adminIdInt());
  },

  subscribeRealTimeEvents() {
    let realtimeService = this.realTimeEventService;
    realtimeService.on('ThreadAssigned', this, '_threadAssignedEvent');
    realtimeService.on('ThreadUpdated', this, '_threadUpdatedEvent');
    realtimeService.on('ThreadReopened', this, '_threadUpdatedEvent');
    realtimeService.on('ThreadUnsnoozed', this, '_threadUpdatedEvent');
    realtimeService.on('ThreadCreated', this, '_threadCreatedEvent');
    realtimeService.on('ThreadClosed', this, '_threadClosedEvent');
    realtimeService.on('NewMinimumVersion', this, '_newMinimumVersionEvent');
    realtimeService.on('AdminAwayReassignModeChange', this, '_updatedAwayModeFromRealtimeEvent');

    realtimeService.subscribeTopics(['inbox-general', 'user-blocked']);
  },
  unsubscribeRealTimeEvents() {
    let realtimeService = this.realTimeEventService;
    realtimeService.off('ThreadAssigned', this, '_threadAssignedEvent');
    realtimeService.off('ThreadUpdated', this, '_threadUpdatedEvent');
    realtimeService.off('ThreadReopened', this, '_threadUpdatedEvent');
    realtimeService.off('ThreadUnsnoozed', this, '_threadUpdatedEvent');
    realtimeService.off('ThreadCreated', this, '_threadCreatedEvent');
    realtimeService.off('ThreadClosed', this, '_threadClosedEvent');
    realtimeService.off('NewMinimumVersion', this, '_newMinimumVersionEvent');
    realtimeService.off('AdminAwayReassignModeChange', this, '_updatedAwayModeFromRealtimeEvent');

    realtimeService.unsubscribeTopics(['inbox-general', 'user-blocked']);
  },

  _refreshNavCountersIfNeeded(event) {
    if (this.get('app.inboxIsNotActive')) {
      this.app.refreshNavCounters();
    } else if (!this._inInbox()) {
      let currentAdminId = this._adminIdInt();
      if (
        event.lastActivityOwnerId === currentAdminId ||
        event.assigneeIdWas === currentAdminId ||
        event.assigneeId === currentAdminId
      ) {
        this.app.refreshNavCounters();
      }
    }
  },

  _newMinimumVersionEvent(event) {
    let OLD_VERSION_ERROR_KEY = 'OLD_VERSION_ERROR';
    let newMinimumVersionId = event.minimumVersionId;
    let runningVersionId = Number($('meta[name="embercom_revision_id"]').attr('content') || '0');
    if (runningVersionId < newMinimumVersionId) {
      this.notificationsService.removeNotification(OLD_VERSION_ERROR_KEY);
      this.notificationsService.notifyWarningWithModelAndComponent(
        {},
        OldVersion,
        ENV.APP.notificationNoTimeout,
        OLD_VERSION_ERROR_KEY,
      );
    }
  },

  // TODO: Is this still needed?
  fetchInboxCounters() {
    let inboxes = this.modelFor(this.get('app.inboxRoute')).inboxes;
    let assignee = this.modelFor(`${this.get('app.inboxBaseRoute')}.assignee`);
    if (assignee) {
      assignee.set('isLoaded', false);
    }
    inboxes.set('isLoaded', false);
    this.store.findAll('inbox').then((newInboxes) => {
      let me = this.modelFor('apps');

      if (this.app && me) {
        this.app.setProperties({
          open_message_thread_count: inboxes.findBy('identifier', 'all').get('count'),
          admin_open_message_thread_count: inboxes.findBy('admin.id', me.get('id')).get('count'),
        });
      }

      this.inboxService.updateFavicon();
      this.controllerFor('apps.app.inbox').send('inboxesUpdated', newInboxes);
    });
  },

  actions: {
    error(e) {
      let showBootErrorPage = !this.get('bootService.hasAdvancedToSecondaryStage');
      console.error(e);
      captureException(e, {
        fingerprint: ['route', 'apps', 'app'],
      });
      if (showBootErrorPage) {
        this.iamService.boot();
        return true;
      }
    },
    refreshInboxCounters() {
      try {
        if (this.get('inboxService.isActive') === false) {
          throttle(this, this.fetchInboxCounters, ENV.APP._5000MS, false);
        }
        throttle(this, this.send, 'refreshRelativeTime', ENV.APP._3000MS, false);
      } catch (e) {
        // no-op
      }
    },
    // pushes a payload to store to update existing customer record
    loadCustomer(payload) {
      return this.store.pushPayload('billing/customer', {
        'billing/customer': payload,
      });
    },
    setDocumentTitle(title) {
      document.title = `${title} | ${this.app.get('name')} | Intercom`;
    },
  },
});
