/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { TEAMMATE_USAGE, availableUiSeatTypes } from 'embercom/lib/settings/seats/constants';
import calculateCommonSeats, {
  calculateCommonSeatTypes,
  seatsByTeammates,
  countAmountOfSeatModified,
} from 'embercom/lib/teammates/calculate-common-seats';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { isEqual } from 'underscore';
import ModelWithPermissions from 'embercom/models/model-with-permissions';
import { isPresent } from '@ember/utils';

export default class PermissionsRoute extends ManageTeammatesRoute {
  @service appService;
  @service notificationsService;
  @service permissionsMutatorService;
  @service intl;
  @service permissionsHierarchyService;
  @service intercomEventService;
  @service store;
  @service intercomConfirmService;

  hasSeatsToBeSaved = false;

  closeWithoutSaving = false;
  seatsSelections = {};
  analytics = {
    section: 'settings',
    place: 'bulk-teammate-permissions',
  };

  async beforeModel(transition) {
    await super.beforeModel(...arguments);
    this.closeWithoutSaving = false;

    if (!transition.data.teammates) {
      this.transitionTo('apps.app.settings.teammates');
    }

    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();
  }

  model(_params, transition) {
    let commonRoleId = this._getCommonRoleId(transition.data.teammates);
    let permissionsObject = this.store.createRecord('permission', { role_id: commonRoleId });
    if (this.appService.app.hasMultipleSeatTypes) {
      if (commonRoleId) {
        let role = this.store.peekRecord('role', commonRoleId);
        permissionsObject.setPermissions(role);
        permissionsObject.accessType = role.accessType;
        permissionsObject.includeUnassigned = role.includeUnassigned;
        permissionsObject.assigneeBlockedList = role.assigneeBlockedList;
      } else {
        let teammates = transition.data.teammates;
        this.seatsSelections = calculateCommonSeats(transition.data.teammates);
        this._initializePermissionFields(teammates, permissionsObject);
        this._initializeAccessType(teammates, permissionsObject);
      }
    } else {
      permissionsObject.setAllPermissions(undefined);
      permissionsObject.accessType = undefined;
    }
    return {
      app: this.appService.app,
      teammates: transition.data.teammates,
      permissionsObject,
      permissionsObjectChanged: false,
      reassignments: undefined,
      roles: this.store.findAll('role'),
      seatTypes: this.appService.app.canUseBulkEditSeats
        ? calculateCommonSeatTypes(this.seatsSelections)
        : availableUiSeatTypes(this.appService.app.onPricing5),
      seatsSelections: this.seatsSelections,
      seatsSelectionsChanged: false,
      usageType: TEAMMATE_USAGE,
    };
  }

  _initializePermissionFields(teammates, permissionsObject) {
    ModelWithPermissions.editablePermissionKeys.forEach((permissionKey) => {
      let firstTeammatePermission = teammates.firstObject.currentAppPermissions[permissionKey];
      let allTeammatesHaveSamePermission = teammates.every(
        (teammate) => teammate.currentAppPermissions[permissionKey] === firstTeammatePermission,
      );
      permissionsObject[permissionKey] = allTeammatesHaveSamePermission
        ? firstTeammatePermission
        : undefined;
    });
  }

  _initializeAccessType(teammates, permissionsObject) {
    let firstTeammatePermissions = teammates.firstObject.currentAppPermissions;
    let firstTeammateAccessType = firstTeammatePermissions.accessType;
    let firstTeammateIncludeUnassigned = firstTeammatePermissions.includeUnassigned;
    let firstTeammateAssigneeBlockedList = firstTeammatePermissions.assigneeBlockedList;

    let allTeammatesHaveSameAccessType = teammates.every((teammate) => {
      return (
        teammate.currentAppPermissions.accessType === firstTeammateAccessType &&
        teammate.currentAppPermissions.includeUnassigned === firstTeammateIncludeUnassigned &&
        isEqual(
          teammate.currentAppPermissions.assigneeBlockedList,
          firstTeammateAssigneeBlockedList,
        )
      );
    });

    if (allTeammatesHaveSameAccessType) {
      permissionsObject.accessType = firstTeammateAccessType;
      permissionsObject.includeUnassigned = firstTeammateIncludeUnassigned;
      permissionsObject.assigneeBlockedList = firstTeammateAssigneeBlockedList;
    } else {
      permissionsObject.accessType = undefined;
      permissionsObject.includeUnassigned = undefined;
      permissionsObject.assigneeBlockedList = undefined;
    }
  }

  _getCommonRoleId(teammates) {
    let commonRoleId = teammates[0].currentAppPermissions.role_id;
    let allRolesMatch = teammates.every(
      (teammate) => teammate.currentAppPermissions.role_id === commonRoleId,
    );
    return allRolesMatch ? commonRoleId : null;
  }

  getChangedPermissionProperties(permissionsObject) {
    let rawPermissions = permissionsObject.permissions();
    let changedPermissions = Object.keys(rawPermissions).reduce((result, key) => {
      if (rawPermissions[key] !== undefined) {
        result[key] = rawPermissions[key];
      }
      return result;
    }, {});

    if (permissionsObject.accessType !== undefined) {
      changedPermissions['accessType'] = permissionsObject.accessType;
      changedPermissions['includeUnassigned'] = permissionsObject.includeUnassigned;
      changedPermissions['assigneeBlockedList'] = permissionsObject.assigneeBlockedList;
    }

    return Object.assign({ role_id: permissionsObject.role_id }, changedPermissions);
  }

  @action async validateAndSavePermissions(seatTypes, isOverage = false) {
    try {
      this.currentModel.permissionsObject.validate();
      this.savePermissions(seatTypes, isOverage);
    } catch (error) {
      this.notificationsService.notifyError(error.message);
    }
  }

  @action
  async savePermissions(seatTypes, isOverage) {
    let { teammates, permissionsObject } = this.currentModel;
    if (!isOverage) {
      let confirmed = await this.intercomConfirmService.confirm({
        title: this.intl.t('settings.teammates.permissions.apply-changes-modal.apply-changes'),
        confirmButtonText: this.intl.t(
          'settings.teammates.permissions.apply-changes-modal.apply-changes',
        ),
        body: this.intl.t('settings.teammates.permissions.apply-changes-modal.message-body'),
      });

      if (!confirmed) {
        return;
      }
    }
    let permissionsProperties = this.getChangedPermissionProperties(permissionsObject);
    try {
      if (this.appService.app.canUseBulkEditSeats) {
        this.bulkEditSeatsAndPermissions(teammates, seatTypes, permissionsProperties);
        this.hasSeatsToBeSaved = false;
      } else {
        permissionsObject.reassignments = this.currentModel.reassignments;
        await this.permissionsMutatorService.bulkEditPermissions(teammates, permissionsProperties);
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.teammates.permissions.notification.permissions-saved', {
            teammateCount: teammates.length,
          }),
        );
      }

      this.closeWithoutSaving = true;
      this.transitionTo('apps.app.settings.teammates');
    } catch (err) {
      console.error(err);
      if (
        this.appService.app.cannotAssignWiderAccess &&
        isPresent(err?.jqXHR?.responseJSON?.tokens)
      ) {
        this.notificationsService.notifyError(
          this.intl.t('settings.error_message.cannot_modify_unowned_permissions_error'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.error_message.cannot_save_error'),
        );
      }
      if (this.appService.app.canUseBulkEditSeats) {
        this._fireBulkEditSeatsAnalyticsEvent({ is_successful: false });
      }
    }
  }

  async bulkEditSeatsAndPermissions(teammates, seatTypes, permissionsProperties) {
    let seatTypeByUsers = seatsByTeammates(teammates, seatTypes, this.currentModel.seatsSelections);

    await this.permissionsMutatorService.bulkEditSeatsAndPermissions(
      teammates,
      permissionsProperties,
      seatTypeByUsers,
      this.currentModel.reassignments,
    );

    this.notificationsService.notifyConfirmation(
      this.intl.t('settings.teammates.permissions.notification.permissions-and-seats-changed', {
        teammateCount: teammates.length,
      }),
    );
    let seatModified = countAmountOfSeatModified(teammates, seatTypeByUsers);

    this._fireBulkEditSeatsAnalyticsEvent({
      is_successful: true,
      total_seatless_teammates: Object.values(seatTypeByUsers).filter((seats) => seats.length === 0)
        .length,
      difference_in_support_seat: seatModified['support'],
      difference_in_marketing_seat: seatModified['marketing'],
      difference_in_convert_seat: seatModified['convert'],
      difference_in_engage_seat: seatModified['engage'],
    });
  }

  @action
  seatsUpdated(hasUpdatedSeats) {
    this.hasSeatsToBeSaved = hasUpdatedSeats;
  }

  @action
  async willTransition(transition) {
    if (this.closeWithoutSaving) {
      return;
    }

    // Only show modal if there were any changes
    if (this.currentModel.permissionsObjectChanged) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('settings.teammates.permissions.close-without-saving-modal.title'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'settings.teammates.permissions.close-without-saving-modal.close-without-saving',
        ),
        cancelButtonText: this.intl.t(
          'settings.teammates.permissions.close-without-saving-modal.keep-editing',
        ),
        body: this.intl.t('settings.teammates.permissions.close-without-saving-modal.message-body'),
      };

      if (await this.intercomConfirmService.confirm(confirmOptions)) {
        this.closeWithoutSaving = true;
        await transition.retry();
      }
    }
  }

  _fireBulkEditSeatsAnalyticsEvent({
    is_successful,
    total_seatless_teammates = 0,
    difference_in_support_seat = 0,
    difference_in_marketing_seat = 0,
    difference_in_convert_seat = 0,
    difference_in_engage_seat = 0,
  }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'bulk_edit_seats_save_button',
      total_edited_teammates: this.currentModel.teammates.length,
      total_seatless_teammates,
      difference_in_support_seat,
      difference_in_marketing_seat,
      difference_in_convert_seat,
      difference_in_engage_seat,
      is_successful,
    });
  }
}
