/* RESPONSIBLE TEAM: team-product-setup */

import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import { hash } from 'rsvp';
import SdkApp from 'embercom/models/sdk-app';
import type SdkAppService from 'embercom/services/sdk-app-service';
import { taskFor } from 'ember-concurrency-ts';

export default class InstallationRoute extends ProductSettingsRoute {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare sdkAppService: SdkAppService;

  analytics = {
    section: 'settings',
    place: 'installation-new',
  };

  get app() {
    return this.appService.app;
  }

  beforeModel(transition: any) {
    super.beforeModel(...arguments);
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_workspace_settings',
      transition,
    );
  }

  async model() {
    await taskFor(this.sdkAppService.loadSdkApps).perform();
    return hash({
      ios: SdkApp.findOrCreateForPlatform('iOS'),
      android: SdkApp.findOrCreateForPlatform('Android'),
    });
  }

  setupController(controller: $TSFixMe, model: $TSFixMe) {
    super.setupController(...arguments);
    controller.set('iosApiSecret', model.ios.api_secret);
    controller.set('iosApiKey', model.ios.api_key);
    controller.set('iosGcmKey', model.ios.gcm_key);
    controller.set('androidApiKey', model.android.api_key);
    controller.set('androidGcmKey', model.android.gcm_key);
    controller.set('androidApiSecret', model.android.api_secret);
    controller.set('iosSdkApp', model.ios);
    controller.set('androidSdkApp', model.android);
  }
}
