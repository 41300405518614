/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import type IntlService from 'embercom/services/intl';

export default class BrandsRoute extends Route {
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t(`new-settings.submenu.workspace.brands`);
  }
}
