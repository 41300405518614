/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';

export default WorkspaceSettingsRoute.extend({
  titleToken: 'Identity verification',
  appService: service(),

  beforeModel() {
    this.replaceWith('apps.app.settings.security');
  },
});
