/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { hash, allSettled } from 'rsvp';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import { captureException } from 'embercom/lib/sentry';
import { formatList } from 'embercom/helpers/format-list-helper';
export default class BulkTeammatesRemoveRoute extends ManageTeammatesRoute {
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;

  analytics = {
    section: 'settings',
    place: 'teammates-remove',
  };

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    if (!transition.data.teammates) {
      this.transitionTo('apps.app.settings.teammates');
    }
  }

  model(_params, transition) {
    return hash({
      app: this.appService.app,
      teammates: transition.data.teammates,
      reassignments: { teams: {} },
    });
  }

  @action
  removeTeammates() {
    return this.removeTeammatesTask.perform();
  }

  @dropTask
  *removeTeammatesTask() {
    try {
      let results = yield allSettled(this._destroyTeammates());

      if (results.every((result) => result.state === 'fulfilled')) {
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.teammates.remove.successful-removal-message', {
            appName: this.appService.app.name,
          }),
        );
        this._fireBulkDeleteAnalyticsEvent({ isSuccessful: true });
        this.transitionTo('apps.app.settings.teammates');
      } else {
        this._fireBulkDeleteAnalyticsEvent({ isSuccessful: false });
        this._handleBulkDeleteFailures(results);
      }
    } catch (error) {
      this._fireBulkDeleteAnalyticsEvent({ isSuccessful: false });
      this.notificationsService.notifyError(
        this.intl.t('settings.teammates.remove.unexpected-failure-message'),
      );
      captureException(error, { extra: { 'responsible-team': 'team-pricing-and-packaging' } });
    }
  }

  _destroyTeammates() {
    let { teammates, reassignments } = this.currentModel;

    return teammates.map((teammate) => {
      if (teammate.isDestroyed) {
        return;
      }

      /* eslint-disable promise/prefer-await-to-then */
      return teammate.currentAppPermissions
        .destroyRecord({ adapterOptions: { reassignments } })
        .then(() => {
          this.appService.app.admins.removeObject(teammate);
          teammate.unloadRecord();
        });
      /* eslint-enable promise/prefer-await-to-then */
    });
  }

  _handleBulkDeleteFailures(results) {
    let failedTeammateNames = [];
    results.forEach((result, index) => {
      if (result.state !== 'fulfilled') {
        failedTeammateNames.push(this.currentModel.teammates[index].displayAs);
      }
    });

    this.notificationsService.notifyError(
      this.intl.t('settings.teammates.remove.partial-failure-message', {
        failedTeammateCount: failedTeammateNames.length,
        failedTeammateNames: formatList([failedTeammateNames], { type: 'conjunction' }),
      }),
      ENV.APP.notificationNoTimeout,
    );
  }

  _fireBulkDeleteAnalyticsEvent({ isSuccessful }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'remove_teammates_button',
      deleted_teammates_count: this.currentModel.teammates.length,
      is_successful: isSuccessful,
    });
  }
}
