/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { inject as service } from '@ember/service';

export default class SmsRoute extends ManageMessagesSettingsRoute {
  @service store;
  analytics = {
    section: 'settings',
    place: 'sms',
  };
  titleToken = 'SMS settings';

  async model() {
    let customerTwilioCredentialsList = await this.store.findAll(
      'outbound-customer-twilio-credentials',
    );

    let customerTwilioCredentials =
      customerTwilioCredentialsList.firstObject ||
      this.store.createRecord('outbound-customer-twilio-credentials');

    let smsOutboundSettingsList = await this.store.findAll('sms-outbound-settings');

    let smsOutboundSettings =
      smsOutboundSettingsList.firstObject || this.store.createRecord('sms-outbound-settings');

    let smsKeywordResponsesList = await this.store.findAll('sms/keyword-response');
    let smsKeywordResponses =
      smsKeywordResponsesList.toArray() || this.store.createRecord('sms/keyword-response');

    let smsPhoneNumbersList = await this.store.findAll('sms/phone-number');
    let smsPhoneNumbers = smsPhoneNumbersList.toArray();

    return {
      customerTwilioCredentials,
      smsOutboundSettings,
      smsKeywordResponses,
      smsPhoneNumbers,
    };
  }

  beforeModel() {
    super.beforeModel(...arguments);
    let app = this.modelFor('apps.app');
    if (!app.get('canUseSMS')) {
      this.transitionTo('apps.app.outbound.settings.messaging');
    }
  }
}
