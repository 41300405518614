/* RESPONSIBLE TEAM: team-purchase-experience */

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class Success extends Route {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  beforeModel() {
    if (!this.appService.app.canUsePostCheckoutSuccessScreen) {
      this.router.transitionTo('apps.app.index');
    }
  }
}
