/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class WorkflowsOverview extends Route {
  @service store;
  @service appService;
  @service intercomEventService;
  @service notificationsService;
  @service session;
  @service ticketStateService;

  titleToken = 'Workflows';

  analytics = {
    place: 'custom-bots-overview',
    section: 'operator',
  };

  get app() {
    return this.appService.app;
  }

  async beforeModel() {
    this.intercomEventService.trackEvent('visited-custom-bots', {
      onMatchingSystem: true,
    });

    await this.loadTicketCustomStates();
  }

  async model(_, transition) {
    let findDescriptorPromise = async () => {
      return this.store.findAll('conversation-attributes/descriptor');
    };
    let findCustomObjectsPromise = async () => {
      return this.store.findAll('custom-objects/object-type');
    };

    let [descriptors, customObjects, qualificationAttributes, messengerApps] = await Promise.all([
      findDescriptorPromise(),
      findCustomObjectsPromise(),
      this.store.findAll('people/qualification-attribute'),
      this.store.findAll('messenger-app'),
      this.store.findAll('workflow-connector/insertable-action'),
      this.store.findAll('instagram/integration').catch(() => {
        this.notificationsService.notifyError('Error loading Instagram business accounts');
      }),
      this.store.query('whatsapp/integration', { no_external_updates: true }),
    ]);

    return hash({
      descriptors,
      customObjects,
      qualificationAttributes,
      messengerApps,
      launchPaywall: transition.to.queryParams.paywall,
    });
  }

  async loadTicketCustomStates() {
    this.session.switchWorkspace(this.app.id);
    await this.session.workspace.fetchRequiredAttributes();
    await this.ticketStateService.setup();
  }
}
