/* RESPONSIBLE TEAM: team-help-desk-experience */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import type Transition from '@ember/routing/-private/transition';

export default class TeammatePresenceRoute extends IntercomRoute {
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare session: Session;

  analytics = {
    section: 'settings',
    place: 'teammate-presence',
  };

  get titleToken() {
    return this.intl.t('settings.teammate-presence.title');
  }

  beforeModel(transition: Transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_inbox__teammate_presence__manage',
      transition,
    );
  }

  async model() {
    return await this.session.workspace.fetchAppSettings();
  }
}
