/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { hash } from 'rsvp';
import { getWorkspaceSeatUsageAndPrices } from 'embercom/lib/admins/multiple-seats-info';
import { FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';
import { COPILOT_SEAT_TYPE } from 'embercom/lib/settings/seats/constants';
export default class IndexRoute extends ManageTeammatesRoute {
  @service modelDataCacheService;
  @service customerService;
  @service permissionsMutatorService;
  @service appService;
  @service permissionsHierarchyService;
  @service intl;
  @service notificationsService;
  @service intercomConfirmService;
  @service store;
  @service copilotDataService;

  analytics = {
    section: 'settings',
    place: 'teammates',
  };

  async beforeModel() {
    await Promise.all([super.beforeModel(...arguments), this.customerService.refreshCustomer()]);
    // We wait for the app to refresh in the cache to prevent an edge case where booting directly
    // to the teammates page would cause the refesh to clobber the data loaded in the model hook here.
    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();

    return this.modelDataCacheService.awaitRefreshedModel('app', this.get('appService.app.id'));
  }

  _getFirstActivePricePlan() {
    let migrationPricePlans = this.get('customerService.migrationPrices');
    let activePlanIds = this.get('customerService.activePlanIds');
    return migrationPricePlans.find((price) => price.hasSamePlans(activePlanIds));
  }

  _getPricePerInboxSeatPromise() {
    let fallbackPrice = 0;
    let pricePerInboxSeat = this.customerService.customer.pricePerSeat;
    if (pricePerInboxSeat) {
      return Promise.resolve(pricePerInboxSeat);
    }
    let context = this;
    return this.customerService
      .loadData({ source: 'teammates-route' })
      .then(() => {
        let pricePlan = context._getFirstActivePricePlan();
        if (pricePlan === undefined) {
          pricePlan = context.get('customerService.migrationPrices.firstObject');
        }
        return pricePlan ? pricePlan.get('perProductData.price_per_agent') : fallbackPrice;
      })
      .catch(() => {
        return fallbackPrice;
      });
  }

  _getInvitedAdminsPromise() {
    return this.appService.app.fetchAndUpdateAllAdminPermissions().then(() =>
      this.store.findAll('invited-admin').catch(() => {
        this.notificationsService.notifyError(
          this.intl.t('apps.app.settings.teammates.index.invited-admins-unexpected-error'),
        );
      }),
    );
  }

  async _loadCopilotSeatUsageAndPrices() {
    let allSeatUsages = await getWorkspaceSeatUsageAndPrices();
    await this.customerService.bulkLoadPrices([
      {
        planIds: [parseInt(FIN_AI_COPILOT_BASE_ID, 10)],
      },
    ]);

    let copilotPrice =
      this.customerService.prices.find((price) =>
        price.hasSamePlans([parseInt(FIN_AI_COPILOT_BASE_ID, 10)]),
      )?.perUnitPrice ?? 0;
    return {
      seatUsages: allSeatUsages[COPILOT_SEAT_TYPE],
      price: copilotPrice,
    };
  }

  model() {
    let app = this.appService.app;
    if (app.isTestApp) {
      return { invitedAdmins: [], roles: [], copilotData: {} };
    }
    return hash({
      invitedAdmins: this._getInvitedAdminsPromise(),
      pricePerInboxSeat: this._getPricePerInboxSeatPromise(),
      roles: this.store.findAll('role'),
      copilotData: this.copilotDataService.ensureSetup(),
    });
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);
    let app = this.appService.app;
    let admins = app.get('humanAdmins');
    this.customerService.set('pricePerInboxSeat', model.pricePerInboxSeat);

    let controllerProperties = {
      model: admins,
      invitedAdmins: model.invitedAdmins,
      migrationState: model.migrationState,
      copilotData: model.copilotData,
    };

    if (transition.data.selectedTab) {
      controllerProperties.selectedTab = transition.data.selectedTab;
    }

    controller.setProperties(controllerProperties);
    this.permissionsMutatorService.set('invitedAdmins', model.invitedAdmins);
    this.customerService.set('migrationState', model.migrationState);
  }

  @action
  async error(e) {
    if (e.responseJSON && e.responseJSON.test_app) {
      let options = {
        title: this.intl.t('apps.app.settings.teammates.index.switching-to-production'),
        body: this.intl.t('apps.app.settings.teammates.index.switch-to-production-message'),
        confirmButtonText: this.intl.t('apps.app.settings.teammates.index.switch-to-production'),
      };
      let isConfirmed = await this.intercomConfirmService.confirm(options);
      if (!isConfirmed) {
        return;
      }
      let parentAppId = e.responseJSON.parent_app_id;
      window.location = `/a/apps/${parentAppId}/settings/team/`;
    }
  }
}
