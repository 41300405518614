/* RESPONSIBLE TEAM: team-ai-chatbot */
import FinAIAgentSetupRoute from 'embercom/routes/apps/app/fin-ai-agent/setup';

export default class AutomationFinAiAgentSetupRoute extends FinAIAgentSetupRoute {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.setup',
  };

  controllerName = 'apps.app.fin-ai-agent.setup';
  templateName = 'apps.app.fin-ai-agent.setup';
}
