/* RESPONSIBLE TEAM: team-proactive-support */

import { inject as service } from '@ember/service';
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';

export default class DomainsRoute extends SettingsRoute {
  @service appService;
  @service intl;
  @service store;

  analytics = {
    section: 'settings',
    place: 'domains',
  };

  get app() {
    return this.appService.app;
  }

  get titleToken() {
    return this.intl.t('settings.domains.title');
  }

  async model() {
    await this.store.findAll('spambot-contact');

    return this.app.loadEmailDomains();
  }
}
