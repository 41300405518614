/* RESPONSIBLE TEAM: team-ai-chatbot */
import { inject as service } from '@ember/service';
import ErrorHandlingRoute from 'embercom/routes/base/error-handling-route';
import CustomAnswer from 'embercom/models/custom-answers/custom-answer';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type Store from '@ember-data/store';

export default class FinAIAgentCustomAnswersAnswerRoute extends ErrorHandlingRoute {
  @service declare intercomEventService: $TSFixMe;
  @service declare store: Store;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  messengerSettingsLookAndFeel: $TSFixMe;

  analytics = {
    section: 'ai-chatbot',
    place: 'custom-answers-answer',
  };

  get titleToken() {
    return this.intl.t('operator.resolution-bot-answers.new-answer');
  }

  model({ answer_id }: { answer_id: $TSFixMe }) {
    return CustomAnswer.loadFullAnswer(answer_id);
  }

  async afterModel() {
    let app = this.appService.app;
    let [lookAndFeel] = await Promise.all([
      this.store.findRecord('messenger-settings/look-and-feel', app.id),
      this.store.findAll('messenger-app'),
      this.store.findAll('workflow-connector/insertable-action'),
      this.store.findAll('conversational-insights/conversation-topic'),
    ]);

    this.set('messengerSettingsLookAndFeel', lookAndFeel);
  }

  setupController(controller: $TSFixMe, model: $TSFixMe, transition: Transition) {
    super.setupController(controller, model, transition);

    let queryParams = transition.to.queryParams;
    let lookAndFeel = this.messengerSettingsLookAndFeel;
    let previewConfiguration = this.store.createFragment(
      'messenger-preview/configuration',
      lookAndFeel.get('previewConfigurationParams'),
    );
    controller.set('previewConfiguration', previewConfiguration);
    controller.set('transitionBackTo', queryParams.transitionBackTo);
    controller.changeVisualBuilderQueryParam(model);
  }

  handleError(error: $TSFixMe) {
    switch (error.status) {
      case 404:
        return this.displayErrorAndRedirect(
          this.intl.t('operator.resolution-bot-answers.notifications.broken-link-error'),
          'apps.app.automation.resolution-bot',
        );
      default:
        return this.displayErrorAndRedirect(
          this.intl.t('operator.resolution-bot-answers.notifications.default-error'),
          'apps.app.automation.resolution-bot',
        );
    }
  }
}
