/* RESPONSIBLE TEAM: team-proactive-support */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { inject as service } from '@ember/service';

export default class TollFreeVerificationFormRoute extends ManageMessagesSettingsRoute {
  @service intl;
  @service store;

  async model(params) {
    let tollFreeVerifications = await this.store.query('sms/toll-free-verification', {
      phone_number_id: params.id,
    });
    return (
      tollFreeVerifications.firstObject ||
      (await this.store.createRecord('sms/toll-free-verification', {
        phoneNumberId: params.id,
      }))
    );
  }

  activate() {
    window.onbeforeunload = (event) => {
      if (this.controller.model.hasDirtyAttributes) {
        return (event.returnValue = this.intl.t(
          'outbound.sms.onboarding.toll-free-verification-form.warn-unsaved-changes',
        ));
      }
    };
  }
}
