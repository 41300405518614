/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default class EmailTemplatesIndexRoute extends ManageMessagesSettingsRoute {
  @service store;

  analytics = {
    section: 'settings',
    place: 'email_templates',
  };
  titleToken = 'Email templates';

  model() {
    let app = this.modelFor('apps.app');
    return hash({
      emailTemplatesSettings: this.store.findRecord('email-templates-settings', app.get('id')),
      emailTemplateTitles: this.store.findAll('email-template-titles'),
    });
  }
}
