/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import ErrorHandlingRoute from 'embercom/routes/base/error-handling-route';
import { isEmpty } from 'underscore';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';

const FALLBACK_ROUTE = 'apps.app.outbound.all';

export default class RulesetRedirectRoute extends ErrorHandlingRoute {
  @service appService;
  @service intl;
  @service intercomEventService;
  @service store;

  analyticsObject = {
    object: 'apps.app.outbound.find',
    action: 'navigated',
    section: 'global',
    place: 'global',
  };

  async model(params) {
    let ruleset = await this.store.findRecord('matching-system/ruleset', params.ruleset_id);
    this.transitionToRuleset(ruleset);
  }

  routeForObject(objectType) {
    return OUTBOUND_EDITOR_ROUTES[objectNames[objectType]];
  }

  transitionToRuleset(ruleset) {
    if (ruleset.isMemberOfSeries) {
      this.intercomEventService.trackAnalyticsEvent({
        ...this.analyticsObject,
        to: 'apps.app.outbound.series.series',
      });
      this.transitionTo('apps.app.outbound.series.series', ruleset.seriesRelation.series_id, {
        queryParams: { mode: 'view', nodeId: ruleset.seriesRelation.node_id },
      });
    } else {
      let rulesetLink = ruleset?.rulesetLinks?.firstObject;
      if (isEmpty(rulesetLink)) {
        this.displayErrorAndRedirect(this.intl.t('outbound.all.errors.not-found'), FALLBACK_ROUTE);
      }
      let redirectLink = this.routeForObject(rulesetLink.objectType);
      this.intercomEventService.trackAnalyticsEvent({
        ...this.analyticsObject,
        to: redirectLink,
      });
      this.transitionTo(redirectLink, ruleset.id);
    }
  }

  handleError(error) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.analyticsObject,
      error: error.status,
    });
    switch (error.status) {
      case 403:
        return this.displayErrorAndRedirect(
          this.intl.t('outbound.find.errors.dont-have-permission'),
          FALLBACK_ROUTE,
        );
      case 404:
        return this.displayErrorAndRedirect(
          this.intl.t('outbound.find.errors.not-found'),
          FALLBACK_ROUTE,
        );
      case 500:
        return this.displayErrorAndRedirect(
          this.intl.t('outbound.find.errors.internal-error'),
          FALLBACK_ROUTE,
        );
    }
  }
}
