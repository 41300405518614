/* RESPONSIBLE TEAM: team-customer-data-platform */

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import { hash } from 'rsvp';
import type Transition from '@ember/routing/transition';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

export default class MigrateFromZendeskRoute extends WorkspaceSettingsRoute {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare permissionsService: any;

  analytics = {
    section: 'settings',
    place: 'migrate-from-zendesk',
    txn_id: uuidv4(),
  };

  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.data-import');
  }

  beforeModel(transition: Transition) {
    super.beforeModel(...arguments);

    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_bulk_import_contacts',
      transition,
    );

    if (!this.appService.app.canMigrateFromZendesk) {
      this.router.transitionTo('apps.app.settings');
    }
  }

  async model() {
    let ticketTypes = await this.store.findAll('inbox/ticket-type');

    return hash({
      ticketTypes,
    });
  }
}
