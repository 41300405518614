/* RESPONSIBLE TEAM: team-help-desk-experience */
import CheckUnsavedChanges from 'embercom/routes/apps/app/account/base/check-unsaved-changes-route';

export default CheckUnsavedChanges.extend({
  analytics: {
    section: 'respond',
    place: 'preferences_conversations',
  },

  beforeModel() {
    let admin = this.modelFor('apps');

    if (!admin.get('canUseInboxInAnyApp')) {
      this.transitionTo('apps.app.account.general');
    }
  },

  model() {
    let admin = this.modelFor('apps');
    return admin;
  },
});
