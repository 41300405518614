/* RESPONSIBLE TEAM: team-ai-chatbot */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/-private/transition';
import { hash } from 'rsvp';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

const OPERATOR_SETTINGS_PERMISSION = 'can_create_and_edit_bots';

export default class FinAIAgentSettingsRoute extends IntercomRoute {
  @service declare appService: any;
  @service declare outboundHomeService: any;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @service declare store: Store;
  @service declare intl: IntlService;

  analytics = {
    section: 'ai-chatbot',
    place: 'settings',
  };

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.settings.title');
  }

  beforeModel(transition: Transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      OPERATOR_SETTINGS_PERMISSION,
      transition,
    );
  }

  async model() {
    let appId = this.appService.app.id;
    let answerCounts = await ajax({
      url: '/ember/custom_answers/counts',
      type: 'GET',
      data: { app_id: appId },
    });

    let messengerSettings = await this.store.findRecord('messenger-settings/all', appId);

    return hash({
      previewConfiguration: messengerSettings.previewConfiguration,
      aiAgentSettings: this.store.findRecord('ai-agent/settings', appId),
      aiAgentToneOfVoiceSettings: this.store.findRecord('ai-agent/tone-of-voice-settings', appId),
      aiAgentQuickReplySettings: this.store.findRecord(
        'operator-settings/ai-agent-quick-replies',
        appId,
      ),
      answerCounts,
      liveResolutionBotBehaviors: this.outboundHomeService.contentSearch({
        object_types: [objectTypes.resolutionBotBehavior],
        states: [states.live],
        match_behaviors: [],
        app_id: this.appService.app.id,
        per_page: 1,
      }),
    });
  }

  setupController(
    controller: any,
    model: {
      previewConfiguration: any;
      aiAgentSettings: any;
      aiAgentToneOfVoiceSettings: any;
      aiAgentQuickReplySettings: any;
      answerCounts: any;
      liveResolutionBotBehaviors: any;
    },
  ) {
    controller.setProperties({
      previewConfiguration: model.previewConfiguration,
      aiAgentSettings: model.aiAgentSettings,
      aiAgentToneOfVoiceSettings: model.aiAgentToneOfVoiceSettings,
      aiAgentQuickReplySettings: model.aiAgentQuickReplySettings,
      answerCounts: model.answerCounts,
      liveResolutionBotBehaviors: model.liveResolutionBotBehaviors,
    });
  }

  @action refreshRoute() {
    this.refresh();
  }
}
