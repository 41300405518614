/* RESPONSIBLE TEAM: team-reporting */
import { hash } from 'rsvp';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';

export default ProductSettingsRoute.extend({
  store: service(),
  intercomEventService: service(),
  appService: service(),
  intl: service(),
  app: readOnly('appService.app'),

  analytics: {
    section: 'settings',
    place: 'events',
  },

  get titleToken() {
    return this.intl.t('settings.app-settings-submenu.events');
  },

  model() {
    return hash({
      eventSettings: this.store.findRecord('event-settings', this.get('app.id')),
      eventTrackers: this.store.findAll('event-tracker'),
      pingDomains: this.store.query('ping-domain', { ignore_anonymous: false }),
    });
  },

  setupController(controller, models) {
    this._super(controller, models);
    controller.setProperties(models);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'events',
    });
  },
});
