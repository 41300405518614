/* RESPONSIBLE TEAM: team-workflows */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { OUTBOUND_BOT_TYPE } from 'embercom/lib/operator/custom-bots/constants';

export default class AppsAppContentEditRoute extends ContentEditorRoute {
  @service appService;
  @service navbarCollapsingService;

  async model(params) {
    await this.store.findAll('workflow-connector/insertable-action');

    return super.model(params);
  }

  afterModel(model) {
    if (model.rulesetLinks.firstObject.object.type === OUTBOUND_BOT_TYPE) {
      this.outboundHomeService.setActiveList(objectTypes.customBot);
    }
    super.afterModel(...arguments);
  }

  activate() {
    super.activate(...arguments);
    if (this.navbarCollapsingService.collapsed === false) {
      this.navbarCollapsingService.set('collapsed', true);
    }
  }

  deactivate() {
    super.deactivate(...arguments);
    if (this.navbarCollapsingService.collapsed !== false) {
      this.navbarCollapsingService.set('collapsed', false);
    }
  }

  setupController(_, model) {
    super.setupController(...arguments);

    this.controllerFor('apps.app.automation').set(
      'currentViewedCustomBotTarget',
      model.rulesetLinks.firstObject.object.target,
    );
    model.rulesetLinks.firstObject.object.resetInvalidActions();
    this.controllerFor('apps.app.automation.workflows').set('hidePaywallBanner', true);
  }

  resetController() {
    super.resetController(...arguments);

    this.controllerFor('apps.app.automation').set('currentViewedCustomBotTarget', undefined);
    this.controllerFor('apps.app.automation.workflows').set('hidePaywallBanner', false);
  }
}
