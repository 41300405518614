/* RESPONSIBLE TEAM: team-channels */
import ManageMessagesSettingsRoute from 'embercom/routes/apps/app/settings/base/manage-messages-settings-route';
import { inject as service } from '@ember/service';

export default class EmailNotificationsRoute extends ManageMessagesSettingsRoute {
  @service intercomEventService;
  @service intl;
  @service store;

  get titleToken() {
    return this.intl.t('apps.app.settings.email-notifications.title');
  }

  analytics = {
    place: 'email_notifications',
  };

  get app() {
    return this.modelFor('apps.app');
  }

  toggleUserEmailFollowUps() {
    this.messagingSettings.set('userEmailFollowUps', !this.messagingSettings.userEmailFollowUps);
    this.messagingSettings.save();
  }

  toggleUserTicketEmailFollowUps() {
    this.messagingSettings.set(
      'userTicketEmailFollowUps',
      !this.messagingSettings.userTicketEmailFollowUps,
    );
    this.messagingSettings.save();
  }

  model() {
    return this.store.findRecord('messaging-settings', this.app.id);
  }
}
